import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment/moment';
import StudentsList from './StudentsList'


const eventsData = [
    { 
        id : 1 ,
        title: 'Session Name' , 
        date : new Date('2023-01-01T05:30:00-05:00') , 
    },
    { 
        id : 2 ,
        title: 'Session Name' , 
        date : new Date('2023-01-05T11:30:00-05:00') , 
    },
    { 
        id : 3 ,
        title: 'Session Name' , 
        date : new Date('2023-01-11T11:30:00-05:00') , 
    },
    { 
        id : 4 ,
        title: 'Session Name' , 
        date : new Date('2023-01-21T12:30:00-13:00') , 
    },
    { 
        id : 5 ,
        title: 'Session Name' , 
        date : new Date('2023-01-26T08:30:00-05:00') , 
    },
    { 
        id : 6 ,
        title: 'Session Name' , 
        date : new Date('2023-01-20T03:30:00-13:00') , 
    },
    { 
        id : 7 ,
        title: 'Session Name' , 
        date : new Date('2022-12-20T12:30:00-05:00') , 
    },
    { 
        id : 8 ,
        title: 'Session Name' , 
        date : new Date('2023-02-07T12:30:00-05:00') , 
    },
  
]

const StudentsSchedule = ({schedule, requestType, students}) => {
    // console.log('StudentsSchedule data' , schedule);

    const handleEventClick = (info) => {
        // setSelectedExam(moment(info.event.start).format('MM/DD/YYYY'))
    }

    return (
        <div className='bg-pure mt-8'>
            <div className='border-b-2 border-b-gray-300 pb-1 font-semibold text-sm'>
                <span>Schedule</span>
            </div>
            <div className='flex lg:flex-row flex-col gap-4 py-4'>
                {requestType === 'Corporate' ?
                <div className='flex-[0.22]'>
                    <StudentsList students={students} />
                </div>
                :
                <></>
            }
                <div className='flex-[1] schedule-calender'>
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        // eventClick={handleEventClick}
                        headerToolbar={{
                            start: 'dayGridMonth,dayGridWeek,dayGridDay',
                            center : 'title',
                            end : 'prev next'
                        }}
                        aspectRatio={1.5}
                        // expandRows={true}
                        // events={eventsData}
                        events={schedule.map((session) => {
                            // console.log('session? ? ? ',session)
                            return {
                              id: session.id,
                              title: session.name, 
                              date: new Date(session.date+' '+session.from) , 
                            }
                          })}
                    /> 
                </div>
            </div>
        </div>
    )
}

export default StudentsSchedule