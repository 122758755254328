import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import apiRequest from '../../services/axois';

function OTP({ setOtp, setShowModal, allData }) {
  const [otpNumber, setOtpNumber] = useState('');
  const [idFromOtp, setIdFromOtp] = useState('')

  const phone = allData?.phone;
  const lastTwo = phone.slice(-2);
  const asterisks = '*'.repeat(phone.length - 2);
  const maskedPhone = asterisks + lastTwo;

  const handleChange = (otpNumber) => setOtpNumber(otpNumber);

  let id;

  async function createOTP() {
    let data;
    data = await apiRequest('post', '/users/create-otp', {
      phone: allData?.phone,
      email: allData?.email,
    });

    setIdFromOtp(data.data.id)
  }

  async function verifyOTP() {
    let data;
    data = await apiRequest('post', '/users/verify-otp', {
      id: idFromOtp,
      otp: otpNumber,
    })
      .then(() => {
        setShowModal(false);
        setOtp(true);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    createOTP();
  }, []);

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden
      overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="w-1/3 my-6 mx-auto max-w-4xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <h1 className="text-center text-3xl font-bold mt-12">
              OTP Verification
            </h1>
            <p className="text-center mt-4 text-[#718096]">
              Please enter one time OTP to verify your account
            </p>
            <p className="text-center mt-4 font-bold">
              A code has been sent to mobile number: {maskedPhone}
            </p>
            <div
              id="otp"
              className="flex flex-row justify-center text-center px-2 mt-5"
            >
          <OtpInput
            value={otpNumber}
            onChange={handleChange}
            numInputs={4}
            separator={<span style={{ width: '8px' }}></span>}
            isInputNum={true}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: '1px solid black', // set the border color to black
              borderRadius: '8px',
              width: '54px',
              height: '54px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '400',
              caretColor: 'blue',
            }}
            focusStyle={{
              border: '1px solid #CFD3DB',
              outline: 'none',
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px', // set the gap between inputs to 8px
            }}
          />
            </div>
            <p className="text-center mt-4 font-bold">
              Don't Get The Code {'  '}
              <span className="underline text-[#046F6D] cursor-pointer decoration-[#046F6D]">
                Resend It
              </span>
            </p>
            <div className="flex items-center p-2 border-t border-solid border-slate-200 rounded-b m-12">
              <button
                className="text-white bg-[#046F6D] w-full h-12"
                type="button"
                onClick={() => {
                  verifyOTP();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default OTP;
