import React, { useState, useEffect, useRef } from 'react';
// import { FileUploader } from "react-drag-drop-files";
import Dropzone from 'react-dropzone';
import ImageViewer from 'react-simple-image-viewer';
import regionService from '../../services/regionService';
import traningService from '../../services/traningService';
import UploadFileImage from '../../assets/svgs/message-add.svg';
import imageDataTest from '../../assets/img/chart.png';
import { useTranslation } from 'react-i18next';
import uploaderService from '../../services/uploaderService';
import MapComponent from '../MapComponent.js';
import { Controller, useFormContext  } from 'react-hook-form'
import GenericDropdown from '../GenericDropDown';
import UploadFileIcon from '../../assets/svgs/Line.svg'
import { LinearProgress, makeStyles } from '@material-ui/core'
import CloseIcon from '../../assets/svgs/close.svg'
import GenericDatePicker from '../GenericDatePicker';

import HijriDatePicker from '../HijriDatePicker/index'
import AttachmentComponent from '../Attachment';

import axios from '../../api/axios';
import config from '../../config';
import { useLocation } from 'react-router-dom';

const fileTypes = ['JPG', 'PNG', 'PDF'];

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#CFCFCF",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#046F6D",
    },
  },
}));
function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <div  className={classes.root}>
      <div  className='w-full mx-1'>
        <LinearProgress  variant="determinate" {...props} />
      </div>
      <div className='w-[50px] flex justify-end'>
        <span>{`${Math.round(
          props.value,
        )}%`}</span>
      </div>
    </div>
  );
}

function Content(props) {
  const location = useLocation()
  const { register, getFieldState, control, watch, setValue } = useFormContext();
  const watchers = watch();
  const data = props.data;
  const allData = props.allData;
  const setAllData = props.setAllData;
  const [file, setFile] = useState(null);
  const [region, setRegion] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [corporateCategory, setCorporateCategor] = useState([]);
  const [corporateType, setCorporateType] = useState([{
    value: "1",
    label: "A"
  }, {
    value: "2",
    label: "B"
  }]);
  const [genderList, setGenderList] = useState(
    [
      {
        value: 'Male',
        label: 'Male'
      },
      {
        value: 'Female',
        label: 'Female'
      }
    ]

  );
  const [classificationList, setClassificationList] = useState(
    [
      {
        value: 'Laundry',
        label: 'Laundry'
      },
      {
        value: 'Barber',
        label: 'Barber'
      },
      {
        value: 'food Safety',
        label: 'food Safety'
      },
      {
        value: 'Beauty parlor',
        label: 'Beauty parlor'
      },
    ]

  );

  const [BranchList, setBranchList] = useState([]);
  const [regionId, setRegionId] = useState(1);
  const [city, setCity] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [municipalityId, setMunicipalityId] = useState(1);
  const [subMunicipality, setSubMunicipality] = useState([]);
  const [trainingType, setTrainingType] = useState([]);

  const { t } = useTranslation();

  const handleChange2 = (file, name) => {
    setFile(file);
    setAllData({ ...allData, [name]: file });
    // // console.log(allData);
  };

  const handleChangeFile = (file, name) => {
    // console.log(file[0]);
    const data = {
      dataType: "arkan",
      file: file[0]
    }
    uploaderService.postUpload(data).then((result) => {
      // console.log({ pre: progress[name] })
      if (result.data.id) {
        let tempProg = progress;
        tempProg[[name]] = 100;
        setProgress(tempProg);
        // setProgress({ [name]: progress[name] ?? 60 + 40 });
        setAllData({ ...allData, [name]: result.data.id });
      }
    })
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setAllData({ ...allData, [name]: value });
  //   // console.log(allData);
  // };

  const handleChangeWithName = (value, name) => {
    // console.log(value, name);
    // console.log(watch())
    if(name === 'map'){
      // console.log(watchers.map)
      watchers.map = value;
      // console.log('map innnnnnnnnnnnnnnnnnn: ',watchers.map)
      // {...register()}
    }
    setAllData({ ...allData, [name]: value });
    // console.log('allData?? : ',allData.map);
  };

  const hanldeChange3 = (event) => {
    // console.log(event);
    const { name, value } = event;
    setAllData({ ...allData, [name]: value });
    // // console.log(allData);
  };

  useEffect(() => {
    if(watchers.regionId){
      // alert('11')
    regionService.getMunicipalityByRegion(watchers.regionId).then((res) => {
      setMunicipality(res.data.map((_) => ({
        label: _.nameEn,
        value: _.id
      })));
    });
  }
    // // console.log(allData);
  }, [watchers.regionId]);

  useEffect(() => {
    regionService.getRegion().then((res) => {
      setRegion(res.data.map((data) => ({
        label: data.nameEn,
        value: data.id
      })));
    });
  }, []);

  useEffect(() => {
    regionService.getNationality().then((res) => {
      setNationalities(res.data.map((nationality) => ({
        value: nationality.id,
        label: nationality.nameEn
      })));
    });
  }, []);



  useEffect(() => {
    regionService.getCategory().then((res) => {
      setCorporateCategor(res.data.map((corporate => ({
        label: corporate.nameEn,
        value: corporate.id
      }))));
    });
  }, []);

  // useEffect(() => {
  //   let tempShow = showProgress;
  //   tempShow[['121']] = false;
  //   setShowProgress(tempShow);
  // }, [setShowProgress]);

  useEffect(() => {
    if(watchers.regionId){
    regionService.getBranchListByRegion(watchers.regionId).then((res) => {
      // console.log('watchers.regionId??? ',res.data.length)
        setBranchList(res.data.filter(reg => reg.regionId === watchers.regionId).map((_) => ({
          label: _.name,
          value: _.id
        })));
      });
    }
  }, [watchers.regionId]);

  useEffect(() => {
    if(watchers.regionId){
    regionService.getCityById(watchers.regionId).then((res) => {
      // console.log('res.data>. ',watchers.regionId)
        setCity(res.data.filter(reg => reg.regionId === watchers.regionId).map((_) => ({
          label: _.nameEn,
          value: _.id
        })));
      });
    }
  }, [watchers.regionId]);

  useEffect(() => {
    if(watchers.municipalityId){
    regionService.getSubMunicipality(watchers.municipalityId).then((res) => {
        // alert('2')
        setSubMunicipality(res.data.map((_) => ({
          label: _.nameEn,
          value: _.id
        })));
      });
    }
  }, [watchers.municipalityId]);

  useEffect(() => {
    regionService.getType().then((res) => {
      setCorporateType(res.data.map((_) => ({
        label: _.nameEn,
        value: _.id
      })));
    });
  }, []);

  useEffect(() => {
    traningService.getType().then((res) => {
      setTrainingType(res.data.map((_) => ({
        label: _.nameEn,
        value: _.id
      })))
    })
  },[])

    // async function getMedical() {
    //   if(location.pathname.split('/').length < 5){
    //     return;
    //   }
    //   // alert(location.pathname.split('/').length)
    //   let data;
    //   data = await axios.get(config.API_URL + '/medical-center/' + location.pathname.split('/')[4] )
    //   setAllData(data.data);
    //   // console.log('_+++_',data.data);
    //   // watchers.email = data.data.email;
    //   // watchers.regionId = data.data.regionId;
    //   // watchers['name'] = data.data.name;
    //   console.log('watchers',watchers);

    // }

    // useEffect(() => {
    //   console.log(allData)
    //   setValue({
    //     name: allData.name
    //   })
    // }, [allData])

  
  // useEffect(() => {
  //   getMedical()
  // },[])
  
  const [progress, setProgress] = React.useState({});
  const [showProgress, setShowProgress] = React.useState({});
  return (
    <div>
      <div>
        <div>
          <div>
            {/* {console.log('data?? ?? ',data)} */}
            {data?.map((items, index) => (
              <div key={index} className={items.divStyle}>
                {' '}
                {/*Mdiv_wizard: flex justify-center my-10 p-10 bg-white border-t border-black */}
                <div>
                  <h2 className={items.headerStyle}>{t(items.name)}</h2>
                  {
                    items.hrStyle && (
                      <hr className={items.hrStyle}></hr>
                    )
                  }

                  <div className={items.componentStyle}>
                    {' '}
                    {/* should be grid */}
                    {items.Fields?.map((data, index) => (
                      <div key={index}>
                        <>
                        {watchers.id_type == "ID" && data.inputName == 'occupationInIqama' ?
                        <></>
                        :
                        <label className={data.labelStyle}>
                          {t(data.name)}
                        </label>
                        }
                        </>
                        {data.inputField === 'input' && data.type !== "date" && (
                          <>
                          {watchers.id_type == "ID"  && data.inputName == 'occupationInIqama'?
                          <></>
                          :
                          <React.Fragment>

                          <input
                              key={data.inputName}
                              {...register(data.inputName)}
                              value={watchers[data.inputName]}
                              className={getFieldState(data.inputName)?.error?.message ? "inputError" : "inputWrapper"}
                              type={data.type}
                              placeholder={t(data.placeHolder)}
                            />
                            <span className={data.inputErrorStyle}>{getFieldState(data.inputName)?.error?.message}</span>
                          </React.Fragment>
                          }
                          </>

                        )}
                        {data.inputField === 'select' && (
                          <React.Fragment>
                            {data.api === 'region' && (
                              <GenericDropdown
                                placeholder={"Please Select A Region ..."}
                                control={control}
                                name={"regionId"}
                                options={region} />
                            )}
                            {data.api === 'city' && (
                              <GenericDropdown
                                placeholder={"Please Select A City ..."}
                                control={control}
                                name={"cityId"}
                                options={city} />

                            )}
                            {data.api === 'nationality' && (
                              <GenericDropdown
                                placeholder={"Please Select A Nationality ..."}
                                control={control}
                                name={"nationalityId"}
                                options={nationalities} />
                            )}
                            {data.api === 'branchList' && (
                              <GenericDropdown
                                placeholder={"Please Select A Branch ..."}
                                control={control}
                                name={"branchId"}
                                options={BranchList} />
                            )}
                            {data.api === 'municipality' && (
                              <GenericDropdown
                                placeholder={"Please Select A Municipality ..."}
                                control={control}
                                name={"municipalityId"}
                                options={municipality} />

                            )}
                            {data.api === 'subMunicipality' && (
                              <GenericDropdown
                                placeholder={"Please Select A Sub Municipality..."}
                                control={control}
                                name={"submunicipalityId"}
                                options={subMunicipality} />
                            )}
                            {data.api === 'corporateType' && (
                              <GenericDropdown
                                placeholder={"Please Select Corporate Type..."}
                                control={control}
                                name={"type"}
                                options={corporateType} />

                            )}
                            {data.api === 'corporateCategoryList' && (
                              <GenericDropdown
                                placeholder={"Select Corporate Category ..."}
                                control={control}
                                name={"category"}
                                options={corporateCategory} />

                            )}
                            {data.api === 'genderList' && (
                              <GenericDropdown
                                placeholder={"Select Gender..."}
                                control={control}
                                name={"gender"}
                                options={genderList} />

                            )}
                            {data.api === 'trainingType' && (
                              <GenericDropdown
                                placeholder={"Select type..."}
                                control={control}
                                name={"type"}
                                options={trainingType} />

                            )}
                            {data.api === 'classificationList' && (
                              <GenericDropdown
                                placeholder={"Select Classificatin..."}
                                control={control}
                                name={"classification"}
                                options={classificationList} />

                            )}
                          </React.Fragment>
                        )}
                        {data.inputField === 'radio' && (
                          <GenericDropdown
                            placeholder={"Select ID Type..."}
                            control={control}
                            name={data.inputName}
                            options={data.select} />

                        )}
                        {
                          data.type === "date" && (
                            // TODO: Need to fix this
                          //   <HijriDatePicker
                          //   inputName={data.inputName}
                          //   setAllData={setAllData}
                          //   allData={allData}
                          //   // minDate={moment().format('YYYY-MM-DD')}
                          // />
                            // <GenericDatePicker placeholderText={"Please select a date"} control={control} name={data.inputName} />
                            <HijriDatePicker
                            name={data.inputName}
                            inputName={data.inputName}
                            control={control} 
                            placeholderText={"Please select a date"}
                            // setAllData={setAllData}
                            // allData={allData}
                          />

                          )
                        }


                        {data.inputField === 'upload' && (
                          <div key={data.inputName} className=" w-full">

                           <AttachmentComponent 
                                    key={data.inputName}
                                    name={data.inputName}
                                    label={data.targetdLabel}
                                    inputName={data.inputName}
                                    control={control}
                                    allData={allData}
                                    setAllData={setAllData}
                                  />

                            {/* <Controller
                             key={data.inputName}
                              control={control}
                              name={data.inputName}
                              render={({ field: { value: fieldValue, onChange } }) => {
                                return <div className='flex w-full flex-col'>
                                  <Dropzone
                                    key={data.inputName}
                                    onDrop={(acceptedFiles) => {
                                      let tempProg = progress;
                                      tempProg[[data.inputName]] = 30;
                                      setProgress(tempProg);

                                      let tempShow = showProgress;
                                      tempShow[[data.inputName]] = true;
                                      setShowProgress(tempShow)
                                      onChange(acceptedFiles[0])
                                      // preventDropOnDocument = true
                                      handleChangeFile(acceptedFiles, data.inputName);

                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      // bg-[#fff] flex items-center border-dashed border-2 border-[#ADADAD] w-[162px] h-[130px]
                                      <section className={data.inputStyle}>
                                        <div
                                          className="my-5 hover:cursor-pointer w-full"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <img

                                            src={data.targetdLabel ? UploadFileIcon : UploadFileImage}
                                            alt="Upload"
                                            className="mx-auto cursor-pointer"
                                          />
                                          <p className={data.targetedLabelStyle}>
                                            {data.targetdLabel ?? "Upload"}
                                          </p>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                  {fieldValue?.name && showProgress[data.inputName] && (
                                    <>
                                    <div key={data.inputName}  className='border p-4 mt-4 w-[400px] flex flex-col rounded border-gray-300 border-solid'>
                                    <div  className='flex flex-row items-center justify-between w-full mb-2'>
                                      <span className='font-xs'>{fieldValue.name}</span>
                                      <img onClick={() => {
                                        let tempShow = showProgress;
                                        tempShow[[data.inputName]] = false;
                                        setShowProgress(tempShow);

                                      }} className={"cursor-pointer"} src={CloseIcon} />
                                    </div>
                                    <LinearProgressWithLabel value={progress[data.inputName] ?? 0} />
                                  </div>
                                    </>
                                  )}
                                </div>
                              }}
                            /> */}
                          </div>
                        )}
                        {data.inputField === 'mapImage' ? (
                          <div className="">
                            <img src={data.url} />

                          </div>
                        ) : (
                          <></>
                        )}

                        {data.inputField === 'map' && (
                          <div className='mx-0'>
                            <MapComponent
                              name={data.inputName}
                              handleChangeWithName={handleChangeWithName}
                              // markerValue={allData.location}
                            />

                          </div>)
                        }
                        {
                          data.inputField === "address_details_textarea" && (
                            <div>
                            <textarea name="address_details" placeholder='Address Details' className={data.inputStyle} {...register("address_details")} />
                            <span className={data.inputErrorStyle}>{getFieldState(data.inputName)?.error?.message}</span>
                            </div>
                          )
                        }
                        {
                          data.inputField === "copreateAddress_details_textarea" && (
                            <div>
                            <textarea name="copreateAddress" placeholder='Address Details' className={data.inputStyle} {...register("copreateAddress")} />
                            <span className={data.inputErrorStyle}>{getFieldState(data.inputName)?.error?.message}</span>
                            </div>
                          )
                        }


                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
