import React from 'react'

const PaymentDetails = ({payment}) => {
    console.log('payment data' , payment);

    return (
        <div className='bg-pure mt-8'>
            <div className='border-b-2 border-b-gray-300 pb-1 font-semibold text-sm'>
                <span>Payment Details</span>
            </div>
            <div className='pt-5 pb-8 flex flex-col gap-4 lg:w-1/2 md:w-[80%] w-full'>
                {/* Card */}
                <div className='flex gap-2 border rounded-md'>
                    <div className='flex-[0.37]'>
                        <img 
                        src="https://media.istockphoto.com/id/851832858/photo/women-holding-a-pens-writing-a-notebook-recording-concept.jpg?s=612x612&w=0&k=20&c=BL_2VsXoBIZIClupjYrvQTRKctPmLpMExSjNgq_4Yo4=" 
                        alt="hand written pen"
                        className='rounded-tl-md rounded-bl-md w-full h-full object-cover'
                        />
                    </div>
                    <div className='flex-[0.65] text-sm  py-3 px-2'>
                        <p className='font-semibold'>{payment.instructorNameEn}</p>
                        <div className='flex items-center gap-2 py-4'>
                            <div>
                                {/* <img 
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTewPdubiwZ-wA40RGuCnUu-IBIkj3iSCGGd6s5Hf7Q&s" 
                                alt="Profile" 
                                className='w-[40px] h-[40px] object-cover rounded-full'
                                /> */}
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-medium'>{payment.courseName}</p>
                                {/* <span className='text-[10px] text-grayText'>Data Science</span> */}
                            </div>
                        </div>
                        <div className='flex justify-between border-t pt-3'>
                            <div>
                                <p className='font-semibold text-lg'>
                                {payment.amount}
                                    <span className='text-grayText text-sm'>/SAR</span>
                                </p>
                            </div>
                            {/* <div className='flex items-center gap-1 '>
                                <i className="uil uil-favorite text-primary"></i>
                                <p className='font-semibold'>4.91</p>
                                <span>(98)</span>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Price List */}
                <ul className='pt-4 flex flex-col gap-4 text-sm'>
                    <li className='flex items-center justify-between text-grayText'>
                        <p>1x Course</p>
                        <div className='flex items-center gap-4 text-dark'>
                            <p>
                            {payment.amount}
                            </p>
                            <span>SAR</span>
                        </div>
                    </li>
                    <li className='flex items-center justify-between text-grayText'>
                        <p>Balance</p>
                        <div className='flex items-center gap-4 text-dark'>
                            <p>
                            {payment.balance}
                            </p>
                            <span>SAR</span>
                        </div>
                    </li>
                    <li className='flex items-center justify-between text-grayText'>
                        <p>Service fee</p>
                        <div className='flex items-center gap-4 text-dark'>
                            <p>
                            {payment.serviceFee}
                            </p>
                            <span>SAR</span>
                        </div>
                    </li>
                    <li className='flex items-center justify-between text-dark font-semibold text-base'>
                        <p>Total</p>
                        <div className='flex items-center gap-4 text-dark'>
                            <p>
                            {payment.totalAmount}
                            </p>
                            <span>SAR</span>
                        </div>
                    </li>

                    {/* <li className='flex border-t pt-6 items-center justify-between text-grayText '>
                        <p>Payment with</p>
                        <div className='flex items-center gap-3 bg-gray-100 px-2 rounded-md'>
                            <i className="uil uil-card-atm text-lg"></i>
                            <p>****4567</p>
                            <i className="uil uil-pen text-lg"></i>
                        </div>
                    </li> */}


                </ul>
            </div>
        </div>
    )
}

export default PaymentDetails