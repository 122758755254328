import Attendings from '../../../components/bookingManagement/Attendings';
import Info from '../../../components/bookingManagement/Info';
import Header from '../../../components/bookingManagement/Header';
import PaymentDetails from '../../../components/bookingManagement/PaymentDetails';
import ProfileInfo from '../../../components/bookingManagement/ProfileInfo';
import StudentsSchedule from '../../../components/bookingManagement/StudentsSchedule';
import Tabs from '../../../components/bookingManagement/Tabs';
import { useBookingManagementContext } from '../../../context/BookingManagementContext';
import { useParams, useLocation } from 'react-router-dom';
import config from '../../../config';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import walletSvg from '../../../assets/svgs/wallet.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import more from '../../../assets/img/more.svg';

const BookingManagementRetake = () => {
  const { activeTab } = useBookingManagementContext();
  const [allData, setAllData] = useState([]);
  const params = useParams();
  let idParam = params.id;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState(true);
  // const []
  const navigate = useNavigate();

  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  // async function getData() {
  //     let data;
  //     data = await axios.get(config.API_URL + `/students/details${idParam}&en`)
  //     console.log('ssss ',data.data);
  //     setAllData(data.data)
  // }

  async function getBookingInfo() {
    let data;
    data = await axios.get(config.API_URL + `/booking/` + idParam);
    // console.log('ssss ',data.data);
    setAllData(data.data);
  }

  useEffect(() => {
    // getData()
    getBookingInfo();
  }, []);

  async function cancelBooking(cancelType) {
    await axios
      .post(config.API_URL + `/booking/cancel`, {
        bookingId: idParam,
        cancelType: cancelType,
      })
      .then((res) => {
        if (res.status === 'failed' || res.data.status === 'failed') {
          alert('Smoething went wrong.');
          return;
        }
        setChangeStatus(false);
        let tempData;
        // allData?.details?.status
      })
      .catch((err) => {
        alert('Smoething went wrong..');
      });
  }

  const tabs = [
    {
      id: 1,
      tab: 'Booking Details',
    },
    {
      id: 2,
      tab: 'Schedule',
    },
    {
      id: 3,
      tab: 'Payment Details',
    },
    {
      id: 4,
      tab: 'Attendings',
    },
  ];
  if (allData.requestType === 'Corporate') {
    tabs.push({
      id: 5,
      tab: 'Students',
    });
  }
  return (
    <div className="min-h-screen h-full pb-12">
      {/* <div>
                <Header />
            </div> */}

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
          setChangeStatus(true);
        }}
      >
        {changeStatus ? <ModalHeader>Cancel Booking</ModalHeader> : <></>}
        <ModalBody>
          <div>
            {changeStatus ? (
              <div>
                <p className=" my-3">
                  Do you want to add Balance to wallet or refund
                </p>

                <div className=" p-3 py-5 border rounded">
                  <div className="flex justify-between">
                    <div className="flex justify-between span-col-2">
                      <div>
                        <img src={walletSvg} />
                      </div>
                      <div className="mx-2">
                        <p className="mt-.5">
                          This Amount Will Added To Your Balance{' '}
                        </p>
                      </div>
                    </div>
                    <div className="flex text-xl gap-1 font-bold">
                      <h1>{allData?.payment?.paidAmount}</h1>
                      <p className="text-xs text-gray-400 mt-2">/SAR</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="place-self-center">
                <h1 className="text-center">Refund Request Submitted</h1>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {changeStatus ? (
            <>
              <div className="w-full">
                {' '}
                <Button
                  onClick={() => {
                    cancelBooking('Refund');
                  }}
                  className="w-full"
                  layout="outline"
                >
                  Refund
                </Button>
              </div>
              <div className="w-full">
                <Button
                  onClick={() => {
                    cancelBooking('Wallet');
                  }}
                  className="w-full "
                >
                  To Wallet
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="w-full">
                {' '}
                <Button
                  onClick={() => {
                    navigate('/admin/booking-management');
                    // cancelBooking('Refund')
                  }}
                  className="w-full"
                >
                  Booking Management
                </Button>
              </div>
            </>
          )}
        </ModalFooter>
      </Modal>
      <div className="py-4 sm:px-6 px-2">
        <div>
          <ProfileInfo headers={allData.headers} />
        </div>
        <div className="mt-4 bg-pure rounded-md w-full h-full sm:p-3">
          <div>
            <Tabs tabs={tabs} />
          </div>
          <div className="border-b border-b-gray-300">
            {activeTab === 1 ? (
              <Info info={allData.details} requestType={allData.requestType} />
            ) : activeTab === 2 ? (
              <StudentsSchedule
                schedule={allData.schedule}
                students={allData.students}
                requestType={allData.requestType}
              />
            ) : activeTab === 3 ? (
              <PaymentDetails
                payment={allData.payment}
                requestType={allData.requestType}
              />
            ) : activeTab === 4 ? (
              <Attendings />
            ) : activeTab === 5 && allData.requestType === 'Corporate' ? (
              <div>
                <TableContainer className="mb-8">
                  <Table>
                    <TableHeader>
                      <tr className="bg-[#F7F7F7]">
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Start From</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Status</TableCell>

                        <TableCell></TableCell>
                      </tr>
                    </TableHeader>
                    <TableBody>
                      {allData.students.map((user, i) => (
                        <TableRow key={i}>
                          <TableCell>{user.id}</TableCell>
                          <TableCell>
                            <div className="flex items-center text-sm">
                              <div>
                                <p className="font-semibold">{user.nameEn}</p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm">{user.email}</span>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm">{user.joinedDate}</span>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm">{user.cityEn}</span>
                          </TableCell>
                          <TableCell>
                            <Badge type="success">Active</Badge>
                          </TableCell>
                          <TableCell>
                            <img
                              className="cursor-pointer"
                              src={more}
                              onClick={() =>
                                navigate('/admin/students/profile/' + user.id)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TableFooter></TableFooter>
                </TableContainer>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="pb-4 pt-8">
            {allData?.details?.status === 'Canceled' ? (
              <button
                className="bg-gray-300 rounded-md py-2 sm:px-6 px-4 text-sm text-pure flex cursor-not-allowed items-center gap-2  hover:bg-primaryHover"
                disabled={
                  allData?.details?.status === 'Canceled' ? true : false
                }
                onClick={() => {
                  // setShowCancelModal(true)
                  openModal();
                }}
              >
                <i className="uil uil-cancel text-lg"></i>
                <span>Cancel Booking</span>
              </button>
            ) : (
              <button
                className="bg-primary rounded-md py-2 sm:px-6 px-4 text-sm text-pure flex items-center gap-2 cursor-pointer hover:bg-primaryHover"
                disabled={
                  allData?.details?.status === 'Canceled' ? true : false
                }
                onClick={() => {
                  // setShowCancelModal(true)
                  openModal();
                }}
              >
                <i className="uil uil-cancel text-lg"></i>
                <span>Cancel Booking</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingManagementRetake;
