import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import axios from 'axios';
import config from './config';
import authHeader from './services/auth-header';
import getRoutes from './routes/sidebar';
import { useParams, useLocation } from 'react-router-dom';

function Protected(props) {
  const Component = props.component;
  const permission = props.permission;
  const type = props.type;
  const navigate = useNavigate()
  const location = useLocation();
  let path = location.pathname//.split('/')[1];
  // console.log('props: ',props)
  useEffect(() => {
    protectRoute()
  },[])

  async function protectRoute() {//auth/test-token
    const routeList = []//await getRoutes();
    await axios.post(config.API_URL + "/auth/test-token",{token: localStorage.getItem("user"),userId: localStorage.getItem("userId")}, { headers: authHeader() }).then((result) => {
      // console.log('type? ',type ,result.data.type)
      if(type){
        if(type !== result.data.type){
          navigate("/login")
        }
      } else {

      
      
        // 'Amanah','Teller','Instructor','super admin','manager','student','corporate','branch admin'
        if(!["Amanah","Teller","super admin","manager","branch admin"].includes(result.data.type)){
            throw new Error('not allowed to view Admin')
        }

        // localStorage.setItem("type", result.data.type);
        // localStorage.setItem("privileges", JSON.stringify(result.data.privileges));
        // console.log('result.data.privileges?? : ',result.data.privileges)
        let isAllowed = false;
        // console.log('perm & path : ',permission,path)
        if(!permission){
          // console.log('perm not def : ',path)
          return
        }
        for(let route of result?.data?.privileges){
          // console.log('route.path: ',route.path, path)
          // console.log('route.permission: ',permission)
          for(let perm of permission){
            if((perm === 'read_'+route.id && route.read) || (perm === 'create_'+route.id && route.create) || (perm === 'edit_'+route.id && route.edit)){
              // if (sup.permissions && (sup.permissions.includes('read_' + pr.id) && pr.read === 1) || (sup.permissions.includes('create_' + pr.id) && pr.create === 1)) {
              isAllowed = true;
              break;
            }
          }
          if(isAllowed){
            break;
          }
          // if(route.path === path){
          //   isAllowed = true;
          //   break;
          // }
        }
        if(!isAllowed){
          console.log('not allowed????')
          navigate("/admin/dashboard")
        }
      }
    }).catch((err) => {
        console.log('resss err: ',err)
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("privileges");
        localStorage.removeItem("type");
        // window.location.replace('https://arkansite.mishari.dev/auth/login')
        navigate("/login")
    })
  }

  return (
    <div>
        <Component />
    </div>
  )
}

export default Protected