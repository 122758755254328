import React, { useState, useEffect, useRef } from 'react';
// import GoogleMapReact from 'google-map-react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import LocationIcon from '../assets/img/LocationIcon.svg';

export default function MapComponent({ handleChangeWithName, name, markerValue}) {
  const [markHandler, setMarkHandler] = useState(null)

  if(markerValue){
    console.log('map comp value: ',markerValue);
    // setMarkHandler(value)
  }
  const mapRef = useRef(null)
  if(mapRef.current){
    // console.log(mapRef);
    mapRef.current.on('click', (e)=>{
      // setPosition(e.latlng)
      // console.log(e.latlng)
      setMarkHandler([e.latlng.lat, e.latlng.lng]);
      handleChangeWithName(JSON.stringify([e.latlng.lat, e.latlng.lng]), name);
    })
    // setListenerSetup(true)
  }

  const mapPin = L.icon({
    iconUrl: LocationIcon,
    iconSize: [32,32],
    iconAnchor: [16, 32],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});

  return (
    <MapContainer
      className="h-60 rounded-lg mx-0 z-0"
      center={[24.7255553, 46.5423417]}
      zoom={6}
      // scrollWheelZoom={false}
      attributionControl={false}
      // onClick={(e) => {
      //   console.log(e.latlng);
      // }}
      ref={mapRef}
      // style={{ height: "100vh" }}
    >
      <TileLayer        
      className='mx-0'
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markHandler && (
        <Marker
          // position={[24.788729489286403, 46.58203125000001]}
          position={markHandler}
          icon={mapPin}
        >
          {/* <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                  </Popup> */}
        </Marker>
      )}
    </MapContainer>
  );
}
