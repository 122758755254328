import Header from '../../../components/bookRetake/Header'
import React, { useEffect, useRef, useState } from 'react'
import InquiryForm from '../../../components/bookRetake/InquiryForm'
import DateOfExam from '../../../components/bookRetake/DateOfExam'
import FinanceSvg from '../../../assets/svgs/Finance'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import LeftArrow from '../../../assets/svgs/LeftArrow'
import Stepper from '../../../components/bookRetake/Steper'
import { useBookRetakeContext } from '../../../context/BookRetakeContext'
import axios from 'axios';
import config from '../../../config';

const BookRetakePayment = () => {

    const [defBorder, setDefBorder] = useState(false);
    const [immedBorder, setImmedBorder] = useState(false);
    const { setCurrentStep, setComplete } = useBookRetakeContext();
    const [allData, setAllData] = useState([])
    const [paymentInfo, setPaymentInfo] = useState({});

    const [submitted, setSubmitted] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const selectedBook = location.state;

    const handleChangeWithName = (value, name) => {
        setAllData({ ...allData, [name]: value });
    };

    async function getPaymentInfo() {
        let tempData = {
            scheduleId: selectedBook.studentId || null,
            courseId: selectedBook?.schedule?.course.id,
            numberOfSeats: 1,
            type: selectedBook?.productType
        }
        // console.log('?tempData? ', tempData)
        let data = await axios.post(config.API_URL + '/booking/get-booking-fees', tempData);
        // console.log('paymentInfo? ',paymentInfo)
        setPaymentInfo(data.data);
    }

    useEffect(() => {
        setCurrentStep(2);
        getPaymentInfo();
    }, []);

    // console.log('selectedBook payment: ',selectedBook)

    async function submit() {
        try {
            setSubmitted(true);
            let final = allData;
            
            final.studentId =  selectedBook.studentId;
            final.scheduleId = selectedBook.schedule.id;
            final.paymentFlag = allData.paymentFlag
            final.retentionPeriod = allData.retentionPeriod || 0;
            let data;
            data = await axios.post(config.API_URL + '/booking/retake-exam', final);
            console.log('sss',data.status,data.statusText);
            if (data.status == 201  || data.status == 200 || data.status == 204 || data.statusText == "Created") {
                navigate('/admin/retake-exam/book-retake/succeed')
                
                return;
            }
            alert('Something went wrong, please try again later.');
            setSubmitted(false);

        } catch (error) {
            alert('Something went wrong, please trry again later..');
            setSubmitted(false);
        }
    }

    return (
        <div className='min-h-screen h-full pb-8'>
            <div>
                {/* <Header /> */}
            </div>
            <div>
                {/* Steper */}
                <div className='mt-6 md:mx-6 mx-2'>
                    <div className='bg-pure rounded-md w-full flex items-center justify-center py-4'>
                        <Stepper />
                    </div>
                </div>

                {/* Retake Payment */}
                <div className='bg-pure rounded-md py-4 px-3 mt-4 md:mx-6 mx-2'>
                    {/* Form */}
                    <div>
                        <InquiryForm isPayment={true} selectedBook={selectedBook} />
                    </div>
                    <div className='py-4'>
                        <DateOfExam selectedBook={selectedBook} paymentInfo={paymentInfo} />
                    </div>
                    {/* Payment */}
                    <div className='py-12'>
                        <h3 className='font-semibold text-xl'>Payment</h3>
                        <div className='border border-gray-300 rounded-md flex items-center justify-between mt-4 py-6 sm:px-4 px-2 lg:w-1/2 md:w-[80%] w-full'>
                            <div className='flex items-center gap-2'>
                                <div className='black-svg'>
                                    <FinanceSvg />
                                </div>
                                <p className='font-semibold sm:text-lg text-base'>Student Balance</p>
                            </div>
                            <div className='flex items-center gap-6'>
                                <div className='text-dark font-bold sm:text-2xl text-lg'>
                                    <p>{paymentInfo.balance}<span className='text-sm font-normal text-grayText'>/SAR</span></p>
                                </div>
                                <div>
                                    <input type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center gap-4 mt-4'>
                        <label
                            htmlFor='deferred'
                            className={`border ${defBorder ? 'border-primary' : 'border-gray-300'} rounded-md py-2 px-8 relative h-[80px] w-[150px] flex items-center justify-center cursor-pointer text-sm font-semibold`}
                            onClick={() => {
                                setImmedBorder(false);
                                setDefBorder(true);
                                handleChangeWithName('Deferred', 'paymentFlag')
                            }}
                        >
                            <div className='absolute top-1 right-1'>
                                <input
                                    name='paymentType'
                                    type="radio"
                                    id='deferred'
                                />
                            </div>
                            <p >Deferred</p>
                        </label>
                        <label
                            htmlFor='immediate'
                            className={`border
                        ${immedBorder ? 'border-primary' : 'border-gray-300'} rounded-md py-2 px-8 relative h-[80px] w-[150px] flex items-center justify-center text-sm font-semibold cursor-pointer`}
                            onClick={() => {
                                setImmedBorder(true);
                                setDefBorder(false);
                                handleChangeWithName('Immediate', 'paymentFlag')
                            }}>
                            <div className='absolute top-1 right-1'>
                                <input
                                    name='paymentType'
                                    type="radio"
                                    id='immediate'
                                />
                            </div>
                            <p>Immediate</p>
                        </label>
                    </div>

                    <div className='py-12'>
                        <div className='flex flex-col gap-1.5 flex-[0.4]'>
                            <label className='text-dark font-semibold text-sm'>Retention Period</label>
                            {immedBorder ?
                                <input
                                    onChange={(e) => {
                                        handleChangeWithName(0, 'retentionPeriod')
                                    }}
                                    disabled
                                    name="retentionPeriod"
                                    placeholder="2 Days"
                                    type="text"
                                    value="0"
                                    className="w-1/4 appearance-none p-1 px-2 text-gray-800 outline-none"
                                />
                                :
                                <input
                                    min={0}
                                    onChange={(e) => {
                                        handleChangeWithName(e.target.value, 'retentionPeriod')
                                    }}
                                    name="retentionPeriod"
                                    placeholder="2 Days"
                                    type="number"
                                    value={allData[`name`]}
                                    className="w-1/4 appearance-none p-1 px-2 text-gray-800 outline-none"
                                />
                            }
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className='bg-pure border-t border-t-gray-200 border-b border-b-gray-200 rounded-md py-4 mt-4 flex items-center justify-between   '>
                        <div className='py-2 px-8 text-dark text-sm border-2 border-gray-300 hover:bg-gray-300 hover:border-transparent duration-300 cursor-pointer rounded-md'>
                            <Link to='/retake-exam/book-retake'>Cancel</Link>
                        </div>
                        <div className='flex items-center sm:gap-4 gap-1'>
                            <Link to='/admin/retake-exam/book-retake'>
                                <div className='flex items-center sm:gap-1 font-semibold text-sm cursor-pointer'>
                                    <div>
                                        <LeftArrow />
                                    </div>
                                    Back
                                </div>
                            </Link>
                               { submitted ? 
                                <div className='bg-primary py-2 rounded-md cursor-pointer px-12 font-semibold text-pure' >
                                    Confirm
                                </div>
                                :
                                <div className='bg-primary py-2 rounded-md cursor-pointer px-12 font-semibold text-pure'  onClick={() => {
                                    submit();
                                }}>
                                    Confirm
                                </div>

                               }
                               
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BookRetakePayment
