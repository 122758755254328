import React from 'react';
import lock from '../../assets/img/lock.svg';
import number from '../../assets/img/number.svg';

const data = [
  {
    n: 1,
  },
  {
    n: 1,
  },
  {
    n: 1,
  },
  {
    n: 1,
  },
  {
    n: 1,
  },
  {
    n: 1,
  },
];

function Content() {
  return (
    <div>
      <h1 className="text-2xl">What will you learn?</h1>

      <div className="border border-[#F5F5F5] bg-[#F5F5F5] p-3">
        <div className="ml-3 flex mt-2">
          <img className="w-10 h-10" src={number} />
          <h1 className="text-3xl mt-1 ml-2">Basics of management</h1>
        </div>
        <hr className="my-5"></hr>
        {data.map((a,i) => (
          <div key={i} className="grid grid-cols-2 bg-white mt-1 rounded p-2 mx-2">
            <div className="flex">
              <img className="mx-3" src={lock} />
              <h3 className="mt-0.5"> Basic introduction to management</h3>
            </div>
            <p className=" justify-self-end  text-gray-600">5 Min</p>
          </div>
        ))}
      </div>
      <br></br>

      <div className="border border-[#F5F5F5] bg-[#F5F5F5] p-3">
        <div className="ml-3 flex mt-2">
          <img className="w-10 h-10" src={number} />
          <h1 className="text-3xl mt-1 ml-2">Learn trello for management</h1>
        </div>
        <hr className="my-5"></hr>
        {data.map((a,i) => (
          <div key={i} className="grid grid-cols-2 bg-white mt-1 rounded p-2 mx-2">
            <div className="flex">
              <img className="mx-3" src={lock} />
              <h3 className="mt-0.5"> Basic introduction to management</h3>
            </div>
            <p className=" justify-self-end  text-gray-600">5 Min</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Content;
