import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CourseImage from '../../../assets/img/CourseImage.svg';
import clock from '../../../assets/img/clock.svg';
import clockhistory from '../../../assets/img/clock-history.svg';
import user from '../../../assets/img/user.svg';
import book from '../../../assets/img/book.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import playList from '../../../assets/img/fontisto_play-list.svg';
import video from '../../../assets/img/video.png';
import stop from '../../../assets/img/gg_play-pause-o.svg';
import play from '../../../assets/img/ant-design_play-circle-filled.svg';
import locked from '../../../assets/img/locked.svg';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';


function CourseDetailsConfirm() {
  const param = useParams();
  const location = useLocation()
  const schedule = location.state.schedule;
  // console.log('schedule' , schedule)




  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <h1 className="font-bold text-3xl">Book Course</h1>
        <div className="flex flex-wrap m-4 mt-8">
          <div className="w-full md:w-1/2">
          <div>
      {/* Top  */}
      <div className="flex border">
        <div className="w-1/3">
          <img className="h-full w-full" src={CourseImage} />
          <h1> </h1>
        </div>
        <div className="w-2/3 ml-1">
          <h1 className="font-bold">
            {schedule.course.name}
          </h1>
          <div className="flex mt-3">
            {/* img */}
            <div>
              <img src={presonImage4}></img>
            </div>
            {/* name and description */}
            <div className="ml-3">
              <p className="text-base">{schedule.instructor.nameEn}</p>
              <p className="text-sm text-gray-600"> {schedule.instructor.field}</p>
            </div>
          </div>

          <hr className="m-2 bg-gray-600"></hr>

          <div className="grid grid-cols-2">
            <div className="flex">
              <h1 className="text-xl font-bold">{parseFloat(schedule.course.fees)}</h1>
              <p className="mt-1">/SAR</p>
            </div>
            {/* <div className="justify-self-end flex">
              <div className="mr-1 mt-1">
                <img src={star} />
              </div>
              <div>
                <strong>4.91</strong>(98)
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Crds */}

      {/* <div className="mx-5">
        <div className="grid grid-cols-2 gap-5">
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Booking Type
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="Corporate"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
          </div>
          <div className="w-full md:w-1/2">
          <div className="p-2 max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 mt-5">
                <div className="flex">
                  <div className="flex justify-between">
                    <img className="mr-3 w-6" src={playList} />
                    <h1 className="font-bold text-sm"> {schedule.course.numberOfSessions} lessons</h1>
                  </div>

                  <div className="ml-20">
                    <div>
                      <div className="flex">
                        <img src={clock} />
                        <p className="flex"> {schedule.course.hours} h</p>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  schedule.course.sessions && schedule.course.sessions.map((session,i) => {
                    return (
                      <div key={i} className="mt-4 flex justify-between bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2 mt-5">
                        <div className="flex justify-between">
                        <div className="mr-2 mt-1 ">{i+1}</div>
                          <img src={locked} />
                          <h1 className="mt-1 ml-2"> {String(session.name).slice(0, 20)}</h1>
                        </div>
                        <div className="ml-4 mt-1">
                          <h1>{session.duration} MIN</h1>
                        </div>
                      </div>
                    )
                  })
                }
                {/* <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2 mt-5">
                  <div className="mr-2 mt-1">1</div>
                  <div className="flex">
                    <img src={stop} />
                    <h1 className="mt-1 ml-2"> Intro</h1>
                  </div>
                  <div className="ml-36 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div> */}

                {/* <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">2</div>
                  <div className="flex">
                    <img src={play} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div>

                <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">3</div>
                  <div className="flex">
                    <img src={locked} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div> */}

                <div className="grid grid-cols-2 gap-3 mt-10 ml-5">
                  <div>
                    <button
                      onClick={() => {
                        // navigate(`/student/courses/confirm/${param.id}`, {state: {schedule}});
                      }}
                      className="bg-[#046F6D] text-white font-bold p-3 rounded-lg w-full"
                    >
                      {' '}
                      Book Now{' '}
                    </button>
                  </div>

                  <div className="flex mt-5 ml-5">
                    <h1 className="flex font-bold text-2xl">{parseFloat(schedule.course.fees)} </h1>
                    <p className="flex mt-2">/SAR</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseDetailsConfirm;
