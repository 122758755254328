import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import SectionTitle from '../../components/Typography/SectionTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  Pagination,
} from '@windmill/react-ui';
import { SearchIcon, TablesIcon } from '../../components/icons';
import response from './demo';
import corporateImg from '../../assets/img/buliding.svg';
import { useNavigate } from 'react-router-dom';
import more from '../../assets/img/more.svg';
import axios from 'axios';
import config from '../../config';
const response2 = response.concat([]);

function BookingManagement() {
  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);
  const [studentData, setStudentData] = useState([]);
  const [corporateData, setCorporateData] = useState([]);
  const [selectedType, setSelectedType] = useState('Student');

  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  // setup data for every table
  const [dataTable1, setDataTable1] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);

  // pagination setup
  const resultsPerPage = 10;
  const totalResults = response.length;

  // pagination change control
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  // pagination change control
  function onPageChangeTable2(p) {
    setPageTable2(p);
  }

  async function getStudentBooking() {
    let data;
    data = await axios.post(config.API_URL + '/booking/booking-management-list',{requestType:'Student'});
    // console.log('sssss? ',data.data)
    setStudentData(data.data);
  }

  async function getCorporateBooking() {
    let data;
    data = await axios.post(config.API_URL + '/booking/booking-management-list',{requestType: 'Corporate'});
    // console.log('sssss? ',data.data)
    setCorporateData(data.data);
  }

  async function handleAction(data, action) {
    await axios.patch(config.API_URL + '/booking/update-status/'+ data.id, {"status": action})
    getAllList()
  }

  function getAllList() {
    getCorporateBooking()
    getStudentBooking()
  }


  useEffect(() => {
    getCorporateBooking()
    getStudentBooking()

  }, []);

  

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          <div className="grid grid-cols-2">
            <div className="flex">
              <img src={corporateImg} className="w-8 mr-2" aria-hidden="true" />
              <PageTitle className="w-1/2">Booking Management</PageTitle>
            </div>
            <div className="w-5/6"></div>
          </div>

          <Button
            onClick={() => {
              navigate('/admin/new-book');
            }}
            className="w-1/5 h-14"
            style={{
              backgroundColor: '#046F6D',
              float: 'right',
              marginRight: '15px',
              marginBottom: '15px',
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
                fill="white"
              />
              <path
                d="M15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
                fill="white"
              />
              <path
                d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
                fill="white"
              />
            </svg>
            <span className="ml-2 font-bold">Add New Book</span>{' '}
          </Button>

          <div className="flex my-2">
            {/* <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1 p-1"
                onChange={(e) => {
                  setQuery(e.target.value);
                  // getAllList()
                }}
              >
                <option value="asc">A-Z</option>
                <option value="dec">Z-A</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 text-sm">STATUS:</label>
              <select
                className="mx-1 p-1"
                onChange={(e) => {
                  setQuery(e.target.value);
                  // getAllList()
                }}
              >
                <option value="All">ALL</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            */}
            <div className="mx-2 ">
              <select
              defaultValue={"Student"}
                className="border border-black rounded p-1"
                onChange={(e) => {
                  if(e.target.value === 'Student'){
                    setSelectedType('Student')
                  } else {
                    setSelectedType('Corporate')
                  }
                }}
              >
                <option value="Corporate" key={'corp'}>Corporate</option>
                <option value="Student"  key={'student'}>Student</option>
              </select>
            </div>
          </div>

          {/* <select className="border border-black rounded my-2" onChange={(e) => setQuery(e.target.value)}>
            <option value="Corporate">Corporate</option>
            <option value="Student">Student</option>
          </select> */}

          {selectedType === 'Student' ?
           <TableContainer className="mb-8">
           <Table>
             <TableHeader className="border-none">
               <tr className="bg-[#F7F7F7]">
                 <TableCell className="text-[#252631] text-sm">
                   Booking NO
                 </TableCell>
                 <TableCell className="text-[#252631] text-sm">
                   Student Name
                 </TableCell>
                 <TableCell className="text-[#252631] text-sm">
                   Student Code
                 </TableCell>
                 <TableCell className="text-[#252631] text-sm">
                   Booking Type
                 </TableCell>
                 <TableCell className="text-[#252631] text-sm">
                   Booking Date
                 </TableCell>
                 <TableCell className="text-[#252631] text-sm">
                   Status
                 </TableCell>
                 <TableCell></TableCell>
               </tr>
             </TableHeader>
             <TableBody className="">
               {studentData?.map((user, i) => (
                 <React.Fragment key={i}>
                   <TableRow className="border-y border-gray-200">
                     <TableCell className="">{user.id}</TableCell>
                     <TableCell>
                       <div className="flex items-center text-sm">
                         <div className="flex">
                           <p className="font-semibold mt-2 ml-1">
                             {user?.nameEn}
                           </p>
                           {/* <span>{user?.email}</span> */}
                         </div>
                       </div>
                     </TableCell>
                     <TableCell>
                       <span className="text-sm">{user?.code}</span>
                     </TableCell>
                     <TableCell>
                       <span className="text-sm">
                         {/* {moment(user.createdAt).format('DD MMM YYYY')} */}
                         {user?.type}
                       </span>
                     </TableCell>
                     <TableCell>
                       <span className="text-sm">{user?.date}</span>
                     </TableCell>
                     <TableCell>
                     {user.status !== 'Pending' ?
                        <Badge type={user.status === "Rejected" ? `danger` : 'success'}>{user.status}</Badge>
                        :
                        <>
                        <Button
                          style={{ backgroundColor: '#046F6D' }}
                          className="text-white mr-3"
                          onClick={() => {
                            handleAction(user, 'Accepted')
                            // getAllList()
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ backgroundColor: '#D80027' }}
                          className="bg-red-600 text-white"
                          onClick={() => {
                            handleAction(user, 'Rejected')
                            // getAllList()
                          }}
                        >
                          Reject
                        </Button>
                      </>
                      }
                   </TableCell>
                     <TableCell>
                       <a>
                         <img src={more} onClick={() => navigate('/admin/booking-management/book-details/'+user.id)} />
                       </a>
                     </TableCell>
                   </TableRow>
                   <TableRow>
                     <td className="py-0 h-1"></td>
                   </TableRow>
                 </React.Fragment>
               ))}
             </TableBody>
           </Table>
           <TableFooter className="border-none bg-none">
             <Pagination
               resultsPerPage={resultsPerPage}
               totalResults={studentData.length}
               onChange={onPageChangeTable1}
               style={{ color: '#718096' }}
               label="Table navigation"
             />
           </TableFooter>
         </TableContainer>
         :
         <TableContainer className="mb-8">
         <Table>
           <TableHeader className="border-none">
             <tr className="bg-[#F7F7F7]">
               <TableCell className="text-[#252631] text-sm">
                 Booking NO
               </TableCell>
               <TableCell className="text-[#252631] text-sm">
                Corporate Name
               </TableCell>
               <TableCell className="text-[#252631] text-sm">
                Corporate Code
               </TableCell>
               <TableCell className="text-[#252631] text-sm">
                 Booking Type
               </TableCell>
               <TableCell className="text-[#252631] text-sm">
                 Booking Date
               </TableCell>
               <TableCell className="text-[#252631] text-sm">
                 Status
               </TableCell>
               <TableCell></TableCell>
             </tr>
           </TableHeader>
           <TableBody className="">
             {corporateData?.map((user, i) => (
               <React.Fragment key={i}>
                 <TableRow className="border-y border-gray-200">
                   <TableCell className="">{user.id}</TableCell>
                   <TableCell>
                     <div className="flex items-center text-sm">
                       <div className="flex">
                         <p className="font-semibold mt-2 ml-1">
                           {user?.nameEn}
                         </p>
                         {/* <span>{user?.email}</span> */}
                       </div>
                     </div>
                   </TableCell>
                   <TableCell>
                     <span className="text-sm">{user?.code}</span>
                   </TableCell>
                   <TableCell>
                     <span className="text-sm">
                       {/* {moment(user.createdAt).format('DD MMM YYYY')} */}
                       {user?.type}
                     </span>
                   </TableCell>
                   <TableCell>
                     <span className="text-sm">{user?.date}</span>
                   </TableCell>
                   <TableCell>
                   {user.status !== 'Pending' ?
                        <Badge type={user.status === "Rejected" ? `danger` : 'success'}>{user.status}</Badge>
                        :
                        <>
                        <Button
                          style={{ backgroundColor: '#046F6D' }}
                          className="text-white mr-3"
                          onClick={() => {
                            handleAction(user, 'Accepted')
                            getAllList()
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ backgroundColor: '#D80027' }}
                          className="bg-red-600 text-white"
                          onClick={() => {
                            handleAction(user, 'Rejected')
                            getAllList()
                          }}
                        >
                          Reject
                        </Button>
                      </>
                      }
                 </TableCell>
                   <TableCell>
                     <a>
                       <img src={more} onClick={() => navigate('/admin/booking-management/book-details/'+user.id)} />
                     </a>
                   </TableCell>
                 </TableRow>
                 <TableRow>
                   <TableCell className="py-0 h-1 "></TableCell>
                 </TableRow>
               </React.Fragment>
             ))}
           </TableBody>
         </Table>
         <TableFooter className="border-none bg-none">
           <Pagination
             resultsPerPage={resultsPerPage}
             totalResults={corporateData.length}
             onChange={onPageChangeTable1}
             style={{ color: '#718096' }}
             label="Table navigation"
           />
         </TableFooter>
       </TableContainer>
        }



        </div>
      </div>
    </>
  );
}

export default BookingManagement;
