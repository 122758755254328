import { useNavigate } from 'react-router-dom';
import doneReg from '../../../assets/img/done-reg.svg';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

export default function Done() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ login, setLogin]  = useState(false);
  const [button_,setButton_] = useState()
  
  useEffect(() => {
    if ( location.pathname == "/student-register" || location.pathname == '/corporate-register'  || location.pathname == '/training-register'  || location.pathname ==  '/medical-register' ) {
      // setLogin(true);
      setButton_(<button
      onClick={() => {
        navigate('/login');
      }}
      className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
    >
      Login
    </button>)
    } else if(location.pathname == "/admin/corporate/add-new" || location.pathname.includes('corporates/edit')){
      setButton_(<button
        onClick={() => {
          navigate('/admin/corporate');
        }}
        className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
      >
        Corporates
      </button>)
    } else if(location.pathname == "/admin/student/add-new" || location.pathname.includes('students/edit')){
      setButton_(<button
        onClick={() => {
          navigate('/admin/students');
        }}
        className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
      >
        Students
      </button>)
    } else if(location.pathname == "/admin/training-center/add-new" || location.pathname.includes('training-center/edit')){
      setButton_(<button
        onClick={() => {
          navigate('/admin/training-center');
        }}
        className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
      >
        Training Centers
      </button>)
    } else if(location.pathname == "/admin/medical-center/add-new" || location.pathname.includes('medical-center/edit')){
      setButton_(<button
        onClick={() => {
          navigate('/admin/medical-center');
        }}
        className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
      >
        Medical Centers
      </button>)
    } 
  }, []);

  return (
    <div className="p-4 mb-20">
      <div className="flex flex-col items-center">
        <div className="wrapper">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <img src={doneReg}></img>
        <div className="mt-3 text-xl font-semibold uppercase text-green-500">
          Account Has Been Successfully Created!
        </div>
        <br/>
        <div className="text-lg font-semibold text-gray-500">
          {/* Username and Password has been sent to your Mobile Number. */}
        </div>
        <br/>
        {button_}
        {/* {login ? 
        <button
        onClick={() => {
          navigate('/login');
        }}
        className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
      >
        Login
      </button>
        : 
        <button
          onClick={() => {
            navigate('/login');
          }}
          className="h-12 px-24 text-white transition-colors duration-150 border border-gray-300 rounded-md focus:shadow-outline bg-[#046F6D] hover:bg-green-500 hover:text-green-100"
        >
          Branch
        </button>
        
        } */}
      </div>
    </div>
  );
}
