import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import Login2 from './pages/Login/Auth';
import CorporateRegister from './pages/Register/CorporateRegister/CorporateRegister';
import TrainingCenterRegister from './pages/Register/TrainingCenterRegister/TrainingCenterRegister';
import MedicalCenterRegister from './pages/Register/MedicalCenterRegister/MedicalCenterRegister';
import Dashboard from './pages/Dashboard/Dashboard';
import Corporate from './pages/Dashboard/Corporate';
import Student from './pages/Dashboard/Student';
import NewCorporate from './pages/Dashboard/NewCorporate';
import Booking from './pages/CorporateDashboard/Booking';
import BookingStudent from './pages/StudentDashboard/Booking/Booking';
import BookingInstructor from './pages/InstructorDashboard/Booking/Booking';
import InstructorDashboard from './pages/InstructorDashboard/Dashboard';
import InstructorContact from './pages/InstructorDashboard/Contact/Contact';
import StudentDetails from './pages/StudentDashboard/Courses/StudentDetails';
import InstructorBookingDetails from './pages/InstructorDashboard/Courses/StudentDetails';
import CourseDetails from './pages/CorporateDashboard/CourseDetails';
import CourseDetailsConfirm from './pages/CorporateDashboard/CourseDetailsConfirm';
import CourseStudentDetailsConfirm from './pages/StudentDashboard/Courses/CourseDetailsConfirm';
import Wizard from './components/wizard/Wizard';
import Training from './pages/Dashboard/Training';
import BookingRequests from './pages/Dashboard/Booking';
import BookingManagement from './pages/Dashboard/BookingManagement';
import BookingRetake from './pages/Dashboard/BookingRetake';
import Sidebar from "./components/global/sidebar";

import RetakeExam from "./pages/Dashboard/retakeExam";
import BookRetake from "./pages/Dashboard/bookRetake";
import BookRetakePayment from './pages/Dashboard/bookRetake/BookRetakePayment'; //"./pages/bookRetake/BookRetakePayment";
import BookRetakeSucceed from "./pages/Dashboard/bookRetake/BookRetakeSucceed";
import BookingManagementRetake from "./pages/Dashboard/BookingManagementRetake";

import AmanaInvoice from './pages/Dashboard/amanaInvoice/AmanaInvoice';
import AmanaBatch from './pages/Dashboard/amanaInvoice/AmanaBatch';
// import BranchLedger from './pages/Dashboard/LedgerSetup/BranchLedger';
// import BranchList from './pages/Dashboard/LedgerSetup/BranchList';

import DailyClosing from './pages/Dashboard/DailyClosing';
import AddClosing from './pages/Dashboard/DailyClosing/AddClosing';
import UploadClosing from './pages/Dashboard/DailyClosing/UploadClosing';
import FinishClosing from './pages/Dashboard/DailyClosing/FinishClosing';

import RefundPayment from './pages/Dashboard/RefundPayment';

import Protected from './Protected.js';

import Auth from './pages/Dashboard/Auth'

import Medical from './pages/Dashboard/Medical';
import CurrentTrainingRevenue from './pages/Dashboard/Report/CurrentTrainingRevenue';

// Dashboard
const StudentDashboard = lazy(() => import("./pages/StudentDashboard/Dashboard"));


// Chat (contact)
// const StudentChat = lazy(() => import("./pages/StudentDashboard/Chat"));


const Admin = lazy(() => import("./pages/Dashboard/Admin"));
const CorporateAdmin = lazy(() => import("./pages/CorporateDashboard/Admin"));
const StudentAdmin = lazy(() => import("./pages/StudentDashboard/Admin"));
const ExamAdmin = lazy(() => import("./pages/ExamDashboard/Admin"));
const StudentRequest = lazy(() => import("./pages/StudentDashboard/Requsts/MyRequst"));
const StudentScore = lazy(() => import("./pages/StudentDashboard/Scores/Score"));

const InstructorAdmin = lazy(() => import("./pages/InstructorDashboard/Admin"));

const SchedulingExaminer = lazy(() => import('./pages/ExamDashboard/Scheduling'))
const SchedulingInstructor = lazy(() => import('./pages/InstructorDashboard/Scheduling'))
const SchedulingStudent = lazy(() => import('./pages/StudentDashboard/Scheduling'))
const ExamStudent = lazy(() => import('./pages/StudentDashboard/Exams'))
const CheckOutStudent = lazy(() => import('./pages/StudentDashboard/Exams/CheckOut'))
const ExamSession = lazy(() => import('./pages/StudentDashboard/Exams/Session'))
const ExamReview = lazy(() => import('./pages/StudentDashboard/Exams/Review'))
const ExamDone = lazy(() => import('./pages/StudentDashboard/Exams/Score'))

const ConfirmAndPay = lazy(() => import('./pages/StudentDashboard/Courses/ConfirmAndPay'))
const DoneStudent = lazy(() => import('./pages/StudentDashboard/Courses/Done'))

const PaymentStudent = lazy(() => import('./pages/StudentDashboard/Payments'))
const PaymentCheckOutStudent = lazy(() => import('./pages/StudentDashboard/Payments/CheckOut'))


const StudentRegister = lazy(() => import("./pages/Register/StudentRegister/StudentRegister"));

const StudentRegisterWizard = lazy(() => import("./components/wizard/pages/Student"));
const CorporateRegisterWizard = lazy(() => import("./components/wizard/pages/Corporate"));
const MedicalRegisterWizard = lazy(() => import("./components/wizard/pages/Medical"));
const TrainingRegisterWizard = lazy(() => import("./components/wizard/pages/Training"));

const StudentRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Student"));
const CorporateRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Corporate"));
const MedicalRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Medical"));
const TrainingRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Training"));

const BranchManagement = lazy(() => import("./pages/Dashboard/BranchManagement"));
const UserManagement = lazy(() => import("./pages/Dashboard/UserManagement"));
const CourseManagement = lazy(() => import("./pages/Dashboard/CourseManagement"));
const AddBranch = lazy(() => import("./pages/Dashboard/AddBranch"));
const EditBranch = lazy(() => import("./pages/Dashboard/EditBranch"));
// const AddCourse = lazy (() => import("./components/wizardAdmin/pages/AddCourse"));
// const AddCourse = lazy (() => import("./pages/Dashboard/Course/addNewCourse/index"));

const AddNewCourse = lazy(() => import("./pages/Dashboard/Course/addNewCourse"));
const EditNewCourse = lazy(() => import("./pages/Dashboard/Course/editNewCourse"));
const CourseExam = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseExam"));
const CourseSessions = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseSessions"));

const AddUser = lazy(() => import("./pages/Dashboard/AddUser"));
const EditUser = lazy(() => import("./pages/Dashboard/EditUser"));
const CorporateProfile = lazy(() => import("./components/profile/Wizard"));
const InstructorProfile = lazy(() => import("./components/profile/Wizard"));
const BranchProfile = lazy(() => import("./components/profile/Wizard"));
const StudentsProfile = lazy(() => import("./components/profile/Wizard"));
const TraningProfile = lazy(() => import("./components/profile/Wizard"));
const MedicalProfile = lazy(() => import("./components/profile/Wizard"));
const UsersProfile = lazy(() => import("./components/profile/Wizard"));
const AddClassToBranch = lazy(() => import("./pages/Dashboard/AddClassToBranch"));
const Instructors = lazy(() => import("./pages/Dashboard/Instructor/index"));
const AddInstructor = lazy(() => import("./pages/Dashboard/Instructor/add"));
const EditInstructor = lazy(() => import("./pages/Dashboard/Instructor/edit"));

const Examiners = lazy(() => import("./pages/Dashboard/Examiner/index"));
const AddExaminer = lazy(() => import("./pages/Dashboard/Examiner/add"));
const EditExaminer = lazy(() => import("./pages/Dashboard/Examiner/edit"));

const Scheduling = lazy(() => import("./pages/Dashboard/Scheduling"));
const FeesSetup = lazy(() => import("./pages/Dashboard/FeesSetup"));
const EditFees = lazy(() => import("./pages/Dashboard/EditFees"));
const AddFeesSetup = lazy(() => import("./pages/Dashboard/AddFeesSetup"));
const HealthCheck = lazy(() => import("./pages/Dashboard/HealthCheck"));
const AllPayment = lazy(() => import("./pages/Dashboard/AllPayment"));
const AllPaymentStepOne = lazy(() => import("./pages/Dashboard/AllPaymentStepOne"));

const NewBook = lazy(() => import("./pages/Dashboard/Book/NewBook"));
const NewBookEnd = lazy(() => import("./pages/Dashboard/Book/NewBookEnd"));

const AddExam = lazy(() => import("./pages/Dashboard/AddExam"));
const AddTraning = lazy(() => import('./pages/Dashboard/AddTraning'))
const EditExam = lazy(() => import("./pages/Dashboard/EditExam"));
const EditTraning = lazy(() => import('./pages/Dashboard/EditTraning'))

const RefundCorporate = lazy(() => import("./pages/Dashboard/RefundCorporate"))

// ledgers
const BranchList = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchList"));
const BranchLedger = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchLedger"));

const Score = lazy(() => import("./pages/Dashboard/Score"));

const RequestManagement = lazy(() => import("./pages/Dashboard/RequestManagement"))

const AddExamInExaminer = lazy(() => import("./pages/ExamDashboard/AddExam"))

// Advance Payments
const AdvanceList = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceList"));
const AdvanceNew = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceNew"));


const ProductView = lazy(() => import("./pages/Dashboard/ProductView"));
const AddProduct = lazy(() => import("./pages/Dashboard/AddProduct"));

const StudentRegisterWizardAdmin = lazy(() =>
  import('./components/wizardAdmin/pages/Student'),
);
const CorporateRegisterWizardAdmin = lazy(() =>
  import('./components/wizardAdmin/pages/Corporate'),
);

const StudentRegisterionPage = lazy(() =>
  import('./pages/Dashboard/StudentRegisterionPage')
)
const StudentRegistrationPage = lazy(() =>
  import('./pages/Dashboard/StudentRegistrationPage')

)

// Products
const ProductsList = lazy(() => import("./pages/Products/List"));
const ProductsDetails = lazy(() => import("./pages/Products/Details"));
const ProductPayment = lazy(() => import("./pages/Products/Payment"))


const UserGroup = lazy(() => import("./pages/Dashboard/UserGroup"))


// TODO: fix this
const InstructorScheduling = lazy(() => import("./pages/Dashboard/InstructorRelated/Scheduling"))
const InstructorCourses = lazy(() => import("./pages/Dashboard/InstructorRelated/Booking/Booking"))
const InstructorBooking = lazy(() => import("./pages/Dashboard/InstructorRelated/Courses/StudentDetails"))

const ExaminerScore = lazy(() => import("./pages/ExamDashboard/Scores/Score"))
const ExaminerScheuling = lazy(() => import("./pages/Dashboard/ExaminerRelated/Scheduling"))
const ExaminerDashboard = lazy(() => import("./pages/ExamDashboard/Dashboard"))

const Reports = lazy(() => import("./pages/Dashboard/Report"))
const BranchRevenueReports = lazy(() => import("./pages/Dashboard/Report/BranchRevenue"))
const DetailedRevenueReports = lazy(() => import("./pages/Dashboard/Report/DetailedRevenue"))
const CurentCourseRevenueReports = lazy(() => import("./pages/Dashboard/Report/CurentCourseRevenue"))
const CurentTrainingRevenueReports = lazy(() => import("./pages/Dashboard/Report/CurrentTrainingRevenue"))
const StatmentCoursegRevenueReports = lazy(() => import("./pages/Dashboard/Report/StatementCourse"))
const StatmentTraningRevenueReports = lazy(() => import("./pages/Dashboard/Report/StatementTraning"))


const WorkFlow = lazy(() => import("./pages/Dashboard/WorkFlow/BranchList"))
const WorkFlowConfigration = lazy(() => import("./pages/Dashboard/WorkFlow/BranchConfigration"))



const DashboardAdmin = lazy(() => import("./pages/Dashboard/DashboardAdmin/index"));

const UserApproval = lazy(() => import("./pages/Dashboard/UserApproval"))


// Corporate

const CorporateDashboard = lazy(() => import("./pages/CorporateDashboardNew/Dashboard"))
const CorporateBooking = lazy(() => import("./pages/CorporateDashboardNew/Booking/Booking"))
const CorporateCourse = lazy(() => import('./pages/CorporateDashboardNew/Courses/StudentDetails'))
const CorporateScheduling = lazy(() => import("./pages/CorporateDashboardNew/Scheduling"))

function GlobalDebug(debugOn, suppressAll) {
  var savedConsole = console;
  var suppress = suppressAll || false;
  if (debugOn === false) {
    // supress the default console functionality
    console = {};
    console.log = function () { };
    // supress all type of consoles
    if (suppress) {
      console.info = function () { };
      console.warn = function () { };
      console.error = function () { };
    } else {
      console.info = savedConsole.info;
      console.warn = savedConsole.warn;
      console.error = savedConsole.error;
    }
  } else {
    console = savedConsole;
  }
}

function App() {
  useEffect(() => {
    localStorage.removeItem('theme');
  }, []);

  // useEffect(() => {
  //   (process.env.NODE_ENV === "production" || true ||
  //    process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false,true);
  // }, []);

  return (
    <>
      <Suspense fallback={<h3>Loading...</h3>}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/login" element={<Login />}></Route> */}
            <Route path="/login" element={<Login2 />}></Route>
            <Route path="/register" element={<Login2 />}></Route>
            <Route path="/Auth/:token" element={<Auth />}></Route>
            {/* <Route path="/student-register2" element={<StudentRegister />}></Route>
          <Route path="/corporate-register2" element={<CorporateRegister />}></Route> */}

            {/* <Route path="/student-register" element={<StudentRegisterionPage />}></Route> */}

            <Route path="/student-register" element={<StudentRegisterWizard />}></Route>

            <Route path="/training-register" element={<TrainingRegisterWizard />}></Route>
            <Route path="/medical-register" element={<MedicalRegisterWizard />}></Route>
            <Route path="/corporate-register" element={<CorporateRegisterWizard />}></Route>
            {/* <Route
            path="/training-center-register"
            element={<TrainingCenterRegister />}
          ></Route>
          <Route
            path="/medical-center-regixster"
            element={<MedicalCenterRegister />}
          ></Route> */}
            <Route path="/admin/*" element={<Protected component={Admin} />} >


              <Route path="dashboard" element={<Protected component={DashboardAdmin} />}></Route>


              <Route path="user-group" element={<Protected component={UserGroup} type={'super admin'} />}></Route>
              {/* <Route path="staff"></Route> */}
              {/* permission={{action:'create',resourse: 1}} */}
              <Route path="corporate" element={<Protected permission={['read_4']} component={Corporate} />}></Route>
              <Route path="corporate/profile" element={<Protected permission={['read_4']} component={CorporateProfile} />}></Route>
              <Route path="corporate/add-new" element={<Protected permission={['create_4']} component={CorporateRegisterWizard} />}></Route>
              <Route path="corporate/edit/:id" element={<Protected permission={['edit_4']} component={CorporateRegisterWizard} />}></Route>
              <Route path="students" element={<Protected permission={['read_3']} component={Student} />}></Route>
              <Route path="students/add-new" element={<Protected permission={['create_3']} component={StudentRegisterWizard} />}></Route>
              <Route path="students/edit/:id" element={<Protected permission={['edit_3']} component={StudentRegisterWizard} />}></Route>
              <Route path="training-center/add-new" element={<Protected permission={['create_4']} component={TrainingRegisterWizard} />}></Route>
              <Route path="training-center/edit/:id" element={<Protected permission={['edit_4']} component={TrainingRegisterWizard} />}></Route>
              <Route path="medical-center/add-new" element={<Protected permission={['create_4']} component={MedicalRegisterWizard} />}></Route>
              <Route path="medical-center/edit/:id" element={<Protected permission={['edit_4']} component={MedicalRegisterWizard} />}></Route>
              <Route path="medical-center" element={<Protected permission={['read_4']} component={Medical} />}></Route>
              <Route path="training-center" element={<Protected permission={['read_4']} component={Training} />}></Route>
              <Route path="booking-requests" element={<Protected permission={['read_9']} component={BookingRequests} />}></Route>
              <Route path="booking-management" element={<Protected permission={['read_9']} component={BookingManagement} />}></Route>
              <Route path="request-management" element={<Protected permission={['read_2']} component={RequestManagement} />}></Route>
              <Route path="user-approval" element={<Protected permission={['read_2']} component={UserApproval} />}></Route>
              <Route path="booking-retake-exam" element={<Protected permission={['create_9']} component={BookingRetake} />}></Route>
              <Route path="branch-management" element={<Protected permission={['read_2']} component={BranchManagement} />}></Route>
              <Route path="course-management" element={<Protected permission={['read_6']} component={CourseManagement} />}></Route>
              <Route path="add-branch" element={<Protected permission={['create_2']} component={AddBranch} />}></Route>
              <Route path="edit-branch/:id" element={<Protected permission={['edit_2']} component={EditBranch} />}></Route>
              {/* <Route path="add-course" element={<AddCourse />}></Route> */}

              <Route path='course-management-test' element={<Protected permission={['read_6']} component={AddNewCourse} />} />
              <Route path='add-new-course' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
              <Route path='edit-course/:id' element={<Protected permission={['edit_6']} component={EditNewCourse} />} />
              <Route path='add-new-course/:id' element={<Protected permission={['create_6']} component={AddNewCourse} />} />
              <Route path='add-new-course/sessions/:id' element={<Protected permission={['create_6']} component={CourseSessions} />} />

              <Route path='add-new-course/exam/:id' element={<Protected permission={['create_6']} component={CourseExam} />} />
              <Route path="add-user" element={<Protected permission={['create_1']} component={AddUser} />}></Route>
              <Route path="edit-user/:id" element={<Protected permission={['edit_1']} component={EditUser} />}></Route>
              <Route path="add-class-to-branch/:id" element={<Protected permission={['create_2']} component={AddClassToBranch} />}></Route>
              <Route path="branches/profile/:id" element={<Protected permission={['read_2']} component={BranchProfile} />}></Route>
              <Route path="students/profile/:id" element={<Protected permission={['read_3']} component={StudentsProfile} />}></Route>
              <Route path="training-center/profile/:id" element={<Protected permission={['read_4']} component={TraningProfile} />}></Route>
              <Route path="medical-center/profile/:id" element={<Protected permission={['read_4']} component={MedicalProfile} />}></Route>
              <Route path="instructor/profile/:id" element={<Protected permission={['read_1']} component={InstructorProfile} />}></Route>
              <Route path="users/profile/:id" element={<Protected permission={['read_1']} component={UsersProfile} />}></Route>
              <Route path="user-management" element={<Protected permission={['read_1']} component={UserManagement} />}></Route>
              <Route path="instructors" element={<Protected permission={['read_1']} component={Instructors} />}></Route>
              <Route path="add-instructor" element={<Protected permission={['create_1']} component={AddInstructor} />}></Route>
              <Route path="edit-instructor/:id" element={<Protected permission={['edit_1']} component={EditInstructor} />}></Route>
              <Route path="examiners" element={<Protected permission={['read_1']} component={Examiners} />}></Route>
              <Route path="add-examiner" element={<Protected permission={['create_1']} component={AddExaminer} />}></Route>
              <Route path="edit-examiner/:id" element={<Protected permission={['edit_1']} component={EditExaminer} />}></Route>


              <Route path="Scheduling" element={<Protected permission={['read_8']} component={Scheduling} />}></Route>
              <Route path="fees-setup" element={<Protected permission={['read_10']} component={FeesSetup} />}></Route>
              <Route path="add-fees-setup" element={<Protected permission={['create_10']} component={AddFeesSetup} />}></Route>
              <Route path="edit-fees/:id" element={<Protected permission={['edit_10']} component={EditFees} />}></Route>


              <Route path="all-payments" element={<Protected permission={['read_10', 'read_17']} component={AllPayment} />}></Route>
              <Route path="all-payments/1" element={<Protected permission={['read_10', 'read_17']} component={AllPaymentStepOne} />}></Route>
              <Route path="health-check" element={<Protected permission={['read_20']} component={HealthCheck} />}></Route>
              <Route path="new-book" element={<Protected permission={['create_9']} component={NewBook} />}></Route>
              <Route path="new-book/end" element={<Protected permission={['create_9']} component={NewBookEnd} />}></Route>
              <Route path="add-exam" element={<Protected permission={['create_8']} component={AddExam} />}></Route>
              <Route path="edit-exam/:id" element={<Protected permission={['create_8']} component={EditExam} />}></Route>
              <Route path="add-training" element={<Protected permission={['create_8']} component={AddTraning} />} ></Route>
              <Route path="edit-training/:id" element={<Protected permission={['create_8']} component={EditTraning} />} ></Route>
              <Route path="daily-closing" element={<Protected permission={['read_10', 'read_17']} component={DailyClosing} />}></Route>
              <Route path="daily-closing/add-closing" element={<Protected permission={['create_10', 'create_17']} component={AddClosing} />}></Route>
              <Route path="daily-closing/add-closing/upload" element={<Protected permission={['create_10', 'create_17']} component={UploadClosing} />}></Route>
              <Route path="daily-closing/add-closing/finish" element={<Protected permission={['create_10', 'create_17']} component={FinishClosing} />}></Route>

              <Route path="refund-corporate" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundCorporate} />}></Route>
              <Route path="refund-payment" element={<Protected permission={['create_10', 'create_17', 'read_10', 'read_17']} component={RefundPayment} />}></Route>


              <Route path='retake-exam' element={<Protected permission={['read_9']} component={RetakeExam} />} ></Route>
              <Route path='retake-exam/book-retake' element={<Protected permission={['create_9']} component={BookRetake} />} ></Route>
              <Route path='retake-exam/book-retake/payment' element={<Protected permission={['create_9']} component={BookRetakePayment} />} ></Route>
              {/* <Route path='retake-exam/book-retake/succeed' element={<BookRetakeSucceed />} ></Route> */}
              <Route path='booking-management/book-details/:id' element={<Protected permission={['read_9']} component={BookingManagementRetake} />} ></Route>

              {/* BranchLedger */}
              <Route path='ledger-setup' element={<Protected permission={['read_10']} component={BranchList} />} ></Route>
              <Route path='ledger-setup/:id' element={<Protected permission={['read_10']} component={BranchLedger} />} ></Route>

              {/* AdvancePayment */}
              <Route path='advanced-payment' element={<Protected permission={['read_10']} component={AdvanceList} />} ></Route>
              <Route path='advanced-payment/new' element={<Protected permission={['create_10']} component={AdvanceNew} />} ></Route>

              <Route path='scores' element={<Protected permission={['read_19']} component={Score} />} ></Route>
              <Route path='amana-invoice' element={<Protected permission={['read_10', 'read_18']} component={AmanaInvoice} />} ></Route>
              <Route path='amana-invoice/:id' element={<Protected permission={['read_10', 'read_18']} component={AmanaBatch} />} ></Route>


              <Route path='product' element={<Protected permission={['read_16']} component={ProductView} />} ></Route>
              <Route path='product-add' element={<Protected permission={['read_16', 'create_16']} component={AddProduct} />} ></Route>

              <Route path='instructor-scheduling' element={<Protected permission={['read_16', 'create_16']} component={InstructorScheduling} />} ></Route>
              <Route path='instructor-courses' element={<Protected permission={['read_16', 'create_16']} component={InstructorCourses} />} ></Route>
              <Route path='instructor-courses/:id' element={<Protected permission={['read_16', 'create_16']} component={InstructorBooking} />} ></Route>

              <Route path='examiner-score' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScore} />} ></Route>
              <Route path='examiner-scheduling' element={<Protected permission={['read_16', 'create_16']} component={ExaminerScheuling} />} ></Route>


              <Route path='reports' element={<Protected permission={['read_16', 'create_16']} component={Reports} />} ></Route>
              <Route path='reports/branch-revenue' element={<Protected permission={['read_16', 'create_16']} component={BranchRevenueReports} />} ></Route>
              <Route path='reports/detailed-revenue' element={<Protected permission={['read_16', 'create_16']} component={DetailedRevenueReports} />} ></Route>
              <Route path='reports/curent-course-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentCourseRevenueReports} />} ></Route>
              <Route path='reports/current-traning-revenue' element={<Protected permission={['read_16', 'create_16']} component={CurentTrainingRevenueReports} />} ></Route>
              <Route path='reports/statement-of-cancellation-course' element={<Protected permission={['read_16', 'create_16']} component={StatmentCoursegRevenueReports} />} ></Route>
              <Route path='reports/statement-of-cancellation-traning' element={<Protected permission={['read_16', 'create_16']} component={StatmentTraningRevenueReports} />} ></Route>


              <Route path='workflow' element={<Protected permission={['read_16', 'create_16']} component={WorkFlow} type={'super admin'} />} ></Route>
              <Route path='workflow/configration/:id' element={<Protected permission={['read_16', 'create_16']} component={WorkFlowConfigration} type={'super admin'} />} ></Route>



            </Route>
            <Route path="/corporate" element={<Protected type={'corporate'} component={CorporateAdmin} />} >
              <Route path="dashboard" element={<Protected type={'corporate'} component={CorporateDashboard} />}></Route>
              <Route path="courses" element={<Protected type={'corporate'} component={CorporateBooking} />}></Route>
              <Route path="course/:id" element={<Protected type={'corporate'} component={CorporateCourse} />}></Route>
              <Route path="courses/confirm/:id" element={<Protected type={'corporate'} component={CourseDetails} />}></Route>
              <Route path="scheduling" element={<Protected type={'corporate'} component={CorporateScheduling} />}></Route>
            </Route>

            <Route path="/student" element={<Protected type={'student'} component={StudentAdmin} />} >
              <Route path="dashboard" element={<Protected type={'student'} component={StudentDashboard} />}></Route>
              {/* BOOKING */}
              <Route path="booking" element={<Protected type={'student'} component={BookingStudent} />}></Route>
              {/* MyCourse */}
              <Route path="courses" element={<Protected type={'student'} component={BookingStudent} />}></Route>
              <Route path="course/:id" element={<Protected type={'student'} component={StudentDetails} />}></Route>
              <Route path="courses/confirm/:id" element={<Protected type={'student'} component={CourseStudentDetailsConfirm} />}></Route>

              {/* MyShudcle */}
              <Route path="scheduling" element={<Protected type={'student'} component={SchedulingStudent} />}></Route>
              {/* EXAM */}
              <Route path="exam" element={<Protected type={'student'} component={ExamStudent} />}></Route>
              <Route path="exam/check-out/:id" element={<Protected type={'student'} component={CheckOutStudent} />}></Route>
              <Route path="exam/session/:id" element={<Protected type={'student'} component={ExamSession} />}></Route>
              <Route path="exam/session-review/:id" element={<Protected type={'student'} component={ExamReview} />}></Route>
              <Route path="exam/score" element={<Protected type={'student'} component={ExamDone} />}></Route>
              <Route path="exam/confirm-and-pay" element={<Protected type={'student'} component={ConfirmAndPay} />}></Route>
              <Route path="exam/done" element={<Protected type={'student'} component={DoneStudent} />}></Route>
              {/* SCORE/CERIFICATE */}
              <Route path="scores" element={<Protected type={'student'} component={StudentScore} />}></Route>
              {/* payment */}
              <Route path="payments" element={<Protected type={'student'} component={PaymentStudent} />}></Route>
              {/* <Route path="payments/check-out" element={<Protected type={'student'} component={PaymentCheckOutStudent} />}></Route> */}
              <Route path="payments/check-out/:id" element={<Protected type={'student'} component={PaymentCheckOutStudent} />}></Route>

              {/* myRequest */}
              <Route path="requests" element={<Protected type={'student'} component={StudentRequest} />}></Route>

              {/* Contact */}
              <Route path="contact" element={<Protected type={'student'} component={InstructorContact} />}></Route>

            </Route>

            <Route path="/instructor" element={<Protected type={'Instructor'} component={InstructorAdmin} />} >
              <Route path="dashboard" element={<Protected type={'Instructor'} component={InstructorDashboard} />}></Route>
              <Route path="scheduling" element={<Protected type={'Instructor'} component={SchedulingInstructor} />}></Route>
              <Route path="courses" element={<Protected type={'Instructor'} component={BookingInstructor} />}></Route>
              <Route path="course/:id" element={<Protected type={'Instructor'} component={InstructorBookingDetails} />}></Route>
              <Route path="contact" element={<Protected type={'Instructor'} component={InstructorContact} />}></Route>
            </Route>

            <Route path="/examiner" element={<ExamAdmin />} >
              <Route path="dashboard" element={<ExaminerDashboard />}></Route>
              {/* BOOKING */}
              <Route path="booking" element={<BookingStudent />}></Route>
              <Route path="add-exam/:id" element={<AddExamInExaminer />}></Route>
              {/* MyCourse */}
              <Route path="courses" element={<BookingStudent />}></Route>
              <Route path="course/:id" element={<StudentDetails />}></Route>
              <Route path="courses/confirm/:id" element={<CourseStudentDetailsConfirm />}></Route>

              {/* MyShudcle */}
              <Route path="scheduling" element={<SchedulingExaminer />}></Route>
              {/* EXAM */}
              <Route path="exam" element={<ExamStudent />}></Route>
              {/* SCORE/CERIFICATE */}
              <Route path="scores" element={<ExaminerScore />}></Route>
            </Route>

              {/* Products */}
              <Route path="products" element={<ProductsList />}></Route>
              <Route path="product-details/:id" element={<ProductsDetails />}></Route>
              <Route path="product-payment/:id" element={<ProductPayment />}></Route>


            <Route path="/" element={<Login2 />}></Route>
            <Route path="*" element={<Login2 />}></Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
