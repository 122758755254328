import React, { useState, useEffect } from 'react';

// import CourseImage from '../../../assets/img/CourseImage.svg';
import CourseImage from '../../../assets/img/CourseImage.png';
import clockhistory from '../../../assets/img/clock-history.svg';
import LocationSVG from '../../../assets/svgs/Location.svg';
import BookingTypeSVG from '../../../assets/svgs/BookingType.svg';
import LangSVG from '../../../assets/svgs/Lang.svg';

import user from '../../../assets/img/user.svg';
import book from '../../../assets/img/book.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import Search from '../../../components/util/Search';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import parse from 'html-react-parser'

const data = [
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 2,
    name: 'name',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
];

function Booking() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [list, setList] = useState([])
  const [booking, setBooking] = useState([])
  const [bookingType, setBookingType] = useState('Training')
  const [type, setType] = useState([])
  const [branches, setBranches] = useState([])
  const [lang, setLang] = useState([])
  const [levels, setLevels] = useState([])

  const location = useLocation()

  const path = location.pathname.split('/')[2];

  console.log(location.pathname.split('/')[2])

  async function getList() {
    try {
      let data;
      data = await axios.post(config.API_URL + '/booking/fetch-available-booking', { })
      // console.log('__' , data.data)
      let temp = data.data;
      let uniq = [];
      let ids = []
      temp.map((tem) => {
        if(!ids.includes(tem.course.id)){
          // console.log('?>? ',tem.course.id)
          ids.push(tem.course.id);
          uniq.push(tem)
        }
      });
      temp = temp.filter((te) => te.type === 'Training')
      // console.log('ids',ids)
      setList(temp);
      // setBooking(uniq.filter((co) => co.type === 'Training'))
      setBooking(temp)
      // console.log('__' , temp)

    } catch (error) {
      setList([]);
    }
  }



  async function getType() {
    let data;
    data = await axios.get(config.API_URL + '/schedule/trainee-types')
    setType(data.data)

  }

  async function getBranches() {
    let data;
    data = await axios.post(config.API_URL + '/branches/list', {})
    setBranches(data.data)
  }

  async function getLang() {
    let data;
    data = await axios.get(config.API_URL + '/languages');
    setLang(data.data)
  }

  async function getLevel() {
    let data;
    data = await axios.get(config.API_URL + '/courses/level')
    setLevels(data.data)
  }

  useEffect(() => {
    getList()
    getType()
    getBranches()
    getLang()
  }, [])

  return (
    <>
      <div className="bg-[#FFFFFF] my-8 border-sm p-4">
  
       
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Card */}
          {
            booking && booking.length === 0 ?
            <div>
              <h1> No Available Courses.</h1>
              </div>

              :
              <></>
          }
          {
            // data
            //   .filter((course) => course.name.toLowerCase().includes(query))
            booking && booking.map
              ((item, index) => (
                <div className="" key={index}>

                  <div className="relative">
                    <div><img src={CourseImage} alt="Course" className="w-full" /></div>
                    <div className="absolute top-2  right-2 left-2/4 bottom-30 bg-white bg-opacity-75 grid grid-cols-3 gap-4">
                        <div className='flex gap-1 ml-10'>
                          {/* <img className='mt-0.5 w-4 h-6' src={LocationSVG} /> */}
                          <span className="text-sm sm:text-base">{item?.course?.level}</span>
                        </div>
                    </div>
                    <div className="absolute top-50 left-5 right-5 bottom-2 bg-white bg-opacity-50 grid grid-cols-3 gap-4">
                        <div className='flex gap-1'>
                          <img className='mt-0.5 w-4 h-6' src={LocationSVG} />
                          <span className="text-sm sm:text-base">{item?.location?.nameEn}</span>
                        </div>
                        <div className='flex gap-1'>
                          <img className='mt-0.5  w-4 h-6' src={BookingTypeSVG} />
                          <span className="text-sm sm:text-base">{item?.traineeType}</span>
                        </div>
                        <div className='flex gap-1'>
                          <img className='mt-0.5  w-4 h-6' src={LangSVG} />
                          <span className="text-sm sm:text-base">{item?.language?.nameEn}</span>
                        </div>
                    </div>
                  </div>


                  {/* TITLE Des */}
                  <div className="flex mt-3">
                    <div className='w-full'>
                      {/* {console.log(item)} */}
                      <h1 className="font-bold ">{item.course.name}</h1>
                      <p className="text-gray-700 text-sm">
                        {/* {String(parse(item.question ? item.course : '')).slice(0, 50) + '...'} */}
                        {parse(item.course.description ? item.course.description : '' )}
                       
                      </p>
                    </div>
                    
                  </div>


                  <hr className="my-2 bg-gray-800 " />
                  {/* Hour student */}

                  <div className="flex items-center">
                    <div className="grid grid-cols-3 ml-12 gap-5">
                      <div>
                        <div>
                          <img src={clockhistory} />
                        </div>
                        <div>
                          <p className="text-xs text-gray-800"> {item.course.hours} Hour</p>
                        </div>
                      </div>

                      <div>
                        <div className="mt-1">
                          <img src={user} />
                        </div>
                        <div>
                          <p className="text-xs text-gray-800 mt-0.5">
                            {' '}
                            {item.course.capacity} Student
                          </p>
                        </div>
                      </div>

                      <div>
                        <div>
                          <img src={book} />
                        </div>
                        <div>
                          <p className="text-xs text-gray-800"> {item.course.sessions.length} Session</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <hr className="mt-1 mb-1 bg-gray-800" /> */}

                  {/* profile image */}
                  <div className="mt-3">
                    <div className="flex">
                      {/* <div className="mr-2">
                        <img src={presonImage4} />
                      </div> */}
                      <div>
                        <p className="font-bold  text-sm mt-1">{item.instructor.nameEn}</p>
                        <p className="text-gray-900  text-xs mt-1">
                          {item.instructor.field}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Button */}
                  <div className="grid grid-cols-2 gap-3 mt-3">
                    <div>
                      {path == 'courses' ?
                        <button
                          onClick={() => [
                            navigate(`/instructor/course/${item.scheduleId}`, { state: { schedule: item , new: false } }),
                          ]}
                          className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                        >
                          {' '}
                          View{' '}
                        </button>
                        :
                        <button
                          onClick={() => [
                            navigate(`/instructor/course/${item.scheduleId}`, { state: { schedule: item, new: true } }),
                          ]}
                          className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                        >
                          {' '}
                          Book Now{' '}
                        </button>
                      }

                    </div>

                    {path == 'courses'
                      ?
                      <></>
                      :
                      <div className="flex mt-5 ml-5">
                        <h1 className="flex font-bold text-2xl">{parseFloat(item.course.fees)} </h1>
                        <p className="flex mt-2">/SAR</p>
                      </div>
                    }
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
}

export default Booking;
