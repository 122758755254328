import React, { useState, useEffect } from 'react';
import allPaymentImage from '../../assets/img/AllPayment.png';
import paymentconfirm from '../../assets/img/payment-confirm.png';
import { Button } from '@windmill/react-ui';
import wallet from '../../assets/svgs/wallet.svg'
import mastercard from '../../assets/svgs/mastercard.svg'
import Visa from '../../assets/svgs/visa.svg';
import money from '../../assets/svgs/money.svg'
import wire from '../../assets/svgs/receipt-text.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import uploaderService from '../../services/uploaderService';
import UploadFileImage from '../../assets/img/Line.svg';
import uploadImage from '../../assets/img/uploadImage.svg'
import Dropzone from 'react-dropzone';
import axios from 'axios';
import config from '../../config';

function RefundPayment() {
  const [defBorder, setDefBorder] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);
  const [fullPaymentFlag, setFullPaymentFlag] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState(false);

  const [advancedPayment, setAdvancePayment] = useState(false);
  const [visa, setVisa] = useState(false);
  const [cash, setCash] = useState(false);
  const [wirteTrasfer, setWireTrasfer] = useState(false);
  const [done, setDone] = useState(false)

  const [allData, setAllData] = useState([])

  const [refundError, setRefundError] = useState(true);
  const [paymentMethodError, setPaymentError] = useState(true);
  const [bankNameError, setBankNameError] = useState(true);
  const [accountNumberError, setAccountNumberError] = useState(true);
  const [ibanError, setIbanError] = useState(true);

  const [showErrors, setShowErrors] = useState(false);


  const { state } = useLocation();

  const navigate = useNavigate();

  const paymentInfo = state;
  console.log('Refund all data :: :: :', state);
  //console.log('paymentInfo: ', paymentInfo)

  const handleChangeFile = (file, name) => {
    //console.log(file[0]);
    const data = {
      dataType: "arkan",
      file: file[0]
    }
    uploaderService.postUpload(data).then((result) => {
      setAllData({ ...allData, [name]: result.data.id });
    })
  };

  const handleChangeWithName = (value, name) => {
    // //console.log('value, name  ',value, name)
    setAllData({ ...allData, [name]: value })
    //console.log('??>?>?', allData);
  };

  const handleChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setAllData({ ...allData, [name]: value });
    // console.log(allData);
  };

  async function submit() {
    console.log(paymentInfo);
    let data;
    let finalData = allData;
    //console.log('final ?> ',parseFloat(paymentInfo?.booking?.schedule?.course?.fee[0]?.totalAmount) , parseFloat(paymentInfo?.paidAmount))
    if (finalData.amount === undefined) finalData.amount = parseFloat(paymentInfo?.booking?.schedule?.course?.fee[0]?.totalAmount) - parseFloat(paymentInfo?.paidAmount);
    finalData.branchId = paymentInfo?.branchId;
    finalData.bookingId = paymentInfo?.bookingId;
    finalData.paymentId = paymentInfo?.paymentId?.paymentId;
    finalData.studentId = paymentInfo?.studentId?.studentId;
    console.log(paymentInfo?.studentId?.studentId)
    console.log(finalData?.studentId)
    finalData.corporateId = paymentInfo?.corporateId?.corporateId;
    // finalData.paymentMethod = 'Cash';
    // finalData.paymentType = 'Regular';
    //console.log('finalData>>>>', finalData)
    console.log('payment info ', paymentInfo);
    console.log('all data', allData);
    console.log('final data', finalData);
    if (paymentInfo.type == 'Available') {
      data = await axios.post(config.API_URL + '/finance/refund/balance', finalData).then((res) => {

        if (res.status === 'failed' || res.data.status === 'failed') {
          alert('Missing Fields.')
          return;
        }
        setDone(true)
        console.log('submited')

      }).catch((err) => { alert('Missing Fields..') });
    }
    if (paymentInfo.type == 'student') {
      data = await axios.post(config.API_URL + '/finance/refund/payment', finalData).then((res) => {

        if (res.status === 'failed' || res.data.status === 'failed') {
          alert('Missing Fields.')
          return;
        }
        setDone(true)
        console.log('submited')

      }).catch((err) => { alert('Missing Fields..') });
    }

    //// console.log(data.data);
  }

  function getDefaultData() {

    handleChangeWithName(paymentInfo?.branchId, 'branchId')
    handleChangeWithName(paymentInfo?.regionId, 'regionId')
    handleChangeWithName(paymentInfo?.booking?.corporateId, 'corporateId')
    handleChangeWithName(paymentInfo?.booking?.studentBooking[0].studentId, 'studentId')
  }

  useEffect(() => {
    getDefaultData()
  }, [])


  return (
    <div className="bg-[#FFFFFF] m-8 mx-10 border-sm">
      <div className="p-4 pt-0">
        {!done ?
          <>
            {paymentInfo?.booking?.requestType == 'Corporate' ?
              <h1 className="text-2xl p-4 font-bold">Refund corporate payments</h1>
              :
              <h1 className="text-2xl p-4 font-bold">Refund student payments</h1>
            }


            <div className="grid sm:grid-cols-1 md:grid-cols-3 grid-cols-3 mt-5 gap-3">
              {/* left side */}
              <div className="col-span-2 ">
                {/* <div className="py-10 border w-full mt-2 rounded">
                  <div className="flex justify-between mx-4">
                    <div>
                      <h1>Available balance</h1>
                    </div>
                    <div>
                      <h1 className="text-[#046F6D] font-bold">{paymentInfo?.balance?.balance} / SAR</h1>
                    </div>
                  </div>
                </div> */}


                <div>
                  <div className='mt-5'>
                    <label>Refund amount</label>
                  </div>
                  <input
                    className=" p-3 rounded w-60 text-sm border rounded mt-3"
                    name="amount"
                    placeholder="account number"
                    type="number"
                    onChange={(e) => {
                      handleChange(e)
                      console.log(e.target.value);
                      setRefundError(false);

                    }}
                    value={allData[`amount`]}
                  />
                </div>
                {showErrors && refundError ?
                  <span className='text-red-300'>please enter number</span>
                  :
                  <></>}


                <h1 className="text-[#3A3335] text-lg mt-5 font-bold">Choose refund payment type</h1>




                <div>
                  <div className="mt-5">

                    <div className='flex items-center gap-4 mt-4'>

                      <label
                        htmlFor='cash'
                        className={`border
                        ${cash ? 'border-primary' : 'border-gray-300'} rounded-md grow py-1 px-4 relative h-[80px] w-[150px] flex items-center justify-center text-sm font-semibold cursor-pointer`}
                        onClick={(e) => {
                          setAdvancePayment(false);
                          setCash(true);
                          setVisa(false);
                          setPaymentError(false)
                          // handleChangeWithName('Cash', 'paymentMethod');
                          let temp = allData;
                          temp['paymentMethod'] = 'Cash';
                          temp['paymentType'] = 'Regular';
                          setAllData(temp)
                          // setAllData({ ...allData, ['paymentMethod']: 'Cash', ['paymentType']: 'Regular' })
                          setWireTrasfer(false);
                          setPaymentStatus(false)
                          // handleChangeWithName('Regular', 'paymentType');
                        }}>
                        <div className='absolute top-1 right-1'>
                          <input
                            name='paymentMethod'
                            type="radio"
                            id='Cash'
                            className='accent-[#046F6D] '
                            value="Cash"
                          />
                        </div>
                        <img src={money} className="mx-2" />
                        <p>Cash</p>
                      </label>
                      <label
                        htmlFor='wireTransfer'
                        className={`border
                        ${wirteTrasfer ? 'border-primary' : 'border-gray-300'} rounded-md grow py-1 px-4 relative h-[80px] w-[150px] flex items-center justify-center text-sm font-semibold cursor-pointer`}
                        onClick={(e) => {
                          setAdvancePayment(false);
                          setCash(false);
                          setVisa(false);
                          setWireTrasfer(true);
                          setPaymentError(false)
                          // handleChangeWithName('Transfer', 'paymentMethod')
                          let temp = allData;
                          temp['paymentMethod'] = 'Transfer';
                          temp['paymentType'] = 'Regular';
                          setAllData(temp)
                          setPaymentStatus(true)
                          // setAllData({ ...allData, ['paymentMethod']: 'Transfer', ['paymentType']: 'Regular'})
                          // handleChangeWithName('Regular', 'paymentType')
                        }}>
                        <div className='absolute top-1 right-1'>
                          <input
                            name='paymentMethod'
                            type="radio"
                            id='Transfer'
                            className='accent-[#046F6D] '
                            value="Transfer"
                          />
                        </div>
                        <img src={wire} />
                        <p>Wire transfer</p>
                      </label>
                    </div>
                    {showErrors && paymentMethodError ?
                      <span className='text-red-300'>choose payment</span>
                      :
                      <></>}



                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                      {/* <div>
                        <div className='mt-5'>
                          <label>Refund amount</label>
                        </div>
                        <input
                          disabled
                          className=" p-3 rounded w-60 text-sm border rounded mt-3"
                          name="amount"
                          placeholder=""
                          type="number"
                          onChange={handleChange}
                          value={paymentInfo?.balance?.balance}
                        />
                      </div> */}
                      <>
                        {paymentStatus ?
                          <>
                            <div>
                              <div className='mt-5'>
                                <label>Destination Bank Name</label>
                              </div>
                              <input

                                className=" p-3 rounded w-60 text-sm border rounded mt-3"
                                name="bankName"
                                placeholder="bank name"
                                type="text"
                                onChange={(e) => {
                                  handleChange(e)
                                  console.log(e.target.value);
                                  setBankNameError(false);
                                }}
                                value={allData[`bankName`]}
                              />
                            </div>
                            {showErrors && bankNameError ?
                              <span className='text-red-300'>please type name</span>
                              :
                              <></>}
                            <div>
                              <div className='mt-5'>
                                <label>Account no#</label>
                              </div>
                              <input
                                className=" p-3 rounded w-60 text-sm border rounded mt-3"
                                name="bankAccount"
                                placeholder="2135"
                                type="number"
                                onChange={(e) => {
                                  handleChange(e)
                                  setAccountNumberError(false)
                                  console.log(e.target.value)
                                }}
                                value={allData[`bankAccount`]}
                              />
                            </div>
                            {showErrors && accountNumberError ?
                              <span className='text-red-300'>please type number</span>
                              :
                              <></>}
                            <div>
                              <div className='mt-5'>
                                <label>IBAN</label>
                              </div>
                              <input

                                className=" p-3 rounded w-60 text-sm border rounded mt-3"
                                name="IBAN"
                                placeholder=""
                                type="text"
                                onChange={(e) => {
                                  handleChange(e)
                                  setIbanError(false);
                                  console.log(e.target.value);
                                }}
                                value={allData[`iban`]}
                              />
                            </div>
                            {showErrors && accountNumberError ?
                              <span className='text-red-300'>type IBAN</span>
                              :
                              <></>}
                          </>
                          :
                          <></>}
                      </>
                    </div>


                    <div className='mt-5'>
                      <span>Note</span>
                      <textarea className='border rounded w-full mt-1 p-2' placeholder='Text...' onChange={(e) => {
                        // handleChange(e)
                        handleChangeWithName(e.target.value, 'note')
                        console.log(e.target.value)
                      }} value={allData[`note`]}></textarea>

                    </div>

                  </div>
                </div>
              </div>

              {/* Right Side */}
              <div className="">

              </div>


            </div>
          </>
          :
          <>
            <div className="flex justify-center">
              <img src={paymentconfirm}></img>
            </div>
            <div className="flex justify-center">
              <div>
                <h1 className="text-3xl font-bold text-center">Thank You</h1>
                <p className="my-6 flex justify-center font-bold text-[#ACA9A9]">refunde request was approved successfully</p>
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      navigate("/admin/refund-corporate");
                    }}
                    className="my-4 mb-12 w-64 cursor-pointer h-12 rounded-md bg-[#046F6D] font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                  >
                    All payment
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      {!done ?
      <div className="mt-4 mb-8 flex justify-between p-5">
      <div className="w-1/2">
        <button
          onClick={() => {
            console.log('___')
            navigate("/admin/refund-corporate");
          }}
          className={
            "cursor-pointer w-3/5 md:w-1/3 h-12 rounded-md border-2 border-slate-300 bg-white py-2 px-4 font-semibold text-[#333333] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
          }
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          onClick={() => {

            // handleAddBranch()

            // setShowErrors(true)
            // navigate("/admin/daily-closing/add-closing/upload");
            console.log(allData)

            if (allData.paymentMethod == 'Cash') {
              if (refundError == false && paymentMethodError == false) {
                submit()
              }
              setShowErrors(true);

            }
            if (allData.paymentMethod == "Transfer") {
              if (bankNameError == false && accountNumberError == false && ibanError == false
                && refundError == false && paymentMethodError == false) {
                submit()
              }
              setShowErrors(true)
            }
            setShowErrors(true)

          }}
          className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-12 font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
        >
          Refund payment
        </button>
      </div>
    </div>
    :
    <></>}
    </div>
  );
}

export default RefundPayment;
