import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseImage from '../../../assets/img/CourseImage.svg';
import clock from '../../../assets/img/clock.svg';
import clockhistory from '../../../assets/img/clock-history.svg';
import user from '../../../assets/img/user.svg';
import book from '../../../assets/img/book.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import playList from '../../../assets/img/fontisto_play-list.svg';
import video from '../../../assets/img/video.png';
import stop from '../../../assets/img/gg_play-pause-o.svg';
import play from '../../../assets/img/ant-design_play-circle-filled.svg';
import locked from '../../../assets/img/locked.svg';
import unlocked from '../../../assets/svgs/Unlocked.svg';
import About from '../../../components/course-detail/About';
import Content from '../../../components/course-detail/Content';
import Review from '../../../components/course-detail/Review';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import parse from 'html-react-parser';
import ArrowDown from '../../../assets/svgs/ArrowDown.svg';
import ArrowUp from '../../../assets/svgs/ArrowUp.svg';
import Certificate from '../../../assets/svgs/certificate.svg';

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import ReactPlayer from 'react-player';
import config from '../../../config';
import axios from 'axios';

function StudentDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSent, setIsSent] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const schedule = location.state.schedule;
  // console.log('state: ',location.state)
  const New = location.state.new;
  // console.log('__', New)
  // console.log('workign :::',schedule);
  // console.log('course: ',course)
  // async function getScheduleData(){
  // try {
  //     let data = await axios.patch(config.API_URL + '/segments/'+ selectedAccount?.id, allData).then((res) => {

  //     })
  //   } catch (error) {
  //     navigate(`/student/booking`);
  //   }
  // }
  // useEffect(() => {
  //   getScheduleData();
  // }, []);

  const questions = [
    {
      id: 1,
      question: 'What is the population of Coleraine?',
      answer: 'The population of Coleraine is approximately 57,000.',
    },
    {
      id: 2,
      question: 'What is the weather like in Coleraine?',
      answer:
        'The weather in Coleraine is typically mild and rainy, with average temperatures ranging from 7-16°C (45-61°F).',
    },
    {
      id: 3,
      question: 'What are some popular attractions in Coleraine?',
      answer:
        "Some popular attractions in Coleraine include the Giant's Causeway, Mussenden Temple, and the Ulster American Folk Park.",
    },
  ];

  const [activeQuestion, setActiveQuestion] = useState(null);
  const toggleQuestion = (id) => {
    if (activeQuestion === id) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(id);
    }
  };

  const [played, setPlayed] = useState(0);

  const handleProgress = (state) => {
    setPlayed(state.played);
    if (state.played >= 0.75) {
      console.log('done');
    }
  };

  async function sumbit() {
    let finalData = {
      requestType: 'Student',
      type: schedule.type,
      scheduleId: schedule.scheduleId,
      // "corporateId": 0,
      courseId: schedule.course.id,
      paymentFlag: 'Deferred',
      retentionPeriod: 10,
      students:  [12] ,
    };
    let data;
    data = await axios.post(config.API_URL + '/booking', finalData);

    if (data.status == 201) {
      navigate(`/student/exam/done`, { state: { schedule } });
    }
  }

  async function updateAttachment(id) {
    // let scheduleId = schedule.id,
    // "studentId": 0,
    // "sessionAttachmentId": 0,
    // "progress": 0
    let data;
    data = await axios.post(
      config.API_URL + '/schedule/update-schedule-student-attachments',
      {
        scheduleId: schedule.scheduleId,
        studentId: 2,
        sessionAttachmentId: id,
        progress: 100,
      },
    );
    // console.log(data.data);
  }

  async function sendQuestion() {
    try {
      setIsSent(true);
      let userId = localStorage.getItem('userId');
      let instructorId = schedule?.instructor?.id;
      let tempMessage = message;
      if (!userId || !instructorId || !tempMessage || tempMessage.length < 1) {
        setErrorMessage(true);
        setIsSent(false);
        return;
      }
      let data;
      data = await axios.post(config.API_URL + '/chat', {
        from: userId,
        to: instructorId,
        message: tempMessage,
      });
      setIsSent(false);
      closeModal();
    } catch (error) {
      setIsSent(false);
      alert('Something went wrong..');
    }
  }

  return (
    <>
      <div className="bg-[#FFFFFF] m-2 border-sm">
        <div className="flex-grow flex flex-col justify-around bg-[#F4F5F7] rounded-md p-3">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-row mt-10 space-x-2">
              <p>Progress:</p>
              <p className="text-[#046F6D]">{parseInt(schedule.progress)}%</p>
            </div>
            <div className="flex flex-row space-x-2">
              <img src={Certificate} />
            </div>
          </div>
          <div
            className={'bg-[#046F6D] h-2 rounded'}
            style={{ width: parseInt(schedule.progress) + '%' }}
          ></div>
        </div>
        <div className="flex flex-wrap m-4 mt-8">
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <ModalHeader>Send Question To Instractor </ModalHeader>
            <ModalBody>
              <h1 className="text-lg font-bold">Your Question </h1>
              <p className="text-xs">
                Include min. 260 characters to make it easier{' '}
              </p>

              <div className="mt-3">
                {/* <input className='border rounded w-full mt-1 p-10' placeholder='Text...' /> */}
                <textarea
                  className="border rounded w-full mt-1 p-2"
                  placeholder="Type something..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if (e.target.value.length > 0) {
                      setErrorMessage(false);
                    } else {
                      setErrorMessage(true);
                    }
                  }}
                ></textarea>
                {errorMessage && errorMessage === true ? (
                  <p className="text-xs text-red-600">
                    Please Fill The Question to Send it.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="flex w-full justify-between">
                <div className="w-full m-0 mx-2">
                  {' '}
                  <Button
                    onClick={closeModal}
                    className="w-full"
                    layout="outline"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-full m-0">
                  {isSent && isSent === true ? (
                    <Button disabled className="w-full ">
                      Send
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        sendQuestion();
                      }}
                      className="w-full "
                    >
                      Send
                    </Button>
                  )}
                </div>
              </div>
            </ModalFooter>
          </Modal>

          <div className="w-full md:w-2/3">
            {/* <img src={video}></img> */}
            <div className="">
              {schedule.attachements.map((data) => (
                <>
                  {data.dataValues.type == 'video' ? (
                    <ReactPlayer
                      config={{
                        file: { attributes: { controlsList: 'nodownload' } },
                      }}
                      // url="https://storage.mishari.dev/arkan/export.mp4"
                      url={data.dataValues.fileLink}
                      // playing
                      controls={true}
                      onProgress={handleProgress}
                      onPlay={() => updateAttachment(data.dataValues.id)}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ))}

              {/* <p>Percentage watched: {parseFloat(played * 100).toFixed(2)}%</p> */}
            </div>
            <br></br>
            <br></br>
            <p>{schedule.course.level}</p>
            <br></br>
            <h1 className="text-3xl font-bold">{schedule.course.name}</h1>
            <br></br>
            <div className="flex justify-between">
              {/* <img src={presonImage4}></img> */}
              <p className="mt-2 text-[#141522]">
                {schedule.instructor.nameEn}
              </p>
              <p className="text-[#9C9CA4] mt-2">
                {' '}
                {schedule.instructor.field}
              </p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            <div className="flex justify-between">
              <img src={user} className="w-5"></img>
              <p className="mt-1">{schedule.course.capacity} Student</p>
              <img src={book} className="w-5 mt-1"></img>
              <p className="mt-1">{schedule.course.numberOfSessions} Modules</p>
              <img src={clockhistory} className="mt-1 w-5"></img>
              <p className="mt-1">{schedule.course.hours} Hour</p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            {/* <div className="my-6 w-full">
              <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
                <div className="flex items-center">
                  <ul className="flex flex-row mt-0 mr-6 space-x-16 text-lg font-medium">
                    <li>
                      <p className="active:underline" aria-current="page">
                        Overview
                      </p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Materials </p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Q/A</p>
                    </li>

                  </ul>
                </div>
              </div>
              <hr></hr>
            </div> */}
            {/* <About /> */}

            {/* TABS  */}

            <Tabs className="my-6 w-full space-y-2">
              <TabList className="cursor-pointer mb-6 flex flex-wrap -mb-px px-2 flex-row mt-0 mr-6 space-x-16 text-lg font-medium border-b-2 border-[#F5F5F7]">
                <Tab
                  value="Overview"
                  key="Overview"
                  selectedClassName="cursor-pointer border-b-4 pb-4 border-[#046F6D] text-black"
                >
                  Overview
                </Tab>

                <Tab
                  value="Materials"
                  key="Materials"
                  selectedClassName="cursor-pointer border-b-4 pb-4 border-[#046F6D] text-black"
                >
                  Materials{' '}
                </Tab>
                <Tab
                  value="Q/A"
                  key="Q/A"
                  selectedClassName="cursor-pointer border-b-4 pb-4 border-[#046F6D] text-black"
                >
                  Frequently Q/A{' '}
                </Tab>
              </TabList>
              <div key="Overview">
                <TabPanel className="my-8">
                  <div className="mb-64">
                    <h1 className="text-xl font-bold">Description</h1>
                    <br></br>
                    {parse(
                      schedule.course.description
                        ? schedule.course.description
                        : '',
                    )}
                  </div>
                </TabPanel>
              </div>

              <div key="Materials">
                <TabPanel className="my-8">
                  <div className="mb-64 w-full">
                    {schedule.attachements.map((item, index) => (
                      <>
                        {item.dataValues.type == 'arkan' ? (
                          <div
                            key={index}
                            className="flex justify-between bg-[#F3F3F3] gap-4 w-full p-4 mb-4"
                          >
                            <div className="rounded text-[#23262F] text-sm">
                              {/* {schedule.course.name} {'-'} {schedule.course.sessions[index].name} {'-'} {'Attachment ' +  str(index+1)} */}
                              {schedule.course.name} {'-'}{' '}
                              {schedule.course.sessions[index].name} {'-'}{' '}
                              {'Attachment ' + (index + 1)}
                            </div>
                            <a
                              onClick={() =>
                                updateAttachment(item.dataValues.id)
                              }
                              href={item.dataValues.fileLink}
                              download={item.dataValues.type}
                            >
                              <div className="bg-white border border-[#046F6D] p-2">
                                Download
                              </div>
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </div>
                </TabPanel>
              </div>
              <div key="Q/A">
                <TabPanel className="my-8">
                  <div className="max-w-7xl mb-64">
                    <div className="max-w-3xl mx-auto">
                      {/* <h2 className="text-3xl font-bold mb-8">Frequently Asked Questions</h2> */}
                      <div className="space-y-6">
                        {
                          // questions
                          schedule.course.faqs &&
                            schedule.course.faqs.map((q) => (
                              <div
                                key={q.id}
                                className="border border-[#ECF1F6] bg-[#FDFDFD] rounded shadow"
                              >
                                <button
                                  className="flex justify-between w-full py-4 px-6 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                                  onClick={() => toggleQuestion(q.id)}
                                >
                                  <span className="font-bold text-xl">
                                    {q.question}
                                  </span>

                                  <img
                                    className="h-5 w-5 text-gray-500"
                                    src={
                                      activeQuestion !== q.id
                                        ? ArrowDown
                                        : ArrowUp
                                    }
                                  ></img>
                                </button>
                                {activeQuestion === q.id && (
                                  <div className="px-6 pb-4">
                                    {q.answer ? q.answer : ' '}
                                  </div>
                                )}
                              </div>
                            ))
                        }
                      </div>
                      {New ? (
                        <></>
                      ) : (
                        <div className="flex w-full">
                          <Button
                            onClick={() => {
                              openModal();
                            }}
                            className="my-2 w-32 h-10"
                            style={{
                              backgroundColor: '#046F6D',
                              float: 'right',
                              // marginRight: '15px',
                              // marginBottom: '15px',
                            }}
                          >
                            <span className="font-bold">New Question </span>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>

            {/* <Review /> */}
          </div>
          <div className="w-full md:w-1/3">
            <div className="ml-5">
              <div className="p-4 max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 mt-5">
                <div className="flex">
                  <div className="flex justify-between">
                    {/* <img className="mr-3 w-6" src={playList} /> */}
                    <h1 className="font-bold text-sm">
                      {' '}
                      {schedule.course.numberOfSessions} lessons
                    </h1>
                  </div>

                  <div className="ml-20">
                    <div>
                      <div className="flex">
                        {' '}
                        <img src={clock} />{' '}
                        <p className="flex"> {schedule.course.hours} h</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-[#DFDFDF] my-2" />
                {schedule.course.sessions &&
                  schedule.course.sessions.map((session, i) => (
                    <div
                      key={i}
                      className="flex justify-between bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-700 p-2 mt-5"
                    >
                      <div className="flex justify-between">
                        <div className="mr-1 mt-1">{i + 1}</div>
                        {New ? <img src={locked} /> : <img src={unlocked} />}
                        <h1 className="mt-1 ml-2">
                          {' '}
                          {String(session.name).slice(0, 20)}
                        </h1>
                      </div>
                      <div className="ml-1 mt-1 text-[#9C9CA4]">
                        <h1>{session.duration} MIN</h1>
                      </div>
                    </div>
                  ))}

                <div className="grid grid-cols-2 gap-3 mt-10 ml-5">
                  <div>
                    {New ? (
                      <button
                        onClick={() => {
                          sumbit();
                        }}
                        className="bg-[#046F6D] text-white font-bold p-3 rounded-lg w-full"
                      >
                        {' '}
                        Book Now{' '}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>

                  {New ? (
                    <div className="flex mt-5 ml-5">
                      <h1 className="flex font-bold text-2xl">
                        {parseFloat(schedule.course.fees)}{' '}
                      </h1>
                      <p className="flex mt-2">/SAR</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDetails;
