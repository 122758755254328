import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseImage from '../../../assets/img/CourseImage.svg';
import clock from '../../../assets/img/clock.svg';
import clockhistory from '../../../assets/img/clock-history.svg';
import user from '../../../assets/img/user.svg';
import book from '../../../assets/img/book.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import playList from '../../../assets/img/fontisto_play-list.svg';
import video from '../../../assets/img/video.png';
import stop from '../../../assets/img/gg_play-pause-o.svg';
import play from '../../../assets/img/ant-design_play-circle-filled.svg';
// import locked from '../../../assets/img/locked.svg';
import locked from '../../../assets/svgs/Unlocked.svg';

import About from '../../../components/course-detail/About';
import Content from '../../../components/course-detail/Content';
import Review from '../../../components/course-detail/Review';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import parse from 'html-react-parser'
import ArrowDown from '../../../assets/svgs/ArrowDown.svg'
import ArrowUp from '../../../assets/svgs/ArrowUp.svg'
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import ReactPlayer from 'react-player';
import axios from 'axios';
import config from '../../../config';

function StudentDetails() {

  const [courseSessions, setCourseSessions] = useState([])
  const [studentList, setStudentList] = useState([])
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const schedule = location.state.schedule;
  const New = location.state.new;
  console.log('workign :::', schedule);
  // console.log('course: ',course)


  const questions = [
    {
      id: 1,
      question: "What is the population of Coleraine?",
      answer: "The population of Coleraine is approximately 57,000."
    },
    {
      id: 2,
      question: "What is the weather like in Coleraine?",
      answer: "The weather in Coleraine is typically mild and rainy, with average temperatures ranging from 7-16°C (45-61°F)."
    },
    {
      id: 3,
      question: "What are some popular attractions in Coleraine?",
      answer: "Some popular attractions in Coleraine include the Giant's Causeway, Mussenden Temple, and the Ulster American Folk Park."
    }
  ];

  const [activeQuestion, setActiveQuestion] = useState(null);
  const toggleQuestion = (id) => {
    if (activeQuestion === id) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(id);
    }
  };

  async function getStudent() {
    let data;
    data = await axios.post(config.API_URL + '/schedule/attending-exam/' + courseSessions.scheduleSessionId)
    setStudentList(data.data)
  }

  useEffect(() => {
    setCourseSessions(schedule?.course?.courseSessions[0])
  }, [])


  async function handleAction(item, actionData) {
    try {
      let data;
      console.log('___', item)
      data = await axios.post(config.API_URL + '/schedule/update-student-attending', { "scheduleSessionId": courseSessions.scheduleSessionId, "studentId": item.id, "attend": actionData })

      // console.log('after action', data.data);
      getStudent()
    } catch (error) {
      alert('action is not taking try again')
    }

  }

  useEffect(() => {
    getStudent()
  } , [courseSessions])


  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="flex flex-wrap m-4 mt-8">
          <div className="w-full md:w-2/3">
            {/* <img src={video}></img> */}

            {courseSessions?.attachements?.find((attachment) => attachment.type == 'video') ?
              <div className="">
                <ReactPlayer
                  url={courseSessions.attachements.find((attachment) => attachment.type == 'video').fileLink}
                  // playing
                  controls={true}
                />
              </div>
              :
              <img
                className="w-full h-48 object-cover"
                src={schedule.course.courseImage && schedule.course.courseImage !== "string" ? schedule.course.courseImage : 'https://via.placeholder.com/300x200.png?text=' + courseSessions.name}
                alt={courseSessions.name}
              />
            }

            <br></br>
            <br></br>
            <p>{schedule.course.level}</p>
            <br></br>
            <h1 className="text-4xl font-bold">
              {schedule.course.name}
            </h1>
            <br></br>
            <div className="flex justify-between">
              {/* <img src={presonImage4}></img> */}
              <p className="mt-2 font-bold">{schedule.instructor.nameEn}</p>
              <p className="text-[#9C9CA4] mt-2">
                {' '}
                {schedule.instructor.field}
              </p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            <div className="flex justify-between">
              <img src={user} className="w-5"></img>
              <p className="mt-1">{schedule.course.capacity} Student</p>
              <img src={book} className="w-5 mt-1"></img>
              <p className="mt-1">{schedule.course.numberOfSessions} Modules</p>
              <img src={clockhistory} className="mt-1 w-5"></img>
              <p className="mt-1">{schedule.course.hours} Hour</p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            {/* <div className="my-6 w-full">
              <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
                <div className="flex items-center">
                  <ul className="flex flex-row mt-0 mr-6 space-x-16 text-lg font-medium">
                    <li>
                      <p className="active:underline" aria-current="page">
                        Overview
                      </p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Materials </p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Q/A</p>
                    </li>

                  </ul>
                </div>
              </div>
              <hr></hr>
            </div> */}
            {/* <About /> */}



            {/* TABS  */}


            <Tabs className="mt-6  w-full space-y-2">
              <TabList className="cursor-pointer mb-6 flex flex-wrap -mb-px p-2 flex-row mt-0 mr-6 space-x-16 text-lg font-medium">

                <Tab value="Overview" key="Overview" selectedClassName="cursor-pointer border-b-4 mb-5 pb-4 border-[#046F6D] text-black" >
                  Overview
                </Tab>

                {/* <Tab value="Materials" key="Materials" selectedClassName="cursor-pointer  border-b-2 border-black text-black" >Materials </Tab> */}
                <Tab value="Q/A" key="Q/A" selectedClassName="cursor-pointer border-b-4 border-[#046F6D] pb-4 text-black" >Q/A </Tab>
                <Tab onClick={() => getStudent()} value="Attendance" key="Attendance" selectedClassName="cursor-pointer border-b-4 border-[#046F6D] pb-4 text-black" >Attendance </Tab>

              </TabList>
              <div key="Overview">
                <TabPanel className="my-8">

                  <div>
                    <h1 className="text-2xl font-bold">Description</h1>
                    <br></br>
                    {parse(courseSessions.description ? courseSessions.description : "")}

                  </div>
                </TabPanel>
              </div>

              {/* <div key="Materials">
                <TabPanel className="mt-5">
                 

                </TabPanel>
              </div> */}
              <div key="Q/A">
                <TabPanel className="my-8">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto">
                      {/* <h2 className="text-3xl font-bold mb-8">Frequently Asked Questions</h2> */}
                      <div className="space-y-6">
                        {
                          // questions
                          schedule.course.faqs && schedule.course.faqs.map((q) => (
                            <div key={q.id} className="bg-white rounded-lg shadow">
                              <button
                                className="flex justify-between w-full py-4 px-6 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                                onClick={() => toggleQuestion(q.id)}
                              >
                                <span className="font-bold">{q.question}</span>

                                <img className="h-5 w-5 text-gray-500" src={activeQuestion !== q.id ? ArrowDown : ArrowUp}></img>
                              </button>
                              {activeQuestion === q.id && (
                                <div className="px-6 pb-4">{q.answer ? q.answer : " "}</div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                </TabPanel>
              </div>

              <div key="Attendance">
                <TabPanel className="my-8">
                  <div className='flex justify-between my-4'>
                    <p></p>
                    <p className='text-[#718096]'>Total Students {"("}{studentList?.students?.length}{")"}</p>
                  </div>
                  <div className="max-w-7xl">
                    <TableContainer className="mb-8">
                      <Table>
                        <TableHeader>
                          <tr className="bg-[#F7F7F7]">
                            <TableCell>Student Name</TableCell>
                            <TableCell className='flex justify-center'>Attendance</TableCell>
                          </tr>
                        </TableHeader>
                        <TableBody>
                          {studentList?.students?.map((user, i) => (
                            <TableRow key={i} >
                              <TableCell>
                                <div className="flex items-center text-sm">
                                  <div>
                                    {user.student.nameEn}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className='flex justify-center '>
                                <div className='m-2'>
                                  {user?.attend == null ?

                                    <>
                                      <Button
                                        style={{ backgroundColor: '#046F6D' }}
                                        className="text-white mr-3"
                                        onClick={() => {
                                          handleAction(user.student, true)
                                          // handleAction(item, 'Accepted')
                                          getStudent()
                                        }}
                                      >
                                        Attended
                                      </Button>
                                      <Button
                                        style={{ backgroundColor: '#D80027' }}
                                        className="bg-red-600 text-white"
                                        onClick={() => {
                                          handleAction(user.student, false)

                                          // handleAction(item, 'Rejected')
                                          getStudent()
                                        }}
                                      >
                                        Absent
                                      </Button>
                                    </>


                                    :
                                    <>
                                      {user?.attend == false ?
                                        <>
                                          <Button
                                            style={{ backgroundColor: '#046F6D' }}
                                            className="text-white mr-3"
                                            onClick={() => {
                                              handleAction(user.student, true)
                                              // handleAction(item, 'Accepted')
                                              getStudent()
                                            }}
                                          >
                                            Attended
                                          </Button>
                                          <Button
                                            style={{ backgroundColor: '#D80027' }}
                                            disabled
                                            className="bg-red-600 text-white cursor-not-allowed"
                                            onClick={() => {
                                              handleAction(user.student, false)

                                              // handleAction(item, 'Rejected')
                                              getStudent()
                                            }}
                                          >
                                            Absent
                                          </Button>

                                        </>
                                        :
                                        <>
                                          <Button
                                            style={{ backgroundColor: '#046F6D' }}
                                            className="text-white mr-3 cursor-not-allowed"
                                            disabled
                                            onClick={() => {
                                              handleAction(user.student, true)
                                              // handleAction(item, 'Accepted')
                                              getStudent()
                                            }}
                                          >
                                            Attended
                                          </Button>
                                          <Button
                                            style={{ backgroundColor: '#D80027' }}

                                            className="bg-red-600 text-white "
                                            onClick={() => {
                                              handleAction(user.student, false)

                                              // handleAction(item, 'Rejected')
                                              getStudent()
                                            }}
                                          >
                                            Absent
                                          </Button>

                                        </>
                                      }
                                    </>
                                  }
                                </div>
                                {/* {user.status === 'Pending' &&
                <>
                  <Button
                    style={{ backgroundColor: '#046F6D' }}
                    className="text-white mr-3"
                    onClick={() => {
                      handleAction(user, 'Accepted')
                      getAllList()
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    style={{ backgroundColor: '#D80027' }}
                    className="bg-red-600 text-white"
                    onClick={() => {
                      handleAction(user, 'rejected')
                      getAllList()
                    }}
                  >
                    Reject
                  </Button>
                </>
              } */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {/* <TableFooter>
      <Pagination
        totalResults={tableBody.length}
        resultsPerPage={resultsPerPage}
        onChange={onPageChangeTable1}
        style={{ color: '#718096' }}
        label="Table navigation"
      />
    </TableFooter> */}
                    </TableContainer>
                  </div>

                </TabPanel>
              </div>

            </Tabs>


            {/* <Review /> */}
          </div>
          <div className="w-full md:w-1/3">
            <div className="ml-5">
              <div className="p-2 max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 mt-5">
                <div className="flex">
                  <div className="flex justify-between">
                    <img className="mr-3 w-6" src={playList} />
                    <h1 className="font-bold text-sm"> {schedule.course.numberOfSessions} lessons</h1>
                  </div>

                  <div className="ml-20">
                    <div>
                      <div className="flex">
                        <img src={clock} />
                        <p className="flex"> {schedule.course.hours} h</p>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  schedule.course.courseSessions && schedule.course.courseSessions.map((session, i) => {
                    return (
                      <div onClick={() => {
                        setCourseSessions(session)
                        getStudent()
                        }} key={i} className="mt-4 flex justify-between bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2 mt-5">
                        <div className="flex justify-between">
                          <div className="mr-2 mt-1 ">{i + 1}</div>
                          <img src={locked} />
                          <h1 className="mt-1 ml-2"> {String(session.name).slice(0, 20)}</h1>
                        </div>
                        <div className="ml-4 mt-1">
                          <h1>{session.duration} MIN</h1>
                        </div>
                      </div>
                    )
                  })
                }
                {/* <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2 mt-5">
                  <div className="mr-2 mt-1">1</div>
                  <div className="flex">
                    <img src={stop} />
                    <h1 className="mt-1 ml-2"> Intro</h1>
                  </div>
                  <div className="ml-36 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div> */}

                {/* <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">2</div>
                  <div className="flex">
                    <img src={play} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div>

                <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">3</div>
                  <div className="flex">
                    <img src={locked} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div> */}

                <div className="grid grid-cols-2 gap-3 mt-10 ml-5">
                  <div>


                  </div>

                  {New
                    ?
                    <div className="flex mt-5 ml-5">
                      <h1 className="flex font-bold text-2xl">{parseFloat(schedule.course.fees)} </h1>
                      <p className="flex mt-2">/SAR</p>
                    </div>
                    :
                    <></>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDetails;
