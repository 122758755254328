import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import branchsuccess from '../../../components/branch-success.svg';
import axios from 'axios';
import regionService from '../../../services/regionService';
import branchService from '../../../services/branchService';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import config from '../../../config';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import moment from 'moment';
import more from '../../../assets/img/more.svg';
import Select from 'react-select';

import HijriDatePicker from '../../../components/HijriDatePicker';

function CurrentTrainingRevenue() {
  const [done, setDone] = useState(false);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState(false);
  const [Sucess, setSucess] = useState(false);
  const [message, setMessage] = useState('');

  const [productTypeError, setProductTypeError] = useState(true);
  const [productCodeError, setProductCodeError] = useState(true);

  const [showTableOne, setShowTableOne] = useState(false);
  const [showTableTwo, setShowTableTwo] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const content = useRef(null);
  const resultsPerPage = 10;

  const [regions, setRegions] = useState([])
  const [branches, setBranches] = useState([])
  const [municipalities, setMunicipalities] = useState([])

  const [tableBody, setTableBody] = useState([]);
  const [batchBody, setBatchBody] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const [pageTable1, setPageTable1] = useState(1);
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  const handleChangeWithName = (value, name) => {
    console.log(value);
    setAllData({ ...allData, [name]: value });

    // ToDo
    // setAllData({ ...allData, ['courseId']: courseId });

    // // console.log(allData);
  };


  async function getRegions() {
    let data;
    data = await axios.get(config.API_URL + '/region');
    setRegions(data.data);
  }

async function getBranchById(id) {
  let data;
  data = await axios.get(config.API_URL + '/branches/region/' + id);
  setBranches(data.data)
}
async function getMunicipalitiesById(id) {
  let data;
  data = await axios.get(config.API_URL + '/municipalities/region/' + id);
  setMunicipalities(data.data)
}

useEffect(() => {
  getRegions()
},[])


  async function getData() {
    try {
      let data;
      let tempData = allData;
      data = await axios.post(
        config.API_URL + '/reports/current-training-revenues',
        // tempData,
      );
      // console.log(data);
      if (!data || !data.data || data.data.status === 'failed') {
        if (data.data.message) {
          setMessage(data.data.message);
          return;
        } else {
          setMessage('No data found with the selected period');
          return;
        }
        setSucess(true);
        setSearch(false);
        return;
      }
      setTableBody(data.data);

      setShowTableOne(true);
      setSucess(true);
      setSearch(true);
    } catch (error) {
      setTableBody([]);
      console.log('getData error: ', error);
    }
  }

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          {!done ? (
            <div className="flex flex-col p-4">
              <h1 className="text-2xl p-4">Current Traning Revenue</h1>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <div className="mx-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500 mb-2">
                    Region
                  </div>
                  <Select
                    className="w-full"
                    onChange={(e) => {
                      handleChangeWithName(e.value, 'regionId');
                      getBranchById(e.value)
                      getMunicipalitiesById(e.value)
                      // setNationalityErrir(false);
                    }}
                    placeholder={'Select Region'}
                    options={regions?.map((t) => {
                      return {
                        label: t.nameEn,
                        value: t.id
                      }
                    })}
                  />
                </div>
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500 mb-2">
                    Branch
                  </div>
                  <Select
                    className="w-full"
                    onChange={(e) => {
                      handleChangeWithName(e.value, 'branchId');
                      // setNationalityErrir(false);
                    }}
                    placeholder={'Select Branch'}
                    options={branches?.map((t) => {
                      return {
                        label: t.name,
                        value: t.id
                      }
                    })}
                  />
                </div>
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500 mb-2">
                    Municipality
                  </div>
                  <Select
                    className="w-full"
                    onChange={(e) => {
                      handleChangeWithName(e.value, 'muncipalityId');
                      // setNationalityErrir(false);
                    }}
                    placeholder={'Select Branch'}
                    options={municipalities?.map((t) => {
                      return {
                        label: t.nameEn,
                        value: t.id
                      }
                    })}
                  />
                </div>
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
                    From Date
                  </div>
                  <div className="div-dropdown-wizard">
                    <input
                      className=""
                      type="date"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value, 'startDate');
                        setProductTypeError(false);
                      }}
                    />
                    {/* <HijriDatePicker
                            inputName={'from'}
                            setAllData={setAllData}
                            allData={allData}
                          /> */}
                  </div>
                  {productTypeError && showErrors ? (
                    <span className="text-red-400">select date</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
                    To Date
                  </div>
                  <div className="div-dropdown-wizard">
                    <input
                      className=""
                      type="date"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value, 'endDate');
                        setProductCodeError(false);
                      }}
                    />
                    {/* <HijriDatePicker
                            inputName={'to'}
                            setAllData={setAllData}
                            allData={allData}
                          /> */}
                  </div>
                  {productTypeError && showErrors ? (
                    <span className="text-red-400">select date</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-9 justify-self-start">
                  <Button
                    onClick={() => {
                      if (
                        productCodeError == false &&
                        productTypeError == false
                      ) {
                        getData();
                        setSearch(true);
                        setSucess(true);
                        // setShowTableOne(true);
                      }
                      setShowErrors(true);
                    }}
                    className="w-32 h-12"
                    style={{
                      backgroundColor: '#046F6D',
                      float: 'right',
                      marginRight: '15px',
                      marginBottom: '15px',
                    }}
                  >
                    <span className="ml-2 font-bold">Search</span>{' '}
                  </Button>
                </div>
              </div>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

              {!search ? (
                <>{Sucess ? <h1>{message}</h1> : <></>}</>
              ) : (
                <div>
                  <div className="flex justify-between">
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <TableContainer className="mb-8">
                        <Table>
                          <TableHeader>
                            <tr className="bg-[#F7F7F7]">
                              {/* <TableCell>Region</TableCell>
                              <TableCell>Amana</TableCell>
                              <TableCell>Branch</TableCell> */}
                              <TableCell>Course</TableCell>
                              <TableCell>Total</TableCell>
                              {/* <TableCell>Transaction status</TableCell> */}
                              <TableCell>Current day</TableCell>
                              <TableCell>No# of students</TableCell>
                              <TableCell>Current month</TableCell>
                              <TableCell>No# of students current month</TableCell>
                              <TableCell>Current year</TableCell>
                              <TableCell>No# of students current year</TableCell>
                              <TableCell>last year</TableCell>
                              <TableCell>No# of students last year</TableCell>
                              {/* <TableCell></TableCell> */}
                            </tr>
                          </TableHeader>
                          <TableBody>
                            {tableBody?.map((report, i) => (
                              <TableRow key={i}>
                                {/* <TableCell>
                                  {console.log('www', report)}
                                  <div className="flex items-center text-sm">
                                    <p className="font-semibold">
                                      {report?.region?.nameEn}
                                    </p>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.municipality?.nameEn}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.totalAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell> */}
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.name}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.totalAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.todayAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.todayStudents}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.monthAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.monthStudents}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.yearAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.yearStudents}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.pastYearAmount} /SAR
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center text-sm">
                                    <div>
                                      <p className="font-semibold">
                                        {report?.pastYearStudents}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TableFooter>
                          <Pagination
                            totalResults={tableBody?.length}
                            resultsPerPage={resultsPerPage}
                            onChange={onPageChangeTable1}
                            style={{ color: '#718096' }}
                            label="Table navigation"
                          />
                        </TableFooter>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              )}

              <></>
            </div>
          ) : (
            <>
              <div className="flex justify-center">
                <img src={branchsuccess}></img>
              </div>
              <div className="flex justify-center">
                <div>
                  <h1 className="text-3xl font-bold">
                    Branch Has Been Successfully Added
                  </h1>
                  <p className="my-6 flex justify-center font-bold text-[#ACA9A9]">
                    Be Ready To Assign It
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => console.log('H')}
                      className="my-4 mb-12 w-64 cursor-pointer h-12 rounded-md bg-[#046F6D] font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                    >
                      Branches
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CurrentTrainingRevenue;
