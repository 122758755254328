import React, { useEffect, useState } from 'react';
import fullBanner from '../../assets/img/FullBanner.svg';
import RighteSide from '../../components/Dashboard/RightSide/RighteSide';
import LeftSide from '../../components/Dashboard/LeftSide/LeftSide';
// import Sidebar from "../../components/global/sidebar";
import DashboardAdmin from '../../assets/svgs/dashboard-admin.svg';
import { FaUserGraduate } from 'react-icons/fa';
import { TbCurrencyDollar } from 'react-icons/tb';
import { RxArrowUp } from 'react-icons/rx';
import { FcCheckmark } from 'react-icons/fc';
import { BsClipboard } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';
import { Chart as ChartJS, registerables } from 'chart.js/auto'
import { Line, Doughnut, Bar } from 'react-chartjs-2';
ChartJS.register(...registerables);

function Dashboard() {
  //flex flex-warp items-center justify-between

  //flex w-full

  //mt-8
  return (
    <div
      className='my-4 flex flex-col space-y-4'
    >
      <div className='flex flex-row'>
        <div className='flex flex-row justify-between bg-[#046F6D] rounded-2xl w-full'>
          <div className='flex flex-col justify-center m-4'>
            <h2 className='text-white text-2xl font-bold'>Learning Management System</h2>
            <p className='text-white opacity-50'>Welcome Back Mr Ahmed (Admin)</p>
          </div>
          <img
            src={DashboardAdmin}
            className='h-32'
            alt='Learning Management System'
          />
        </div>
      </div>
      <CardList />
      <div className='bg-white rounded-md p-6 space-y-2'>
        {/* m-auto w-3/4 */}
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row space-x-4 flex-wrap'>
            <h2 className='font-bold text-2xl'>Revenue volume</h2>
            <div className='flex flex-row space-x-4'>
              <div className='flex flex-row space-x-1'>
                <div className='m-auto bg-[#00BBF6] h-3 w-3 rounded-full'></div>
                <p className='m-auto text-sm'>Training</p>
              </div>
              <div className='flex flex-row space-x-1'>
                <div className='m-auto bg-[#573BFF] h-3 w-3 rounded-full'></div>
                <p className='m-auto text-sm'>Exam</p>
              </div>
              <div className='flex flex-row space-x-1'>
                <div className='m-auto bg-[#34B75C] h-3 w-3 rounded-full'></div>
                <p className='m-auto text-sm'>Re-Take Exam</p>
              </div>
            </div>
          </div>
        </div>
        <div className='relative w-full' style={{ width: '99%' }}>
          <Line
            datasetIdKey='id'
            options={{
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  // suggestedMin: 0,
                  suggestedMax: 800,
                }
              }
            }}
            data={{
              labels: ['Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug'],
              datasets: [
                {
                  id: 1,
                  label: '',
                  data: [600, 500, 350, 575, 600, 500, 350, 575, 600, 500, 350, 575],
                  tension: 0.5,
                  borderColor: '#00BBF6',
                },
                {
                  id: 2,
                  label: '',
                  data: [400, 425, 500, 575, 400, 425, 500, 575, 400, 425, 500, 575],
                  tension: 0.5,
                  borderColor: '#573BFF',
                },
                {
                  id: 3,
                  label: '',
                  data: [225, 275, 300, 400, 225, 275, 300, 400, 225, 275, 300, 400],
                  tension: 0.5,
                  borderColor: '#34B75C',
                },
              ],
            }}
          />
        </div>
      </div>
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-2'>
        <div className='flex flex-col bg-white rounded-md p-6'>
          <h2 className='font-bold'>Top Branches Of Revenue</h2>
          <div className='grid gap-y-6 grid-cols-1 md:grid-cols-2'>
            <div className='relative md:p-6' style={{ width: '99%' }}>
              {/* <div className='chart-number'>xyz</div> */}
              <Doughnut
                options={{
                  elements: {
                    arc: {
                      borderWidth: 0
                    }
                  },
                  cutout: 95,
                }}
                data={{
                  labels: [],
                  datasets: [{
                    label: 'My First Dataset',
                    data: [300, 50, 100],
                    backgroundColor: [
                      'rgb(252, 211, 77)',
                      'rgb(251, 191, 36)',
                      'rgb(245, 158, 11)'
                    ],
                    hoverOffset: 4
                  }]
                }}
              />
              <div className='absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center'>
                <p className='text-2xl font-bold'>102k</p>
                <p className='text-lg'>Weekly Visits</p>
              </div>
            </div>
            <div className='flex flex-col my-4 space-y-6 py-4'>
              <div className='flex flex-row space-x-2 items-center'>
                <div className='bg-[#FDF4E7] p-2 rounded-md h-fit'>
                  <TbCurrencyDollar className='text-[#FFA523] text-3xl' />
                </div>
                <div className='flex flex-col'>
                  <h2>Total</h2>
                  <h3 className='font-bold'>$86,400 K</h3>
                </div>
              </div>
              <div className='border-t my-2'></div>
              <div className='grid gap-x-1 gap-y-4 grid-cols-2'>
                {
                  [1, 2, 3, 4].map((item, index) => (
                    <div key={index} className='flex flex-col'>
                      <div className='flex flex-row space-x-1'>
                        <div className='my-auto bg-[#FCD34D] h-3 w-3 rounded-full'></div>
                        <p className='m-auto text-sm'>({index + 1}) Riyadh branch</p>
                      </div>
                      <p className='font-bold'>$1,840</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className='grid gap-2 grid-cols-1 md:grid-cols-2'>
          <div className='bg-white rounded-md p-4 flex flex-row'>
            <div className='flex flex-col justify-between'>
              <p>Student Volume</p>
              <div className='h-full' style={{ width: '99%' }}>
                <Line
                  className='my-auto'
                  datasetIdKey='id2'
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      x: {
                        display: false,
                      },
                      y: {
                        display: false,
                      }
                    },
                  }}
                  data={{
                    labels: ['Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug'],
                    datasets: [
                      {
                        id: 1,
                        label: '',
                        data: [600, 500, 350, 575, 600, 500, 350, 575, 600, 500, 350, 575],
                        tension: 0.5,
                        pointRadius: 0,
                      },
                      {
                        id: 2,
                        label: '',
                        data: [400, 425, 500, 575, 400, 425, 500, 575, 400, 425, 500, 575],
                        tension: 0.5,
                        pointRadius: 0,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col justify-between'>
              <p className='text-sm'>New Subscription’s</p>
              <p className='text-[#46C671]'>20.345</p>
              <div className='bg-[#AFAFAF] rounded-2xl'>
                <p className='text-[#C7C7C7] text-sm w-fit mx-auto'>Last 6 Month</p>
              </div>
            </div>
          </div>
          <div className='bg-white rounded-md p-4 flex flex-row'>
            <div className='flex flex-col justify-between m-auto'>
              <p>Active Users</p>
              <div className='h-full' style={{ width: '99%' }}>
                <Bar
                  datasetIdKey='id3'
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      x: {
                        display: false,
                      },
                      y: {
                        display: false,
                      }
                    },
                  }}
                  data={{
                    labels: ['Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug'],
                    datasets: [
                      {
                        id: 1,
                        label: '',
                        data: [600, 500, 350, 575, 600, 500],
                      },
                      {
                        id: 2,
                        label: '',
                        data: [400, 425, 500, 575, 400, 425],
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col justify-between m-auto'>
              <p className='text-sm'>New Subscription’s</p>
              <p className='text-[#46C671]'>20.345</p>
              <div className='bg-[#AFAFAF] rounded-2xl'>
                <p className='text-[#C7C7C7]'>Last 6 Month</p>
              </div>
            </div>
          </div>
          <div className='bg-white rounded-md p-4 flex flex-col justify-between'>
            <div>
              <p>Regions Revenue</p>
              <p className='text-[#A0A0A0]'>Region</p>
            </div>
            <div className='w-full border-t'></div>
            <div className='flex flex-row justify-between'>
              <div>
                <p className='font-bold text-2xl'>4,590</p>
                <p>Total Traffic</p>
              </div>
              <div className='w-5/12'>
                <Bar
                  datasetIdKey='id3'
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      x: {
                        display: false,
                      },
                      y: {
                        beginAtZero: true,
                        display: false,
                      }
                    },
                  }}
                  data={{
                    labels: ['Jun', 'Jul', 'Aug', 'Jun', 'Jul', 'Aug'],
                    datasets: [
                      {
                        id: 1,
                        label: '',
                        data: [600, 500, 350, 575, 600, 500],
                        borderRadius: Number.MAX_VALUE,
                        barPercentage: 0.4,
                        backgroundColor: '#9155FD',
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className='bg-white rounded-md p-4 flex flex-col justify-between'>
            <p>Net Profit</p>
            <p className='mx-auto w-fit font-bold'>28.2k</p>
            <p className='mx-auto w-fit px-2 rounded-full bg-[#ECF8EF] text-[#46C671]'>This Year</p>
            <p className='mx-auto w-fit'>18k new sales</p>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-wrap w-full m-8">
        <div className="w-2/3 mr-4">
          <LeftSide />
        </div>
        <div className="w-1/4">
          <RighteSide />
        </div>
      </div> */}
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-2'>
        <div className='flex flex-col bg-white rounded-md p-6 space-y-6'>
          <h2 className='font-bold'>Total Of Exam Pass / Fail</h2>
          <div className='flex flex-row space-x-3'>
            <div className='bg-[#E9F6DC] p-2.5 h-fit rounded-full'>
              <FcCheckmark className='text-[#56CA00] text-2xl' />
            </div>
            <div className='grow'>
              <p>Total Of Exam Pass Students</p>
              <div className='flex flex-row space-x-2 text-[#88868C]'>
                <BsClipboard />
                <p>Total Of last Year</p>
              </div>
            </div>
            <div>
              <p className='bg-[#E9F6DC] text-[#56CA00] rounded-full py-0.5 px-2'>Pass</p>
            </div>
          </div>
          <div className='flex flex-row space-x-3'>
            <div className='bg-[#F6E1E5] p-2.5 h-fit rounded-full'>
              <IoMdCloseCircle className='text-[#D80027] text-2xl' />
            </div>
            <div className='grow'>
              <p>Total Of Exam Fail Students</p>
              <div className='flex flex-row space-x-2 text-[#88868C]'>
                <BsClipboard />
                <p>Total Of last Year</p>
              </div>
            </div>
            <div>
              <p className='bg-[#F6E1E5] text-[#D80027] rounded-full py-0.5 px-2'>Fail</p>
            </div>
          </div>
        </div>
        <div className='grid gap-2 grid-cols-1 md:grid-cols-2'>
          <div className='bg-white rounded-md p-4 flex flex-col'>
            <p>Amana’s Paid Flag Overview</p>
            <div className='relative md:p-2' style={{ width: '99%' }}>
              {/* <div className='chart-number'>xyz</div> */}
              <Doughnut
                options={{
                  elements: {
                    arc: {
                      // borderWidth: 0
                    }
                  },
                  // cutout: 95,
                }}
                data={{
                  labels: [],
                  datasets: [{
                    label: 'My First Dataset',
                    data: [300],
                    backgroundColor: [
                      '#56CA00',
                    ],
                    hoverOffset: 4
                  }, {
                    label: 'My Second Dataset',
                    data: [300],
                    backgroundColor: [
                      '#FFB400'
                    ],
                    hoverOffset: 4
                  }]
                }}
              />
              <div className='absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center'>
                <p className='text-2xl font-bold'>102k</p>
                <p className='text-lg'>Weekly Visits</p>
              </div>
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
      <div className='bg-white px-10 py-5 rounded-sm flex flex-row space-x-2'>
        {/* TODO: quick access links */}
        {[1, 2, 3].map((item, index, array) => (
          <>
            <p className='underline decoration-2 font-bold'>Daily Closing</p>
            {
              array.length - 1 != index && (
                <div className='border-l'></div>
              )
            }
          </>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;

function CardList({ cards }) {
  return (
    <div className='grid gap-4 grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 place-items-stretch'>
      {[{ title: 'Total', titleActive: 'Companies', value: '2,856 K', percentage: 15 }, { title: "Total", titleActive: "Students", value: '856', percentage: 33 }, { title: "Total", titleActive: "Revenue", value: '10,776 M', percentage: 60 }, { title: "Booking", titleActive: "Students", value: '1,286 K', percentage: 44 }, { title: "Net", titleActive: "Profit", value: '9,851 M', percentage: 55 }].map((item, index) => (
        <Card
          key={index}
          icon={(<FaUserGraduate className='text-[#3F8CFF]' />)}
          iconBackground='#EAEEF8'
          title={item.title}
          titleActive={item.titleActive}
          value={item.value}
          subtitle='Yearly Status'
          percentage={{
            value: item.percentage,
            increase: true
          }}
        />
      ))}
    </div>
  );
}

function Card({ icon, iconBackground, title, titleActive, value, subtitle, percentage = null }) {
  return (
    <div className='border border-zinc-200 rounded-md flex flex-row justify-around bg-white p-3 space-x-3'>
      <div className='flex flex-col justify-center'>
        <div className='p-3 h-fit rounded-full' style={{ backgroundColor: iconBackground }}>
          {icon}
        </div>
      </div>
      <div
        className='flex-1 flex-col'
      >
        <h3 className='font-bold'>{title} {titleActive}</h3>
        <h5 className='font-bold'>{value}</h5>
        <div className='flex flex-row justify-between'>
          <p className='text-sm self-center'>{subtitle}</p>
          {
            percentage && (
              <div className='flex flex-row space-x-0.5' style={{ color: percentage.increase ? '#56CA00' : '#FF4C51' }}>
                <RxArrowUp className='self-center' />
                <p className='self-center'>{percentage.value}%</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
