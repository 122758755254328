import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './image.png';
import logo2 from '../../components/student_svg.svg';
import logo3 from '../../components/training_center.svg';
import logo4 from '../../components/medical_center.svg';
import logo5 from '../../components/corporate_svg.svg';
import eye from '../../assets/img/eye.svg';
import axios from 'axios';
import config from '../../config';

function Login() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginErrpor] = useState(true);

  const [showErrors, setShowErrors] = useState(false)

  const navigate = useNavigate();


  async function login() {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("privileges");
    localStorage.removeItem("type");

    //  navigate('/admin/dashboard');
    let data;
    // test@test.com
    // password123

    // localStorage.setItem("user", 'sdsdcskljdnfadlnkcadca')
    //   navigate('/admin/dashboard');
    //   return
    data = await axios.post(config.API_URL + '/auth/login', { email: email, password: password }).then((res) => {
      // console.log('res? ',res.data)
      // alert('wrokgin')
      // console.log('res.status ',res.status)
      if (res.data.status == 401) {
        setShowErrors(true);
      }
      if (res.data.access_token) {
        localStorage.setItem("user", JSON.stringify(res.data.access_token));
        localStorage.setItem("userId", JSON.stringify(res.data?.userId));
        localStorage.setItem("type", res.data?.type);
        // localStorage.setItem("privileges", JSON.stringify(res.data?.privileges));
        // navigate('/admin/dashboard');
        setTimeout(() => {
          navigate('/admin/dashboard');
        }, 1000);
      }
    }).catch((error) => {
      console.log('error? ', error)
      setShowErrors(true);
    })

    // if (email === 'test@test.com') {
    //   navigate('/admin/dashboard');
    // } else {
    //   alert('Wrong Information!');
    // }
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 bg-white h-screen w-full">
        <div className="hidden sm:block md:block">
          <img
            className="w-full h-full object-cover"
            src={logo}
            alt="Here is Signin Image"
          ></img>
        </div>
        <div className="flex flex-col justify-center">
          {/* <form className="max-w-[400px] w-full mx-auto p-8 px-8"> */}
          <div className="max-w-[400px] w-full mx-auto p-8 px-8">
            <h3 className="text-4xl font-bold mb-4">Sign In</h3>
            <div className="flex flex-col py-2">
              <label>Email/ID</label>
              <input
                className="mt-2 p-2 focus:border-[#046F6D]"
                type="text"
                placeholder="Input your email/id"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col py-2">
              <label>Password</label>
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="mt-2 p-2 focus:border-[#046F6D]"
                type="password"
                placeholder="Input your password"
              />
            </div>
            {showErrors && loginError ?
              <span className='text-red-400'>Invalid email or password</span>
              :
              <></>
            }
            {/* <div className="flex py-2 justify-between">
              <p></p>
              <p className="underline">Forgot password?</p>
            </div> */}
            <button
              onClick={() => login()}
              className="w-full my-5 py-2 bg-[#046F6D] text-white shadow-md shadow-teal-500/50 hover:shadow-teal-500/40"
            >
              Sign In
            </button>
            <div className="flex py-2 justify-center">
              New user? &nbsp;
              <p
                className="underline hover:text-blue-500 hover:cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                Register{' '}
              </p>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden
            overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full md:w-full lg:w-1/4 my-6 mx-auto max-w-5xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center p-5 rounded-t">
                  <h3 className="text-3xl text-center font-semibold mt-4">
                    Registeration
                  </h3>
                </div>
                {/*body*/}
                <div className="relative">
                  <div className="my-4 mx-8 border bg-[#F9F9F9] opicity-20">
                    <Link to="/student-register">
                      <div className="flex justify-center">
                        <img className="ml-4" src={logo2} alt="icon"></img>
                        <p className="flex w-2/3 ml-8 my-4 text-[#141522] text-xl leading-relaxed p-2">
                          Student
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="my-4 mx-8 border bg-[#F9F9F9] opicity-20">
                    <Link to="/training-register">
                      <div className="flex justify-center">
                        <img className="ml-4" src={logo3} alt="icon"></img>
                        <p className="flex w-2/3 ml-8 my-4 text-[#141522] text-xl leading-relaxed p-2">
                          Training Center
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="my-4 mx-8 border bg-[#F9F9F9] opicity-20">
                    <Link to="/medical-register">
                      <div className="flex justify-center">
                        <img className="ml-4" src={logo4} alt="icon"></img>
                        <p className="flex w-2/3 ml-8 my-4 text-[#141522] text-xl leading-relaxed p-2">
                          Medical Center
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="my-4 mx-8 border bg-[#F9F9F9] opicity-20">
                    <Link to="/corporate-register">
                      <div className="flex justify-center">
                        <img className="ml-4" src={logo5} alt="icon"></img>
                        <p className="flex w-2/3 ml-8 my-4 text-[#141522] text-xl leading-relaxed p-2">
                          Corporate
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center mt-4 justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-1 text-sm outline-none
                    focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}

export default Login;
