import React, { useEffect, useState } from 'react';
import fullBanner from '../../assets/img/FullBanner.svg';
import RighteSide from '../../components/Dashboard/RightSide/RighteSide';
import LeftSide from '../../components/Dashboard/LeftSide/LeftSide';
// import Sidebar from "../../components/global/sidebar";
import DashboardAdmin from '../../assets/svgs/dashboard-admin.svg';
import { FaUserGraduate } from 'react-icons/fa';
import { TbCurrencyDollar } from 'react-icons/tb';
import { RxArrowUp } from 'react-icons/rx';
import { FcCheckmark } from 'react-icons/fc';
import { BsClipboard } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';
import { Chart as ChartJS, registerables } from 'chart.js/auto'
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import apiRequest from '../../services/axois';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
ChartJS.register(...registerables);



function Dashboard() {

  const [dashboardInfo , setDashboardInfo] = useState([])

  async function getInstroctorInfo() {
    let data;
    data = await apiRequest('get', '/dashboard/instructor')
    console.log(data.data)
    setDashboardInfo(data.data)
  }

  useEffect(() => {
    getInstroctorInfo()
  }, [])

  return (
    <div
      className='my-4 flex flex-col space-y-4'
    >
      <div className='flex flex-row'>
        <div className='flex flex-row justify-between bg-[#046F6D] rounded-2xl w-full'>
          <div className='flex flex-col justify-center m-4'>
            <h2 className='text-white text-2xl font-bold'>Learning Management System</h2>
            <p className='text-white opacity-50'>Welcome Back {dashboardInfo.nameEn}</p>
          </div>
          <img
            src={DashboardAdmin}
            className='h-32'
            alt='Learning Management System'
          />
        </div>
      </div>
      <CardList dashboardInfo={dashboardInfo} />

      {/* <div className="flex flex-wrap w-full m-8">
        <div className="w-2/3 mr-4">
          <LeftSide />
        </div>
        <div className="w-1/4">
          <RighteSide />
        </div>
      </div> */}
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-2'>
        <div className='flex flex-col bg-white rounded-md p-6 space-y-6'>
          <h2 className='font-bold'>Total Of Exam Pass / Fail</h2>
          <div className='flex flex-row space-x-3'>
            <div className='bg-[#E9F6DC] p-2.5 h-fit rounded-full'>
              <FcCheckmark className='text-[#56CA00] text-2xl' />
            </div>
            <div className='grow'>
              <p>Total Of Exam Pass Students</p>
              <div className='flex flex-row space-x-2 text-[#88868C]'>
                <BsClipboard />
                <p>Total Of last Year</p>
              </div>
            </div>
            <p>{dashboardInfo?.totalPassExam}</p>
            <div>
              <p className='bg-[#E9F6DC] text-[#56CA00] rounded-full py-0.5 px-2'>Pass</p>
            </div>
          </div>
          <div className='flex flex-row space-x-3'>
            <div className='bg-[#F6E1E5] p-2.5 h-fit rounded-full'>

              <IoMdCloseCircle className='text-[#D80027] text-2xl' />
            </div>
            <div className='grow'>
              <p>Total Of Exam Fail Students</p>
              <div className='flex flex-row space-x-2 text-[#88868C]'>
                <BsClipboard />
                <p>Total Of last Year</p>
              </div>
            </div>
            <p>{dashboardInfo?.totalFailedExam}</p>
            <div className=''>

              <p className='bg-[#F6E1E5] text-[#D80027] rounded-full py-0.5 px-2'>Fail</p>
            </div>
          </div>
        </div>

        <div>
          <div className="max-w-5xl">
            <TableContainer className="mb-8">
              <Table>
                <TableHeader>
                  <tr className="bg-[#F7F7F7]">
                    <TableCell >Course Name</TableCell>
                    <TableCell >
                      Total Student
                    </TableCell>
                    <TableCell >
                      Attendance
                    </TableCell>
                    <TableCell >
                      Absent
                    </TableCell>
                  </tr>
                </TableHeader>
                <TableBody>
                  {dashboardInfo?.trainingAttendence?.slice(0,5).map((user, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <div>{user.name}</div>
                        </div>
                      </TableCell>
                      <TableCell className=" ">
                        <div className="m-2">
                        {user?.students}
                        </div>
                      </TableCell>
                      <TableCell className="">
                        <div className="m-2">
                        {user.attend}
                        </div>
                      </TableCell>
                      <TableCell className=" ">
                        <div className="m-2">
                        {user.absent}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>


        {/* TODO: quick access links */}
      {/* <div className='bg-white px-10 py-5 rounded-sm flex flex-row space-x-2'>
        {[1, 2, 3].map((item, index, array) => (
          <>
            <p className='underline decoration-2 font-bold'>Daily Closing</p>
            {
              array.length - 1 != index && (
                <div className='border-l'></div>
              )
            }
          </>
        ))}
      </div> */}
    </div>
  );
}

export default Dashboard;

function CardList({ cards, dashboardInfo }) {
  return (
    <div className='grid gap-4 grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 place-items-stretch'>
      {[
        { title: 'Assigned (Active) ', titleActive: 'courses', value: dashboardInfo?.activeCourses, percentage: 15 },
        { title: "Assigned (scheduled) ", titleActive: "courses", value: dashboardInfo?.scheduledCourses, percentage: 33 },
        { title: "Training students ", titleActive: "total", value: dashboardInfo?.totalStudents, percentage: 44 },
        { title: "Messages ", titleActive: "notifications", value: dashboardInfo?.notifications, percentage: 60 }
        ].map((item, index) => (
        <Card
          key={index}
          icon={(<FaUserGraduate className='text-[#3F8CFF]' />)}
          iconBackground='#EAEEF8'
          title={item.title}
          titleActive={item.titleActive}
          value={item.value}
          // subtitle='Yearly Status'
          // percentage={{
          //   value: item.percentage,
          //   increase: true
          // }}
        />
      ))}
    </div>
  );
}

function Card({ icon, iconBackground, title, titleActive, value, subtitle, percentage = null }) {
  return (
    <div className='border border-zinc-200 rounded-md flex flex-row justify-around bg-white p-3 space-x-3'>
      <div className='flex flex-col justify-center'>
        <div className='p-3 h-fit rounded-full' style={{ backgroundColor: iconBackground }}>
          {icon}
        </div>
      </div>
      <div
        className='flex-1 flex-col'
      >
        <h3 className='font-bold text-sm'>{title} {titleActive}</h3>
        <h5 className='font-bold flex justify-center text-green-500'>{value}</h5>
        <div className='flex flex-row justify-between'>
          <p className='text-sm self-center'>{subtitle}</p>
          {
            percentage && (
              <div className='flex flex-row space-x-0.5' style={{ color: percentage.increase ? '#56CA00' : '#FF4C51' }}>
                <RxArrowUp className='self-center' />
                <p className='self-center'>{percentage.value}%</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
