import React, { useState, useEffect } from 'react';

// import CourseImage from '../../../assets/img/CourseImage.svg';
import CourseImage from '../../../assets/img/CourseImage.png';
import clockhistory from '../../../assets/img/clock-history.svg';
import LocationSVG from '../../../assets/svgs/Location.svg';
import BookingTypeSVG from '../../../assets/svgs/BookingType.svg';
import LangSVG from '../../../assets/svgs/Lang.svg';

import user from '../../../assets/img/user.svg';
import book from '../../../assets/img/book.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import Search from '../../../components/util/Search';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import parse from 'html-react-parser';

const data = [
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 2,
    name: 'name',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
];

function Booking() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [list, setList] = useState([]);
  const [booking, setBooking] = useState([]);
  const [bookingType, setBookingType] = useState('Training');
  const [type, setType] = useState([]);
  const [branches, setBranches] = useState([]);
  const [lang, setLang] = useState([]);
  const [levels, setLevels] = useState([]);

  const location = useLocation();

  const path = location.pathname.split('/')[2];

  console.log(location.pathname.split('/')[2]);

  async function getList() {
    try {
      let data;
      data = await axios.post(
        config.API_URL + '/booking/fetch-available-booking',
        { studentId: localStorage.userId },
      );
      let temp = data.data;
      setList(temp);
      setBooking(temp.filter((co) => co.type === 'Training'));
    } catch (error) {
      setList([]);
    }
  }

  function tabsHandler(value) {
    setBookingType(value);
    if (value === 'Both') {
      setBooking(list);
      return;
    }
    setBooking(list.filter((co) => co.type === value));
  }
  async function getType() {
    let data;
    data = await axios.get(config.API_URL + '/schedule/trainee-types');
    setType(data.data);
  }

  async function getBranches() {
    let data;
    data = await axios.post(config.API_URL + '/branches/list', {});
    setBranches(data.data);
  }

  async function getLang() {
    let data;
    data = await axios.get(config.API_URL + '/languages');
    setLang(data.data);
  }

  async function getLevel() {
    let data;
    data = await axios.get(config.API_URL + '/courses/level');
    setLevels(data.data);
  }

  useEffect(() => {
    getList();
    getType();
    getBranches();
    getLang();
  }, []);

  return (
    <>
      <div className="bg-[#FFFFFF] my-8 border-sm p-4">
        {/* <div className="my-5 grid grid-cols-1 md:grid-cols-2 ">
          <div>
            <input
              type="text"
              placeholder="Search Course Name"
              className="border border-gray-200 h-16 rounded p-2"
              onChange={(e) => setQuery(e.target.value)}
            />
            <input
              type="date"
              className="mx-3 border border-gray-200 h-16  rounded p-2"
              // placeholder="dd"
            />
          </div>

          <div className="flex my-2">
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Type</label>
              <select
                className="mx-1"
                defaultValue="1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="1" disabled>Select</option>
                {type.map((types) => (
                  <option value={types?.id}>{types?.nameEn}</option>
                ))}

              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Branch</label>
              <select
              defaultValue="1"
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="1" disabled>All</option>
                {branches.map((branch) => (
                  <option value={branch?.id}>{branch?.name}</option>
                ))}

              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Lang</label>
              <select
                defaultValue="1"
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="1">All</option>
                {lang.map((langs) => (
                  <option value={langs?.id}>{langs?.nameEn}</option>
                ))}

              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Level</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">All</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
          </div>
        </div> */}

        <div className="my-6 w-1/2">
          <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
            <div className="flex items-center">
              <ul className="flex flex-row mt-0 mr-6 space-x-16 text-lg font-medium">
                <li
                  onClick={() => {
                    tabsHandler('Training');
                  }}
                >
                  <p
                    className={
                      bookingType === 'Training'
                        ? `active:underline cursor-pointer`
                        : `text-[#9C9CA4] hover:underline cursor-pointer`
                    }
                    aria-current="page"
                  >
                    Courses
                  </p>
                </li>

                <li
                  onClick={() => {
                    tabsHandler('Exam');
                  }}
                >
                  <p
                    className={
                      bookingType === 'Exam'
                        ? `active:underline cursor-pointer`
                        : `text-[#9C9CA4] hover:underline cursor-pointer`
                    }
                    aria-current="page"
                  >
                    Exams
                  </p>
                </li>

                <li
                  onClick={() => {
                    tabsHandler('Both');
                  }}
                >
                  <p
                    className={
                      bookingType === 'Both'
                        ? `active:underline cursor-pointer`
                        : `text-[#9C9CA4] hover:underline cursor-pointer`
                    }
                    aria-current="page"
                  >
                    Both
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Card */}
          {
            // data
            //   .filter((course) => course.name.toLowerCase().includes(query))
            booking &&
              booking.map((item, index) => (
                <>
                  {item.isPaid ? (
                    <div className="" key={index}>
                      <div className="relative">
                        <div>
                          <img
                            src={CourseImage}
                            alt="Course"
                            className="w-full"
                          />
                        </div>
                        <div className="absolute top-2  right-2 left-2/4 bottom-30 bg-white bg-opacity-75 grid grid-cols-3 gap-4">
                          <div className="flex gap-1 ml-10">
                            {/* <img className='mt-0.5 w-4 h-6' src={LocationSVG} /> */}
                            <span className="text-sm sm:text-base">
                              {item?.course?.level}
                            </span>
                          </div>
                        </div>
                        <div className="absolute top-50 left-5 right-5 bottom-2 bg-white bg-opacity-50 grid grid-cols-3 gap-4">
                          <div className="flex gap-1">
                            <img className="mt-0.5 w-4 h-6" src={LocationSVG} />
                            <span className="text-sm sm:text-base">
                              {item?.location?.nameEn}
                            </span>
                          </div>
                          <div className="flex gap-1">
                            <img
                              className="mt-0.5  w-4 h-6"
                              src={BookingTypeSVG}
                            />
                            <span className="text-sm sm:text-base">
                              {item?.traineeType}
                            </span>
                          </div>
                          <div className="flex gap-1">
                            <img className="mt-0.5  w-4 h-6" src={LangSVG} />
                            <span className="text-sm sm:text-base">
                              {item?.language?.nameEn}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* TITLE Des */}
                      <div className="flex mt-3">
                        <div className="w-full">
                          {/* {console.log(item)} */}
                          <h1 className="font-bold ">{item.course.name}</h1>
                          <p className="text-gray-700 text-sm">
                            {/* {String(parse(item.question ? item.course : '')).slice(0, 50) + '...'} */}
                            {parse(
                              item.course.slicedDescription
                                ? item.course.slicedDescription
                                : '',
                            )}
                          </p>
                        </div>
                        <div className=" bg-[#E1EEED] rounded px-2 my-2 h-12">
                          <h4 className=" text-[#046F6D] ">
                            {moment(item?.date).format('MMM')}
                          </h4>
                          <h1 className=" font-bold  text-[#046F6D] ">
                            {moment(item?.date).format('DD')}
                          </h1>
                        </div>
                      </div>

                      {/* profile image */}
                      <div className="mt-3">
                        <div className="flex">
                          <div>
                            <p className="font-bold  text-sm mt-1">
                              {item.instructor.nameEn}
                            </p>
                            <p className="text-gray-900  text-xs mt-1">
                              {item.instructor.field}
                            </p>
                          </div>
                        </div>
                        {path == 'courses' ? (
                          <div className="flex-grow flex flex-col justify-around rounded-md p-3">
                            <div
                              className={'bg-[#25C78B] h-2 rounded'}
                              style={{ width: parseInt(item.progress) + '%' }}
                            ></div>
                            <div className="flex flex-col md:flex-row w-full">
                              <div className="flex flex-row space-x-2 w-full">
                                {/* <p>Progress:</p> */}
                                <p className="item-end">
                                  {parseInt(item.progress)}%
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* Button */}
                      <div className="grid grid-cols-2 gap-3 mt-3">
                        <div>
                          {path == 'courses' ? (
                            <>
                              <button
                                onClick={() => [
                                  navigate(
                                    `/student/course/${item.scheduleId}`,
                                    {
                                      state: { schedule: item, new: false },
                                    },
                                  ),
                                ]}
                                className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                              >
                                {' '}
                                View{' '}
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => [
                                navigate(`/student/course/${item.scheduleId}`, {
                                  state: { schedule: item, new: true },
                                }),
                              ]}
                              className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                            >
                              {' '}
                              Book Now{' '}
                            </button>
                          )}
                        </div>

                        {path == 'courses' ? (
                          <></>
                        ) : (
                          <div className="flex mt-5 ml-5">
                            <h1 className="flex font-bold text-2xl">
                              {parseFloat(item.course.fees)}{' '}
                            </h1>
                            <p className="flex mt-2">/SAR</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="" key={index}>
                    <div className="relative">
                      <div>
                        <img
                          src={CourseImage}
                          alt="Course"
                          className="w-full"
                        />
                      </div>
                      <div className="absolute top-2  right-2 left-2/4 bottom-30 bg-white bg-opacity-75 grid grid-cols-3 gap-4">
                        <div className="flex gap-1 ml-10">
                          {/* <img className='mt-0.5 w-4 h-6' src={LocationSVG} /> */}
                          <span className="text-sm sm:text-base">
                            {item?.course?.level}
                          </span>
                        </div>
                      </div>
                      <div className="absolute top-50 left-5 right-5 bottom-2 bg-white bg-opacity-50 grid grid-cols-3 gap-4">
                        <div className="flex gap-1">
                          <img className="mt-0.5 w-4 h-6" src={LocationSVG} />
                          <span className="text-sm sm:text-base">
                            {item?.location?.nameEn}
                          </span>
                        </div>
                        <div className="flex gap-1">
                          <img
                            className="mt-0.5  w-4 h-6"
                            src={BookingTypeSVG}
                          />
                          <span className="text-sm sm:text-base">
                            {item?.traineeType}
                          </span>
                        </div>
                        <div className="flex gap-1">
                          <img className="mt-0.5  w-4 h-6" src={LangSVG} />
                          <span className="text-sm sm:text-base">
                            {item?.language?.nameEn}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* TITLE Des */}
                    <div className="flex mt-3">
                      <div className="w-full">
                        {/* {console.log(item)} */}
                        <h1 className="font-bold ">{item.course.name}</h1>
                        <p className="text-gray-700 text-sm">
                          {/* {String(parse(item.question ? item.course : '')).slice(0, 50) + '...'} */}
                          {parse(
                            item.course.slicedDescription
                              ? item.course.slicedDescription
                              : '',
                          )}
                        </p>
                      </div>
                      <div className=" bg-[#E1EEED] rounded px-2 my-2 h-12">
                        <h4 className=" text-[#046F6D] ">
                          {moment(item?.date).format('MMM')}
                        </h4>
                        <h1 className=" font-bold  text-[#046F6D] ">
                          {moment(item?.date).format('DD')}
                        </h1>
                      </div>
                    </div>

                    {/* profile image */}
                    <div className="mt-3">
                      <div className="flex">
                        <div>
                          <p className="font-bold  text-sm mt-1">
                            {item.instructor.nameEn}
                          </p>
                          <p className="text-gray-900  text-xs mt-1">
                            {item.instructor.field}
                          </p>
                        </div>
                      </div>
                      {path == 'courses' ? (
                        <div className="flex-grow flex flex-col justify-around rounded-md p-3">
                          <div
                            className={'bg-[#25C78B] h-2 rounded'}
                            style={{ width: parseInt(item.progress) + '%' }}
                          ></div>
                          <div className="flex flex-col md:flex-row w-full">
                            <div className="flex flex-row space-x-2 w-full">
                              {/* <p>Progress:</p> */}
                              <p className="item-end">
                                {parseInt(item.progress)}%
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {/* Button */}
                    <div className="grid grid-cols-2 gap-3 mt-3">
                      <div>
                        {path == 'courses' ? (
                          <>
                            <button
                              onClick={() => [
                                navigate(
                                  `/student/course/${item.scheduleId}`,
                                  {
                                    state: { schedule: item, new: false },
                                  },
                                ),
                              ]}
                              className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                            >
                              {' '}
                              View{' '}
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => [
                              navigate(`/student/course/${item.scheduleId}`, {
                                state: { schedule: item, new: true },
                              }),
                            ]}
                            className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                          >
                            {' '}
                            Book Now{' '}
                          </button>
                        )}
                      </div>

                      {path == 'courses' ? (
                        <></>
                      ) : (
                        <div className="flex mt-5 ml-5">
                          <h1 className="flex font-bold text-2xl">
                            {parseFloat(item.course.fees)}{' '}
                          </h1>
                          <p className="flex mt-2">/SAR</p>
                        </div>
                      )}
                    </div>
                  </div>
                  )}
                </>
              ))
          }
        </div>
      </div>
    </>
  );
}

export default Booking;
