import React, { useState, useEffect } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import revenueSVG from '../../../assets/svgs/revenue.svg';
import cardSVG from '../../../assets/svgs/card.svg';
import cashSVG from '../../../assets/svgs/cash.svg';
import RoundIcon from '../../../components/RoundIcon.js';
import PageTitle from '../../../components/Typography/PageTitle';
import SectionTitle from '../../../components/Typography/SectionTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import { SearchIcon, TablesIcon } from '../../../components/icons';
// import response from './demo';
import corporateImg from '../../../assets/img/buliding.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import more from '../../../assets/img/more.svg'
import { useTable, usePagination } from 'react-table'
import profileImageOne from '../../../assets/img/profileImagOne.png'
import config from '../../../config';
import Dropzone from 'react-dropzone';
import uploaderService from '../../../services/uploaderService';
import UploadFileImage from '../../../assets/img/Line.svg';
import uploadImage from '../../../assets/img/uploadImage.svg'

import AttachmentComponent from '../../../components/Attachment';

function DailyClosing() {


  const [allData, setAllData] = useState([])
  const [tableBody, setTableBody] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dailySummary, setDailySummary] = useState([]);
  const [transactionId , setTransactionId] = useState();
  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  async function submit() {
    let data;
    let finalData = allData;
    finalData.transactionId = transactionId;
    data = await axios.post(config.API_URL + '/finance/closing/upload-deposit', finalData).then((res) => {
      if (res.status === 'failed' || res.data.failed === 'failed') {
        alert('Missing Fields.')
        return
      }
      closeModal()
      getList()
    }).catch((err) => {
      alert('Missing Fields..')
    });
  }

  const navigate = useNavigate();

  async function getDailySummary() {
    let data;
    data = await axios.get(config.API_URL + '/finance/daily-closing/summary');
    // console.log(data.data);
    setDailySummary(data.data);
    // console.log(data.data)
  }

  const handleChangeFile = (file, name) => {
    console.log(file, ' ' , name)
    console.log(file[0]);
    const data = {
      dataType: "arkan",
      file: file[0]
    }
    uploaderService.postUpload(data).then((result) => {
      setAllData({ ...allData, [name]: result.data.id });
    })
  };

  const handleChangeWithName = (value, name) => {
    console.log(value);
    setAllData({ ...allData, [name]: value });

    // ToDo
    // setAllData({ ...allData, ['courseId']: courseId });

    // console.log(allData);
  };

  async function getList() {
    let data;
    data = await axios.post(config.API_URL + '/finance/closing/posted', {});
    setTableBody(data.data)
    console.log('???', data.data);
  }

  useEffect(() => {
    getDailySummary()
    // getNewClosing()
    getList()
    console.log('sss')
  }, [])

  return (
    <div className='mt-10'>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader>Upload Deposit Copy</ModalHeader>
        <ModalBody>
          {/* <p className='font-bold my-3'></p> */}
          <div className='mt-5'>
            <span className='mt-5'>Bank name </span>
            <div className='mt-3 '>
              <input
                className="border rounded p-3 rounded w-full text-sm "
                name="closingBankName"
                placeholder="bank name"
                type="text"
                onChange={(e) => handleChangeWithName(e.target.value, 'closingBankName')}
              />
            </div>
          </div>

          <br />

          <div className='mt-5'>
            <span className='mt-5'>Deposit Amount </span>
            <div className='mt-3 '>
              <input
                className="border rounded p-3 rounded w-full text-sm "
                name="closingDepositAmount"
                placeholder="Deposit Amount"
                type="text"
                onChange={(e) => handleChangeWithName(e.target.value, 'closingDepositAmount')}
              />
            </div>
          </div>



          <br />


          <div className='mt-3'>
          <AttachmentComponent 
                      name={'closingFile'}
                      inputName={'closingFile'}
                      setAllData={setAllData}
                      allData={allData}
                      
                    />
            {/* <Dropzone

              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
                handleChangeFile(acceptedFiles, 'closingFile');
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="bg-[#F4F5F6] border-dashed border-2 border-[#ADADAD] w-full">
                  <div
                    className="my-5 hover:cursor-pointer"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p className="text-[#777E91] text-center text-xs mt-1">
                      PNG,JPEG , PDF . Max 5Mb.
                    </p>
                    <img
                      src={uploadImage}
                      alt="Upload"
                      className="mx-auto mt-2"
                    />

                  </div>
                </section>
              )}
            </Dropzone> */}
          </div>

          <div className='mt-5'>
            <span  >Note</span>
            <textarea name="note" placeholder='text ...' className="address_details_textarea" onChange={(e) => handleChangeWithName(e.target.value, 'closingNote')} />
          </div>


        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={() => {

            submit()
          }} className="w-full sm:w-auto">Upload</Button>
        </ModalFooter>
      </Modal>
      {/* Card */}
      <div className='grid gird-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
        <div className=''>

          <InfoCard type="View" title="Total revenue" value={dailySummary?.totalRevenue + " /SAR"}>
            <img className="mx-2" src={cashSVG} />        

          </InfoCard>
        </div>

        <div className=''>
          <InfoCard title="Total cards revenue" value={dailySummary?.creditRevenue + " /SAR"}>
            <img className="mx-2" src={revenueSVG} />

          </InfoCard>
        </div>

        <div className=''>
          <InfoCard title="Total cash revenue" value={dailySummary?.cashRevenue + " /SAR"}>
            <img className="mx-2" src={cardSVG} />
          </InfoCard>
        </div>




      </div>

      {/* Table */}
      <div className='bg-white mt-32'>
        <h1 className='text-xl font-bold p-5'>Posted Transactions</h1>

        <Button
          onClick={() => {
            navigate('/admin/daily-closing/add-closing');
          }}
          className="w-1/5 h-14"
          style={{
            backgroundColor: '#046F6D',
            float: 'right',
            marginRight: '15px',
            marginBottom: '15px',
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
              fill="white"
            />
            <path
              d="M15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
              fill="white"
            />
            <path
              d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
              fill="white"
            />
          </svg>
          <span className="ml-2 font-bold">Create new closing</span>{' '}
        </Button>

        {/* <div className="flex my-2">
            <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  // setQuery(e.target.value)
                  // getAllList()
                }}
              >
                <option value="asc">A-Z</option>
                <option value="dec">Z-A</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 text-sm">STATUS:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  // setQuery(e.target.value)
                  // getAllList()
                }}
              >
                <option value="All">ALL</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div> */}

        <TableContainer className="mb-8">
          <Table >
            <TableHeader className='border-none'>
              <tr className="bg-[#F7F7F7]">
                <TableCell className='text-[#252631] text-sm' >Date</TableCell>
                {/* <TableCell className='text-[#252631] text-sm' >Created By</TableCell> */}
                <TableCell className='text-[#252631] text-sm' >Cash Amount </TableCell>
                <TableCell className='text-[#252631] text-sm' >Deposit Amount </TableCell>
                {/* <TableCell className='text-[#252631] text-sm' >Attachment</TableCell> */}
                <TableCell className='text-[#252631] text-sm' >Status</TableCell>
                {/* {query == 'Accepted' ? (
                    <TableCell className='text-[#252631] text-sm' >Status</TableCell>
                  ) : (
                    <TableCell className='text-[#252631] text-sm' >Actions</TableCell>
                  )} */}
                <TableCell></TableCell>
              </tr>
            </TableHeader>
            <TableBody className='py-5 space-y-5'>
              {tableBody?.map((user, i) => (
                <React.Fragment key={i}>
                  <TableRow className='border-y border-gray-200 my-3'>
                    <TableCell className='py-5'>{moment(user?.closing?.createdAt).format('DD MMM YYYY')}</TableCell>
                    {/* <TableCell>
                      <div className="flex items-center text-sm">
                        <div className='flex'>
                          <p className="font-semibold mt-2 ml-1">-</p>
                        </div>
                      </div>
                    </TableCell> */}
                    <TableCell>
                      <span className="text-sm">{user?.entries[0].amount}/SAR</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {/* {moment(user?.createdAt).format('DD MMM YYYY')} */}
                        {user?.closingDepositAmount} /SAR
                      </span>
                    </TableCell>
                    {/* <TableCell>
                      <span className="text-sm">{user?.closingFile}</span>
                    </TableCell> */}
                    <TableCell>
                      {/* {user.status != 'Pending' ? */}
                      {/* <Badge className='border border-[#F6FDF9] bg-[#F6FDF9] text-[#22C55E]'>Active</Badge> */}
                      {user.closed == false ?
                        <span className='border border-[#F6FDF9] bg-[#F6FDF9] text-[#FF784B] p-2'>
                          Pending Deposit
                        </span>
                        :
                        <span className='border border-[#F6FDF9] bg-[#F6FDF9] text-[#22C55E] p-2'>
                          Completed
                        </span>
                      }
                      {/* //   :
                      //   <>
                      //   <Button
                      //     style={{ backgroundColor: '#046F6D' }}
                      //     className="text-white mr-3"
                      //     onClick={() => {
                      //       console.log(user)
                      //       handleAction(user, 'Accepted')
                      //       getAllList()
                      //     }}
                      //   >
                      //     Accept
                      //   </Button>
                      //   <Button
                      //     style={{ backgroundColor: '#D80027' }}
                      //     className="bg-red-600 text-white"
                      //     onClick={() => {
                      //       handleAction(user, 'rejected')
                      //       getAllList()
                      //     }}
                      //   >
                      //     Reject
                      //   </Button>
                      // </>
                      // } */}
                    </TableCell>
                    <TableCell><a onClick={() => {
                      setTransactionId(user.id)
                      openModal()
                    }}><img src={more} /></a></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='py-0 h-1 '></TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          <TableFooter className='border-none bg-none'>
            {/* <Pagination
              resultsPerPage={resultsPerPage}
                totalResults={tableBody.length}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              /> */}
          </TableFooter>
        </TableContainer>


      </div>
    </div>
  )
}

export default DailyClosing
