import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import SectionTitle from '../../components/Typography/SectionTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  Pagination,
} from '@windmill/react-ui';
import { SearchIcon, TablesIcon } from '../../components/icons';
import response from './demo';
import studentIcon from '../../components/icons/studentIcon.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import more from '../../assets/img/more.svg'
import config from '../../config';
import EditUserSVG from '../../assets/svgs/user-edit.svg';
import UserSquare from '../../assets/svgs/user-square.svg';

// make a copy of the data, for the second table
const response2 = response.concat([]);

function Medical() {
  const [showTableItemMenu, setShowTableItemMenu] = useState(false);
  const [tableItemIndex, setTableItemIndex] = useState(0);

  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);
  const [tableBody, setTableBody] = useState([]);

  const [cities, setCities] = useState([]);
  const [query, setQuery] = useState('');
  const [cityFilter, setCityFilter] = useState('');

  const navigate = useNavigate();

  // setup data for every table
  const [dataTable1, setDataTable1] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);

  // pagination setup
  const resultsPerPage = 10;
  const totalResults = response.length;

  // pagination change control
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  // pagination change control
  function onPageChangeTable2(p) {
    setPageTable2(p);
  }
  async function getAllList() {
    let data;
    const filters = {
      'asc': {"sort": "asc"},
      'dec': {"sort": "dec"},
      'pending': {"status": "Pending"},
      'rejected': {"status": 'Rejected'},
      'approved': {"status": 'Accepted'},
      'All':{},
      '':{}
    }
    console.log(cityFilter)
    data = await axios.post(config.API_URL + '/medical-center/list', filters[query]);
    setTableBody(data.data);
  }

  async function handleAction(data, action) {
    await axios.patch(config.API_URL + '/medical-center/'+ data.id, {"status": action})
    getAllList()
  }

  useEffect(() => {
    getAllList();
  }, [query]);

  useEffect(() => {
    setDataTable1(
      response.slice(
        (pageTable1 - 1) * resultsPerPage,
        pageTable1 * resultsPerPage,
      ),
    );
  }, [pageTable1]);

  useEffect(() => {
    setDataTable2(
      response2.slice(
        (pageTable2 - 1) * resultsPerPage,
        pageTable2 * resultsPerPage,
      ),
    );
  }, [pageTable2]);

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          <div className="flex justify-between">
            <img src={studentIcon} className="w-8" aria-hidden="true" />
            <PageTitle className="w-1/2"> Medical Center</PageTitle>
            <div className="w-4/6"></div>
          </div>

          <Button
            onClick={() => {
              navigate('/admin/medical-center/add-new');
            }}
            className="w-1/5 h-14"
            style={{
              backgroundColor: '#046F6D',
              float: 'right',
              marginRight: '15px',
              marginBottom: '15px',
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
                fill="white"
              />
              <path
                d="M15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
                fill="white"
              />
              <path
                d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
                fill="white"
              />
            </svg>
            <span className="ml-2 font-bold">Add New Medical Center</span>{' '}
          </Button>

          <div className="flex my-2">
            <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  setQuery(e.target.value)
                  getAllList()
                }}
              >
                <option value="asc">A-Z</option>
                <option value="dec">Z-A</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 text-sm">STATUS:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  setQuery(e.target.value)
                  getAllList()
                }}
              >
                <option value="All">ALL</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Registeration Date</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell></TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                {tableBody.map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{user.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{user.email}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {moment(user.createdAt).format('DD MMM YYYY')}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{user.city.nameEn}</span>
                    </TableCell>
                    <TableCell className='text-center'>
                      {user.status != 'Pending' ?
                        <Badge type={user.status === "Rejected" ? `danger` : 'success'}>{user.status}</Badge>
                        :
                        <>
                        <Button
                          style={{ backgroundColor: '#046F6D' }}
                          className="text-white mr-3"
                          onClick={() => {
                            handleAction(user, 'Accepted')
                            getAllList()
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ backgroundColor: '#D80027' }}
                          className="bg-red-600 text-white"
                          onClick={() => {
                            handleAction(user, 'Rejected')
                            getAllList()
                          }}
                        >
                          Reject
                        </Button>
                      </>
                      }
                    </TableCell>
                    <TableCell>
                    <div className='flex items-center justify-between'>
                        <div className='cursor-pointer text-lg relative'
                          onClick={() => {
                            setShowTableItemMenu(prev => !prev);
                            setTableItemIndex(i);
                          }
                          }
                        >
                          <i className="uil uil-ellipsis-h"></i>
                          {/* Drop menu */}
                          {
                            showTableItemMenu && i === tableItemIndex &&
                            <div className='absolute top-6 right-0 bg-pure rounded-md py-2 px-1 flex flex-col gap-1 z-50 w-[200px]'
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                              }}
                            >

                              <div onClick={() => navigate('/admin/medical-center/profile/' + user.id)} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                <img src={UserSquare} className="w-5" aria-hidden="true" />
                                <p>View</p>
                              </div>


                              <div  onClick={() => navigate('/admin/medical-center/edit/'+user.id)} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                              <img src={EditUserSVG} className="w-5" aria-hidden="true" />
                                                        <p>Edit</p>
                                                    </div>
                              {/* <div className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-repeat text-base"></i>
                                                        <p>Replace Student</p>
                                                    </div> */}
                            </div>
                          }
                        </div>
                      </div>
                      </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={tableBody.length}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Medical;
