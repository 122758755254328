const dev = {
  // API_URL: 'http://localhost:4000',
  // API_URL:"http://172.20.10.14:4000"
  // API_URL:"https://server.mishari.dev"
  API_URL: 'https://sdev.mishari.dev',
};

const prod = {
  // API_URL:"http://localhost:4000"
  API_URL: 'https://sdev.mishari.dev',
};
const config = process.env.NODE_ENV === 'development' ? dev : prod;
export default config;
