import React, { useState } from 'react';
import './styles.css';

const StudentsList = ({students}) => {
    const [activeItem , setActiveItem] = useState(1);

    return (
        <div className='bg-gray-100 py-5 flex flex-col gap-4 rounded-md xl:h-[500px] lg:h-[400px] h-[200px] overflow-auto students-list'>
            {
                students.map((item , i) => (
                    <div key={i} className={`flex items-center gap-2 px-3 text-sm
                    cursor-pointer
                    ${activeItem === i ? 'border-x-4 border-x-primary text-primary font-semibold' : ''}`}
                    onClick={() => setActiveItem(i)}
                    >
                        <div className='relative'>
                            {/* <img 
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTewPdubiwZ-wA40RGuCnUu-IBIkj3iSCGGd6s5Hf7Q&s" 
                            alt="Profile" 
                            className='w-[40px] h-[40px] object-cover rounded-full'
                            /> */}
                            <div className='absolute w-[8px] h-[8px] bg-green-500 rounded-full bottom-1 right-0'></div>
                        </div>
                        <div>
                            <span>{item.nameEn}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default StudentsList