import React, { useState, useEffect , useRef } from 'react';

import UserImage from '../../../assets/img/user.svg';
import clockhistory from '../../../assets/img/clock-history.svg';
import LangSVG from '../../../assets/svgs/Lang.svg';
import user from '../../../assets/img/user.svg';
import presonImage4 from '../../../assets/img/presonImage4.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import Search from '../../../components/util/Search';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import parse from 'html-react-parser'

const data = [
  {
    id: 1,
    title: 'Asma Mohammed',
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 2,
    title: 'Ahmed',
    name: 'name',
  },
  {
    id: 3,
    title: 'Asma',
    name: 'Level 1&2 ...',
  },
  {
    id: 4,
    title: 'Mohammed',
    name: 'Level ...',
  },
];

function Booking() {
  const navigate = useNavigate();
  const [list, setList] = useState(data)
  const [selected, setSelected] = useState(0)
  const [lang, setLang] = useState([])
  const [answer, setAnswer] = useState("")
  const [answered, setAnswered] = useState("")
  const [isAnswered, setIsAnswered] = useState(false)
  const [chats, setChats] = useState([])
  const [selectedChat, setSelectedChat] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const location = useLocation()
  const path = location.pathname.split('/')[2];
  const userId = localStorage.getItem("userId");
  const [refreshChat, setRefreshChat] = useState(false);
  // const scrollViewRef = useRef(null);
  function handleSelectedChat(id,data) {
    // console.log(id)
    let tempData = chats;
    if(data) {
      tempData = data;
    }
    let temp = tempData.filter((ch) => ch.id === id);
    // temp = temp[0].chat;
    // console.log('temp: ',temp)
    if (temp.length > 0) {
      setSelectedChat(temp[0])
    }
    setSelected(Number(id))
  }

  const filterBySearch = (event) => {
    const query = event.target.value;
    console.log(query)
    var updatedList = [...data];
    updatedList = updatedList.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setList(updatedList);
  };

  useEffect(() => {
    getChats()
  }, [])

  useEffect(() => {
    getChats();
    if(selected !== 0){
      handleSelectedChat(selected)
    }
    setRefreshChat(false)
    const timer = setTimeout(() => {
      setRefreshChat(true);
    }, 15000);
    return () => clearTimeout(timer);
  }, [refreshChat]);

  // useEffect(() => {
  //   // Scrolls to the bottom of the scroll view when it loads or updates
  //   scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
  // });

  async function getChats() {
    let data;
    data = await axios.get(config.API_URL + '/chat/' + userId)
    console.log('working ?>',data.data);
    setChats(data.data)
    
    if(selected !== 0){
      handleSelectedChat(selected,data.data)
    }
  }

  async function sendMessage() {
    try {
      setIsSent(true);
      let instructorId = selectedChat?.profile?.id
      let tempMessage = answer;
      if (!userId || !instructorId || !tempMessage || tempMessage.length < 1) {
        setErrorMessage(true);
        setIsSent(false);
        return;
      }
      let data;
      data = await axios.post(config.API_URL + '/chat',
        {
          from: userId,
          to: instructorId,
          message: tempMessage
        }
      );
      setIsSent(false);
      setAnswer('')
      getChats();
     
    } catch (error) {
      setIsSent(false);
      alert('Something went wrong..')
    }
  }
  // console.log('sss',chats)

  return (
    <>
      <div className="bg-[#FFFFFF] my-4 border-sm p-4">
        <div className="">
          <h1 className="text-2xl font-bold m-2">Inbox</h1>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 md:gap-3 justify-between m-2 mb-12">
        {/* Left side: Contact */}
        <div className="bg-[#FFFFFF] drop-shadow-md col-span-2">
          <div className='h-full md:mb-24'>
            <div>
              {/* <div className='bg-[#F9FAFC] my-4 mx-3 flex'>
                <div className="left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <div>
                  <input onChange={filterBySearch} type="search" className="placeholder-[#283333F5] bg-[#F9FAFC] w-full p-2 text-sm text-gray-900 rounded-lg" placeholder="Search contacts here..." />
                </div>
              </div> */}
            </div>
            <hr className='m-3 border border-[#E6E8F0]' />
            {/* Card */}
            {chats.map((item, i) => (
              <div key={i} className="border hover:cursor-pointer rounded-sm shadow-md m-3" onClick={() => handleSelectedChat(item.id)}>
                <figure className={`flex rounded-sm p-4 md:p-0 ${item.id === selected ? `bg-[#E4E4E4]` : `bg-white`}`}>
                  <img className="w-12 h-12 border-2 rounded-full md:ml-4 md:mt-4" src={UserImage} alt="" width="384" height="512" />
                  <div className="md:pt-2 p-4 text-center text-left space-y-2">
                    <h1 className='text-md font-semibold text-left md:mt-2'>{item.profile.nameEn}</h1>
                    <p className="hidden md:block text-xs font-light text-left">
                      {item.chat[0].message}
                    </p>
                    <p className='text-left hidden md:block mt-2 text-xs text-gray-400'> {moment(item.createdAt).format('DD MMMM')} | {moment(item.createdAt).format('LT')}</p>
                  </div>
                </figure>
              </div>
            ))}
          </div>
        </div>

        {/* Right Side: Chat */}
        {chats && selectedChat && selectedChat?.id ?
          <div className="bg-[#FFFFFF] drop-shadow-md col-span-3 h-full">
            <div className='border-b shadow-sm h-16'>
              <div className="flex m-8">
                <img className="w-12 h-12 border-2 rounded-full" src={UserImage} alt="" />
                <h1 className='flex ml-4 mt-2 text-[#203B54] text-xl'>{selectedChat?.profile && selectedChat?.profile?.nameEn}</h1>
              </div>
            </div>
            <div className="md:mb-10"  style={{ overflowY: 'scroll', height: '400px' }}>
              {selectedChat?.chat && selectedChat?.chat?.map((item, i) => (
                <>
                  {selectedChat?.profile?.id === item.from ?
                    <div className='flex m-4'>
                      <div className='drop-shadow-md flex w-full'>
                        <img className="w-8 h-8 border-2 rounded-full m-2" src={UserImage} alt="" />
                        <div className='grid grid-rows-2'>
                          <div className='border shadow-sm m-1'>
                            <div className='m-2 text-xs font-normal text-[#32425B]'>
                              {item.message}
                            </div>
                          </div>
                          <div>
                            <span className=' text-xs'>{moment(item.createdAt).fromNow()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className='flex m-4'>
                      <div className='drop-shadow-md flex justify-end w-full'>
                        <div className='grid grid-rows-2'>
                          <div className='border shadow-sm m-1 bg-[#2FA285]'>
                            <div className='m-2 text-xs font-normal text-white'>
                              {item.message}
                            </div>
                          </div>
                            <div className='flex justify-end'>
                              <span className='text-xs'>{moment(item.createdAt).fromNow()}</span>
                            </div>

                        </div>
                        <img className="w-8 h-8 border-2 rounded-full m-2" src={UserImage} alt="" />
                      </div>
                    </div>
                    // <>
                    //   {isAnswered ?
                    //     <div className='flex m-4'>
                    //       <div className='drop-shadow-md flex justify-end w-full'>
                    //         <img className="w-8 h-8 border-2 rounded-full m-2" src={UserImage} alt="" />
                    //         <div className='border shadow-sm m-1 bg-[#2FA285]'>
                    //           <div className='m-2 text-xs font-normal text-white'>
                    //             {answered || item.message}  222
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //      : <></>
                    //   }
                    // </>
                  }

                </>
              ))
              }
            </div>
            <div >
              <div className='flex m-4 p-2 text-sm text-gray-900 border rounded-sm bg-[#F9FAFC]'>
                <input
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  type="text" className="w-full bg-[#F9FAFC] text-sm border-none focus:ring-0 focus:border-none focus:ring-transparent mx-1" placeholder="Answer Here..." />
                {answer.length > 0 ?
                  <button type="submit"
                    onClick={() => {
                      sendMessage()
                    }}
                    className="text-black border right-2.5 bottom-2.5 bg-grey-500 font-medium rounded-lg text-sm px-4">Send</button>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
          :
          <></>
        }
      </div>
    </>
  );
}

export default Booking;
